import React, {useEffect, useState, useRef} from 'react';
import './ResetPinModal.scss';
import {GetCommon, GetSign_In} from '../../../resources/image/Image';
import {useModal} from '../../context/ModalContext';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';
import CPButton from '../../../common/components/button/CPButton';
import {SIGNUP_MODAL_KEY} from '../../utils/loginHelper';
import SignUpModal from './SignUpModal';

function ResetPinModal({mobile, state}) {
  const {popLastAndPushModal, popModal} = useModal();
  const Common = GetCommon();
  const Sign_In = GetSign_In();
  const {t: trans} = useTranslation();
  let location = useLocation();
  const from = state?.from;
  const extraState = state?.extraState;
  const navigate = useNavigate();

  return (
    <div className="resetPin-modal-main-container">
      <img
        src={Common.modalClose}
        alt="close"
        className="resetPin-modal-close-icon"
        onClick={() => {
          popModal();
        }}></img>
      <div className="resetPin-modal-container">
        <div className="resetPin-modal-title">
          {trans('general.title.forgotPin')}
        </div>
        <img
          alt="reset-pin-modal-icon"
          src={Sign_In.verifyMobile}
          className="reset-pin-modal-icon"
        />
        <div className="reset-pin-modal-desc">{trans('forgotPin.msg.1')}</div>
        <div className="reset-pin-modal-mobile-container">
          <div className="reset-pin-modal-mobile">+{mobile}</div>
        </div>
        <CPButton
          btnBg={'reset-pin-modal-btn'}
          title={trans('general.btn.tempPin')}
          textStyle={'reset-pin-modal-signIn-txt'}
          onClick={() =>
            popLastAndPushModal({
              key: SIGNUP_MODAL_KEY,
              content: (
                <SignUpModal
                  mobile={mobile}
                  fromForgotPin={true}
                  verify={false}
                  state={state}></SignUpModal>
              ),
              showAnim: false,
            })
          }
        />
      </div>
    </div>
  );
}

export default ResetPinModal;
