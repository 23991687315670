import React, {createContext, useState, useContext, useEffect} from 'react';
import {LOGIN_MODAL_KEYS} from '../utils/loginHelper';

const ModalContext = createContext();

// example of modalStack object:
// key: RESETPIN_MODAL_KEY,
// content: (
//   <ResetPinModal mobile={mobile} state={state}></ResetPinModal>
// ), modal component
// showAnim: false, boolean
export class ModalObject {
  /**
   * @constructor
   * @param {object} param
   * @param {string} param.key
   * @param {React.ComponentType<any>} param.content
   * @param {boolean} param.showAnim
   */
  constructor({key = null, content, showAnim}) {
    this.key = key;
    this.content = content;
    this.showAnim = showAnim;
  }
}

export const ModalProvider = ({children}) => {
  const [modalStack, setModalStack] = useState([]);
  const [bottomModal, setBottomModal] = useState(null);

  const popLastAndPushModal = (modalContent) => {
    popModal();
    pushModal(modalContent);
  };

  const pushModal = (modalContent) => {
    setModalStack((prevStack) => {
      let filterStack = prevStack;

      //this is to prevent when register flow in progress, they still can pop up another step modal.
      if (
        modalContent.key &&
        LOGIN_MODAL_KEYS.includes(modalContent.key) &&
        filterStack.some((modal) => LOGIN_MODAL_KEYS.includes(modal.key))
      ) {
        return prevStack;
      }

      //this is to prevent added redundant modal with same key
      if (modalContent.key) {
        filterStack = filterStack.filter((value) => {
          return value.key != modalContent.key;
        });
      }

      return [...filterStack, modalContent];
    });
  };

  const popModal = () => {
    setModalStack((prevStack) => {
      if (prevStack.length === 1) {
        document.body.style.overflow = 'scroll';
      }
      return prevStack.slice(0, -1);
    });
  };

  const clearModals = () => {
    document.body.style.overflow = 'scroll';
    setModalStack([]);
  };

  const createBottomModal = (bottomContent) => {
    setBottomModal(bottomContent);
  };

  const clearBottomModal = () => {
    setBottomModal(null);
  };

  return (
    <ModalContext.Provider
      value={{
        modalStack,
        pushModal,
        popModal,
        popLastAndPushModal,
        clearModals,
        bottomModal,
        createBottomModal,
        clearBottomModal,
      }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  return useContext(ModalContext);
};
