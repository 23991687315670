import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSpring, animated, config} from 'react-spring';

import './Invite.scss';
import {GetPromo} from '../../resources/image/Image';
import Anim from '../../common/components/root/animation/Anim';
import {useNav as useNavigate} from '../../common/utils/helper';

export default function Invite({verifiedBank}) {
  const Promo = GetPromo();
  const navigate = useNavigate();
  const {t: trans} = useTranslation();
  const [receive, setReceive] = useState(false); // spin number
  const [angpao, setAngpao] = useState(true); // first view (angpao)
  const claim = true;

  const [{number}, api] = useSpring(() => ({
    from: {number: claim ? 100.3 : 0},
    number: claim ? 100.3 : 0,
    config: config.molasses,
  }));

  useEffect(() => {
    if (claim) {
      var choishen = document.getElementById('invite-choishen');
      var dialog = document.getElementById('invite-dialog-verify');
      var angpao = document.getElementById('invite-angpao'); //bottom small angpao
      var showTime = document.getElementById('invite-time');
      var dialog = document.getElementById('invite-dialog');

      if (verifiedBank) {
        setAngpao(false);
        dialog.style.display = 'flex';
        choishen.style.display = 'flex';
        return;
      }

      setAngpao(false);
      choishen.style.display = 'flex';
      dialog.style.display = 'flex';
      angpao.style.display = 'flex';
      showTime.style.display = 'flex';
    } else {
      setAngpao(true);
    }
  }, []);

  return (
    <div className="invite-container">
      {angpao && (
        <div
          className="invite-angpao-container"
          onClick={() => {
            setAngpao(false);
            var choishen = document.getElementById('invite-choishen');
            choishen.style.display = 'flex';
            api.start({
              number: 10.3,
              delay: 1000,
              onRest: () => {
                if (verifiedBank) {
                  setReceive(true);
                } else {
                  var dialog = document.getElementById('invite-dialog-verify');
                  var angpao = document.getElementById('invite-angpao');
                  var showTime = document.getElementById('invite-time');
                  dialog.style.display = 'flex';
                  angpao.style.display = 'flex';
                  showTime.style.display = 'flex';
                }
              },
            });
          }}>
          <img
            src={Promo.invite.angpaoWCloud}
            alt="invite"
            className="invite-angpaoWCloud"
          />
          <img
            src={Promo.invite.close}
            alt="close"
            className="invite-close"
            onClick={() => Anim.hide()}
          />
        </div>
      )}

      <div id="invite-choishen" className="invite-choishen-container">
        <div className="invite-choishen">
          <animated.div className="invite-numbering">
            {number.to((n) => n.toFixed(2))}
          </animated.div>
          {receive ? (
            <div
              className="invite-receive"
              onClick={() => {
                var dialog = document.getElementById('invite-dialog');
                dialog.style.display = 'flex';
              }}>
              {trans('homepage.promo.receive')}
            </div>
          ) : null}
          <div
            id="invite-dialog"
            className="invite-dialog-container invite-dialog-share">
            <div className="invite-dialog-title">
              {trans('homepage.promo.header')}
            </div>
            <div className="invite-dialog-desc">
              {trans('homepage.promo.body')}
            </div>
            <div
              className="invite-receive invite-dialog-btn"
              onClick={() => {
                Anim.hide();
                navigate('/affiliate');
              }}>
              {trans('homepage.promo.share')}
            </div>
            <img
              alt="close"
              src={Promo.invite.close}
              className="invite-dialog-close"
              onClick={() => {
                Anim.hide();
              }}
            />
          </div>

          <div id="invite-dialog-verify" className="invite-dialog-container">
            <div className="invite-dialog-title">
              {trans('homepage.promo.header')}
            </div>
            <div className="invite-dialog-desc">
              {trans('homepage.promo.body2')}
            </div>
            <div className="invite-dialog-checkTime">
              {trans('homepage.promo.time')}
            </div>
            <div className="invite-dialog-time">06:02 PM</div>
            <div className="invite-dialog-checkTime">
              {trans('homepage.promo.note')}
            </div>
            <img
              alt="close"
              src={Promo.invite.close}
              className="invite-dialog-close"
              onClick={() => {
                Anim.hide();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
