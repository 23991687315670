import React from 'react';
import {useSpring, animated} from 'react-spring';
import {useMatch} from 'react-router-dom';
import './FooterItem.scss';

function FooterItem({icon, name, to, item, path, promo}) {
  const isActive = useMatch({path: path, end: true});

  const angpao = useSpring({
    from: {scale: 0},
    to: {scale: 1},
    loop: {
      from: {scale: 1},
      to: [{scale: 1.2}, {scale: 1}],
      delay: 5000,
      config: {tension: 100, duration: 100},
    },
  });

  return (
    <div
      className={`footer-style ${isActive && 'footer-selected-style'}`}
      onClick={() => {
        to();
      }}>
      {item === 'mission' && promo ? (
        <animated.div
          id="invite-angpao"
          alt="invite-angpao"
          className="invite-angpao"
          style={angpao}>
          <div id="invite-time" className="invite-angpao-time">
            11:00 PM
          </div>
        </animated.div>
      ) : (
        <>
          <img alt={name} src={icon} className={'footer-icon'} />
          <div className={`footer-txt ${isActive && 'footer-selected-txt'}`}>
            {name}
          </div>
        </>
      )}
    </div>
  );
}

export default FooterItem;
