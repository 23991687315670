import React, {Component} from 'react';
import './Toast.scss';
import '../../../../App.js';

class Toast extends Component {
  static toastInstance;
  state = {show: false, msg: ''};

  static show(config) {
    this.toastInstance.showToast(config);
  }

  static hide() {
    this.toastInstance.hideToast();
  }

  showToast(config) {
    this.setState({show: true, msg: config.msg});

    setTimeout(() => {
      this.setState({show: false, msg: ''});
    }, 5 * 1000);
  }

  hideToast() {
    this.setState({show: false, msg: ''});
  }

  render() {
    const {show, msg} = this.state;

    return show ? (
      <div className="toast">
        <div className="desc">{msg}</div>
      </div>
    ) : null;
  }
}

export default Toast;
