import {createSlice} from '@reduxjs/toolkit';
import {setCompany} from '../companySlice';
import {isEqual} from 'lodash';

const initialState = {
  CMSelectedProvider: [
    // 'pg',
    // 'joker',
    // 'jili',
    // 'ufa',
    // 'ab',
    // 'ag',
    // 'mp',
    // 'yb',
    // 'wc',
    // 'eb',
    // 'dg',
    // 'g8',
    // 'qk',
    // 'wssport',
    // 'saba',
    // 'pp',
    // 'jdb',
    // 'fc',
    // 'kingmaker',
    // 'spade',
    // 'haba',
    // 'rt',
    // 'yl',
    // 'bg',
    // 'sexybcrt',
    // 'venus',
    // 'sa',
    // 'sv388',
    // 'e1sport',
    // 'pt',
    // 'ludo',
    // 'fastspin',
    // 'luckypoker',
    // 'evo',
    // 'sbo',
    // 'yesbingo',
    // 'pussy',
  ],
};
const providerSliceStorage = localStorage.getItem('providerSlice');
let providerSliceObj = providerSliceStorage
  ? JSON.parse(providerSliceStorage)
  : null;
const finalInitialState = providerSliceObj
  ? {...initialState, CMSelectedProvider: providerSliceObj}
  : initialState;

export const providerSlice = createSlice({
  name: 'provider',
  initialState: finalInitialState,
  extraReducers: (builders) => {
    builders.addCase(setCompany, (state, action) => {
      let currentProvider = state.CMSelectedProvider;
      let incomingProvider = action.payload.gameProvider;
      if (!isEqual(currentProvider, incomingProvider)) {
        localStorage.setItem('providerSlice', JSON.stringify(incomingProvider));
        state.CMSelectedProvider = incomingProvider;
      }
    });
  },
});

export default providerSlice.reducer;
