import React from 'react';
import './AppGameChangePasswordModal.scss';
import {GetCommon} from '../../../resources/image/Image';
import {useModal} from '../../context/ModalContext';

import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {updateAccountPw} from '../../redux/game/appGameSlice';

function AppGameChangePasswordModal({changedCB}) {
  const {popModal} = useModal();
  const Common = GetCommon();
  const {t: trans} = useTranslation();
  const dispatch = useDispatch();
  const {selectedAccountIndex} = useSelector((state) => state.appGame);

  return (
    <div className="appGameChangePassword-modal-main-container">
      <img
        src={Common.modalClose}
        alt="close"
        className="appGameChangePassword-modal-close-icon"
        onClick={() => {
          popModal();
        }}></img>
      <div className="appGameChangePassword-modal-container">
        <div
          className="appGameChangePassword-modal-title"
          onClick={() => {
            dispatch(
              updateAccountPw({
                newPw: 'halo123',
                accountIndex: selectedAccountIndex,
              }),
            );
            if (changedCB) {
              changedCB();
            }
            popModal();
          }}>
          {'CHANGE PASSWORD'}
        </div>
      </div>
    </div>
  );
}

export default AppGameChangePasswordModal;
