import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import '../home/PlayGame.scss';
import {clientInfo} from '../../resources';
import TopNav from '../modal/TopNav';
import Loading from '../../common/components/root/loading_spinner/Loading';

CustomerService.propTypes = {
  header: PropTypes.bool,
};

CustomerService.defaultProps = {
  header: true,
};

export default function CustomerService({header}) {
  const {t: trans} = useTranslation();
  const {profile} = useSelector(({user}) => user ?? {});
  const navigate = useNavigate();
  const mobile =
    profile.mobileNumber === undefined
      ? trans('general.label.mobileNo')
      : profile.mobileNumber;

  useEffect(() => {
    Loading.show();
  }, []);

  return (
    <div className="play-container container-padding">
      {header ? (
        <TopNav
          title={trans('general.title.cs')}
          // leftBtn={() => {
          //   navigate('/');
          // }}
        />
      ) : null}
      <div className="play-style">
        <iframe
          title="live chat"
          onLoad={(e) => {
            Loading.hide();
          }}
          src={clientInfo.customerService + mobile}
          frameBorder="0"
          height="100%"
          width="100%"></iframe>
      </div>
    </div>
  );
}
