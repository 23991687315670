import React, {useEffect, useState} from 'react';
import './ReferralTextField.scss';
import {clientInfo} from '../../../resources';
import {GetTextField} from '../../../resources/image/Image';
import api from '../../api';
import {useSearchParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {getReferral} from '../../utils/helper';
import {useTranslation} from 'react-i18next';
import LoginCommonTf from './LoginCommonTf';

export default function ReferralTextField({isFormChild, name, onChange}) {
  const {t: trans} = useTranslation();
  const tfImg = GetTextField();
  const registerConfig = clientInfo.register;
  const {seoRefcode, refcodeExp} = useSelector(({company}) => company);
  // const refcode = useSearchParams()[0].get('rc') || getReferral(refcodeExp);
  const refcode = getReferral(refcodeExp);
  const [ref, setRef] = useState(refcode);

  useEffect(() => {
    VerifyRef();
  }, []);

  useEffect(() => {
    if (!ref) {
      const refStore = getReferral(refcodeExp);
      if (refStore) {
        VerifyRef(refStore);
      }
    }
  }, [refcodeExp]);

  useEffect(() => {
    onChange({name: name, value: ref});
  }, [ref]);

  function VerifyRef(referral = null) {
    const isFromGoogleSEO = localStorage.getItem('isFromGoogleSEO') === 'true';
    const finalRef = referral ? referral : ref;
    api.VerifyReferrer({
      referrer: finalRef,
      success: (res) => {
        if (res.noData) {
          setRef(isFromGoogleSEO ? seoRefcode : '');
        } else {
          setRef(finalRef);
        }
      },
      error: () => {
        setRef(isFromGoogleSEO ? seoRefcode : '');
      },
      option: {
        dialog: false,
      },
    });
  }

  return registerConfig.referral ? (
    <LoginCommonTf
      isFormChild={isFormChild}
      icon={tfImg.referral}
      name={name}
      placeholder={trans('general.label.refCode')}
      value={ref}
      disabled={true}
    />
  ) : null;
}
