import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import '../../../pages/sign_in_out/SignIn.scss';
import './GlobalBottomModal.scss';
import {GetCommon} from '../../../resources/image/Image';
import {useModal} from '../../context/ModalContext';

// GlobalBottomModal.propTypes = {
//   content: PropTypes.element,
//   callback: PropTypes.func,
//   icon: PropTypes.string,
//   background: PropTypes.oneOf(['black', 'default']),
//   bottomModalStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//   insideCloseBtn: PropTypes.bool,
// };

// GlobalBottomModal.defaultProps = {
//   callback: {},
//   background: 'default',
//   insideCloseBtn: false,
// };

export default function GlobalBottomModal() {
  const {bottomModal, clearBottomModal} = useModal();

  useEffect(() => {
    document.getElementById('global-bottomModal-id').style.display = bottomModal
      ? 'block'
      : 'none';
  }, [bottomModal]);

  const prefab =
    bottomModal?.background === 'black'
      ? 'global-bottomModal-black'
      : 'global-bottomModal-white';
  const Common = GetCommon();

  return (
    <div id="global-bottomModal-id" className="global-bottomModal-container">
      <div
        className={`global-bottomModal-content ${prefab} ${bottomModal?.bottomModalStyle}`}>
        <div className="global-bottomModal-style ">
          {bottomModal?.content}
          {bottomModal?.insideCloseBtn ? (
            <div
              className="global-bottomModal-close-modal"
              onClick={() => {
                bottomModal?.callback
                  ? bottomModal.callback()
                  : clearBottomModal();
              }}>
              <img
                alt="global-bottomModal-close-icon"
                src={bottomModal?.icon ? bottomModal.icon : Common.close2}
                className="global-bottomModal-close-icon"
                style={{
                  height: bottomModal?.icon ? 15 : null,
                  width: bottomModal?.icon ? 15 : null,
                }}
              />
            </div>
          ) : null}
        </div>
        {bottomModal?.insideCloseBtn ? null : (
          <div
            className="global-bottomModal-close-modal"
            onClick={() => {
              bottomModal?.callback
                ? bottomModal.callback()
                : clearBottomModal();
            }}>
            <img
              alt="global-bottomModal-close-icon"
              src={bottomModal?.icon ? bottomModal.icon : Common.close2}
              className="global-bottomModal-close-icon"
              style={{
                height: bottomModal?.icon ? 15 : null,
                width: bottomModal?.icon ? 15 : null,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
