import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import './ButtonComponent_v2.scss';
import scssVariales from '../../../resources/theme/Common.scss';

const ButtonComponent_v2 = forwardRef(
  (
    {
      btnClassName,
      disableBtnClassName,
      btnTextClassName,
      disableBtnTextClassName,
      leftImgClassName,
      leftImgSrc,
      leftImgAlt,
      rightImgClassName,
      rightImgSrc,
      rightImgAlt,
      rightComponent,
      leftComponent,
      text,
      onClick,
      disable,
    },
    ref,
  ) => {
    React.useImperativeHandle(ref, () => ({
      click: () => {
        if (onClick && !disable) onClick();
      },
    }));

    return (
      <div
        className={`btnV2-container ${btnClassName} ${
          disable
            ? `btnV2-container-disabled ${
                disableBtnClassName == ''
                  ? 'btnV2-container-disabled-opacity'
                  : disableBtnClassName
              }`
            : ''
        }`}
        onClick={disable ? null : onClick}>
        {leftComponent}
        {leftImgSrc ? (
          <img className={leftImgClassName} src={leftImgSrc} alt={leftImgAlt} />
        ) : null}

        <div
          className={`btnV2-btn-txt ${btnTextClassName} ${
            disable && disableBtnTextClassName
          }`}>
          {text}
        </div>

        {rightImgSrc ? (
          <img
            className={rightImgClassName}
            src={rightImgSrc}
            alt={rightImgAlt}
          />
        ) : null}
        {rightComponent}
      </div>
    );
  },
);

ButtonComponent_v2.propTypes = {
  btnClassName: PropTypes.string,
  disableBtnClassName: PropTypes.string,
  btnTextClassName: PropTypes.string,
  disableBtnTextClassName: PropTypes.string,
  leftImgClassName: PropTypes.string,
  leftImgSrc: PropTypes.string,
  leftImgAlt: PropTypes.string,
  rightImgClassName: PropTypes.string,
  rightImgSrc: PropTypes.string,
  rightImgAlt: PropTypes.string,
  rightComponent: PropTypes.element,
  leftComponent: PropTypes.element,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  onClick: PropTypes.func,
  disable: PropTypes.bool,
};

ButtonComponent_v2.defaultProps = {
  btnClassName: '',
  disableBtnClassName: '',
  btnTextClassName: '',
  disableBtnTextClassName: '',
  leftImgClassName: '',
  leftImgSrc: '',
  leftImgAlt: '',
  text: '',
  rightImgClassName: '',
  rightImgSrc: '',
  rightImgAlt: '',
  disable: false,
  rightComponent: null,
  leftComponent: null,
};

export default ButtonComponent_v2;
