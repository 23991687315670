import React from 'react';
import './TabItem.scss';

export default function TabItem({
  title,
  isSelected,
  onClick,
  className,
  selectedClassName,
  txtClassName,
}) {
  return (
    <div
      className={`tab-item-container ${className ? className : ''} ${
        isSelected && `tab-item-container-selected ${selectedClassName}`
      }`}
      onClick={onClick}>
      <div
        className={`tab-item-txt ${txtClassName ? txtClassName : ''} ${
          isSelected && 'tab-item-txt-selected'
        }`}>
        {title}
      </div>
    </div>
  );
}
