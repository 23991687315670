import React from 'react';
import ContentModal from './ContentModal';
import {useMsgModal} from '../../context/MsgModalContext';

const MsgModalStack = () => {
  const {msgModalStack, popMsgModal} = useMsgModal();

  return (
    <div>
      {msgModalStack.map((modalObj, index) => (
        <ContentModal
          key={index}
          isOpen={index === msgModalStack.length - 1}
          handleClose={popMsgModal}
          showAnim={modalObj?.showAnim != null ? modalObj.showAnim : true}>
          {modalObj.content}
        </ContentModal>
      ))}
    </div>
  );
};

export default MsgModalStack;
