import {createSlice} from '@reduxjs/toolkit';
import {clientInfo} from '../../resources';
import {logIn} from './userSlice';
import {IsAppWrap, IsSpecialUser, APP_WRAP_IMG_PATH} from '../utils/helper';

const initialState = {
  availBankList: [],
  depositFigure: [],
  minDeposit: 0,
  minWithdraw: 0,
  currency: '',
  minCommToWallet: 0,
  csLineId: '',
  shareUrl: '',
  seoRefcode: '',
  refcodeExp: 0,
  seoException: [],
  autoDeposit: false,
  decimalDeposit: false,
  lineClientId: '',
  lineClientSecret: '',
  website: '',
  premiumUrl: '',
  salesKit: '', // learn more
  marquee: clientInfo.appname,
  contentUrl: IsAppWrap()
    ? clientInfo.defaultAssetUrl + APP_WRAP_IMG_PATH
    : clientInfo.defaultAssetUrl,
  skipOtp: false,
  regSkipOtp: false,
  enableLoginProhibition: false,
  SysGoogleAuthEnabled: false,
  skipBindBank: false,
};

const contentUrlStorage = localStorage.getItem('contentUrl');
let contentUrlObj =
  contentUrlStorage && !IsAppWrap() ? JSON.parse(contentUrlStorage) : null;
export const finalInitialState = contentUrlObj
  ? {...initialState, contentUrl: contentUrlObj}
  : initialState;

export const companySlice = createSlice({
  name: 'company',
  initialState: finalInitialState,
  reducers: {
    setCompany: (state, action) => {
      state.availBankList = action.payload.availBankList;
      state.depositFigure = action.payload.depositFigure;
      state.minDeposit = action.payload.minDeposit;
      state.currency = action.payload.currency;
      state.minCommToWallet = action.payload.minCommToWallet;
      state.minWithdraw = action.payload.minWithdraw;
      state.csLineId = action.payload.csLineId;
      state.shareUrl = action.payload.shareUrl;
      state.seoRefcode = action.payload.seoRefcode;
      state.refcodeExp = action.payload.refcodeExp;
      state.seoException = action.payload.seoException;
      state.autoDeposit = action.payload.autoDeposit;
      state.decimalDeposit = action.payload.decimalDeposit;
      state.lineClientId = action.payload.lineClientId;
      state.lineClientSecret = action.payload.lineClientSecret;
      state.website = action.payload.website;
      state.premiumUrl = action.payload.premiumUrl;
      state.salesKit = action.payload.salesKit;
      state.skipOtp = action.payload.skipOtp;
      state.regSkipOtp = action.payload.regSkipOtp;
      state.enableLoginProhibition = action.payload.enableLoginProhibition;
      state.SysGoogleAuthEnabled = action.payload.SysGoogleAuthEnabled;
      state.skipBindBank = action.payload.skipBindBank;

      let latestContentUrl = action.payload.contentUrl;
      const isAppWrap = IsAppWrap();
      const isLogin = action.payload.isLogin;
      //isLogin need to manually add into payload get from userSlice
      if (
        (!isLogin && isAppWrap) ||
        (isLogin && isAppWrap && IsSpecialUser())
      ) {
        latestContentUrl = latestContentUrl + APP_WRAP_IMG_PATH;
      }

      if (state.contentUrl != latestContentUrl) {
        localStorage.setItem('contentUrl', JSON.stringify(latestContentUrl));
        state.contentUrl = latestContentUrl;
      }
    },
    setMarquee: (state, action) => {
      state.marquee = action.payload.headline;
    },
  },
  extraReducers: (builders) => {
    builders.addCase(logIn, (state, action) => {
      const isAppWrap = IsAppWrap();
      if (isAppWrap && IsSpecialUser()) {
        if (!state.contentUrl.includes(APP_WRAP_IMG_PATH)) {
          let latestContentUrl = state.contentUrl + APP_WRAP_IMG_PATH;
          state.contentUrl = latestContentUrl;
          localStorage.setItem('contentUrl', JSON.stringify(latestContentUrl));
        }
      } else {
        let latestContentUrl = state.contentUrl.replace(APP_WRAP_IMG_PATH, '');
        state.contentUrl = latestContentUrl;
        localStorage.setItem('contentUrl', JSON.stringify(latestContentUrl));
      }
    });
  },
});

export const {setCompany, setMarquee} = companySlice.actions;
export default companySlice.reducer;
