import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  disallow: [],
};

export const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    gameInit: (state, action) => {
      state.disallow = action.payload;
    },
  },
});

export const {gameInit} = gameSlice.actions;
export default gameSlice.reducer;
