import React, {Component} from 'react';
import './Feedback.scss';
import CPButton from '../../button/CPButton';
import {GetCommonByUrl} from '../../../../resources/image/Image';
import api from '../../../api';
import {connect} from 'react-redux';

class Feedback extends Component {
  static feedbackInstance;
  state = {show: false, rating: -1, remark: ''};

  static show() {
    this.feedbackInstance.showFeedback();
  }

  static hide() {
    this.feedbackInstance.hideFeedback();
  }

  showFeedback() {
    this.setState({show: true});
    document.getElementById('root-style').style.overflow = 'hidden';
    document.body.style.overflow = 'hidden';
  }

  hideFeedback() {
    this.setState({show: false, rating: -1, remark: ''});
    document.getElementById('root-style').style.overflow = 'visible';
    document.body.style.overflow = 'scroll';
  }

  render() {
    const {show, rating, remark} = this.state;

    const {trans, contentUrl} = this.props;
    const Common = GetCommonByUrl(contentUrl);
    const isDisabled = rating < 0 && !remark;
    return show ? (
      <div className="feedback-container">
        <div className="feedback">
          <div className="feedback-title">{trans('feedback.title')}</div>
          <div className="feedback-desc">{trans('feedback.tapDesc')}</div>
          <div className="stars-container">
            {[...Array(5)].map((value, i) => {
              return (
                <div className="star-img" key={i}>
                  <img
                    alt={`star${i}`}
                    className="star"
                    src={rating >= i ? Common.pickStar : Common.star}
                    key={i}
                    onClick={() => this.setState({rating: i})}
                  />
                </div>
              );
            })}
          </div>
          <textarea
            className="feedback-comment"
            placeholder={trans('feedback.eg')}
            onChange={(e) => {
              this.setState({remark: e.target.value.trim()});
            }}
          />
          <div className="feedback-btn-container">
            <CPButton
              btnBg={`feedback-submit ${
                isDisabled && 'feedback-submit-disabled'
              }`}
              textStyle={`feedback-submit-txt ${
                isDisabled && 'feedback-submit-txt-disabled'
              }`}
              title={trans('general.btn.submit')}
              onClick={() => {
                if (isDisabled) return;
                api.SendFeedback({
                  rating: rating + 1,
                  remark,
                  success: (res) => {
                    this.hideFeedback();
                  },
                });
              }}
            />
          </div>
          <div
            className="feedback-close-container"
            onClick={() => this.hideFeedback()}>
            <img className="feedback-close" alt="close" src={Common.remove} />
          </div>
        </div>
      </div>
    ) : null;
  }
}

const mapStateToProps = (state) => ({
  contentUrl: state.company.contentUrl,
});

export default connect(mapStateToProps, null, null, {forwardRef: true})(
  Feedback,
);
