import React, {forwardRef, useEffect, useRef, useState} from 'react';
import './LoginMobileTf.scss';
import BaseTextField from './BaseTextField';
import {useTranslation} from 'react-i18next';
import {
  GetCommon,
  GetSign_In,
  GetTextField,
} from '../../../resources/image/Image';
import {clientInfo} from '../../../resources';
import {useModal} from '../../context/ModalContext';
import {useSearchParams} from 'react-router-dom';
import MobileCodeBottomModal from '../bottomModal/MobileCodeBottomModal';
import {
  GetMobileNumberWithoutPrefix,
  GetPureCountryCode,
} from '../../utils/helper';

const LoginMobileTf = forwardRef(
  (
    {
      onChange,
      value,
      countryCode: externalCountryCode,
      validation,
      countryCodeOnChange,
      ...props
    },
    ref,
  ) => {
    const tfImg = GetTextField();
    const Sign_In = GetSign_In();
    const Common = GetCommon();
    const {t: trans} = useTranslation();
    const containerRef = useRef(null);
    const [errMsg, setErrMsg] = useState('');
    const [isFocused, setIsFocused] = useState('');
    const [countryCode, setCountryCode] = useState(externalCountryCode);
    const {createBottomModal, clearBottomModal} = useModal();
    const devMode = useSearchParams()[0].get('dev') ?? false;
    const mobileCode = [
      ...clientInfo.optionMobileCode,
      ...(devMode === 'true' ? clientInfo.devUse.mobileCode : []),
    ];

    useEffect(() => {
      setCountryCode(externalCountryCode);
    }, [externalCountryCode]);

    useEffect(() => {
      const inputRef = ref?.current;
      const isRealTimeValidation = inputRef?.isRealTimeValidation;
      if (isRealTimeValidation) {
        inputRef.ValidateValue();
      }
    }, [countryCode]);

    const handleErrMsg = (errMsg) => {
      setErrMsg(errMsg);
    };

    const handleOnChange = (e) => {
      const completedMobile = (GetPureCountryCode(countryCode) ?? '') + e.value;
      if (onChange) onChange({...e, value: completedMobile});
    };

    const handleValidation = (value) => {
      if (!countryCode || countryCode == '') {
        return 'general.errMsg.countryCode';
      }
      if (value.length < 7) {
        return 'general.errMsg.phoneFormat';
      }

      if (validation) {
        return validation(value);
      }
    };

    return (
      <div
        ref={containerRef}
        className="loginMobileTf-container"
        style={props.disabled ? {opacity: '0.5'} : {}}>
        <div
          className={`loginMobileTf-input-container ${
            errMsg ? 'loginMobileTf-error-input-container' : ''
          } ${isFocused ? 'loginMobileTf-focus-input-container' : ''}`}>
          <div className="loginMobileTf-icon-container">
            <img
              className="loginMobileTf-icon-img"
              alt={'mobile'}
              src={tfImg.mobile}
            />
          </div>
          <div className="loginMobileTf-prefix-input-container">
            <div
              className="loginMobileTf-prefix-txt"
              onClick={() => {
                createBottomModal({
                  icon: Common.close,
                  content: (
                    <MobileCodeBottomModal
                      mobileCode={mobileCode}
                      selectedId={countryCode}
                      onClick={(selectedItem, previousItem) => {
                        const prefix = GetPureCountryCode(selectedItem);
                        const previousPrefix =
                          GetPureCountryCode(previousItem) ?? '';
                        if (onChange)
                          onChange({
                            name: props.name,
                            value:
                              prefix +
                              GetMobileNumberWithoutPrefix(
                                previousPrefix,
                                value,
                              ),
                          });

                        if (countryCodeOnChange) {
                          countryCodeOnChange(prefix);
                        }

                        setCountryCode(prefix);
                        clearBottomModal();
                      }}
                    />
                  ),
                });
              }}>
              {countryCode && countryCode != ''
                ? '+' + countryCode.replace('+', '')
                : '-'}
            </div>
            <div className="loginMobileTf-prefix-separator" />
            <div style={{flex: '1'}}>
              <BaseTextField
                {...props}
                value={GetMobileNumberWithoutPrefix(
                  GetPureCountryCode(countryCode) ?? '',
                  value,
                )}
                inputClassName={'loginCommonTf-input'}
                placeholderClassName={'loginCommonTf-placeholder'}
                containerRef={containerRef}
                ref={ref}
                errMsgCallBack={handleErrMsg}
                isFocusedCallBack={setIsFocused}
                onChange={handleOnChange}
                validation={handleValidation}
                inputMode="numeric"
                maxDigits={13}
                // allowFirstZero={true}
              />
            </div>
          </div>
        </div>
        {errMsg ? (
          <div className="loginMobileTf-error-msg">{trans(errMsg)}</div>
        ) : null}
      </div>
    );
  },
);

export default LoginMobileTf;
