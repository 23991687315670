import React, {useEffect, useState} from 'react';
import './GamesLog.scss';
import BackNav from '../../common/components/navigationBar/BackNav';
import {useLocation} from 'react-router-dom';
import PageTitleComponent from '../../common/components/titleComponent/PageTitleComponent';
import {useTranslation} from 'react-i18next';
import NavItem from '../../common/components/navigationItem/NavItem';
import {GetCommon} from '../../resources/image/Image';
import MultiTabComponent from '../../common/components/tab/MultiTabComponent';
import PopUpComponent from '../../common/components/PopUpComponent/PopUpComponent';
import api from '../../common/api';
import moment from 'moment';
import {isEqual} from 'lodash';
import ButtonPaginationComponent from '../../common/components/PaginationComponent/ButtonPaginationComponent';
import PopUpItem from '../../common/components/PopUpComponent/PopUpItem';
import GamesLogItem from '../../common/components/gamesLogComponent/GamesLogItem';

export default function GamesLog() {
  const Common = GetCommon();
  const {t: trans} = useTranslation();
  const location = useLocation();
  const {hideBackNav} = location?.state ?? {};
  const statamentLocalKey = 'gamesLogList';

  //tab config
  const betRecordsTabId = 'betRecords';
  const reportTabId = 'report';
  const tabObjects = [
    {displayText: trans('gamesLog.betRecords'), id: betRecordsTabId},
    {displayText: trans('gamesLog.report'), id: reportTabId},
  ];

  //day pop up config
  const todayOptionId = 'today';
  const ytdOptionId = 'yesterday';
  const last7OptionId = 'last7day';
  const last15OptionId = 'last15day';
  const last30OptionId = 'last30day';
  const dayObjects = [
    {displayText: trans('general.list.today'), id: todayOptionId},
    {displayText: trans('general.list.yesterday'), id: ytdOptionId},
    {displayText: trans('general.list.last7'), id: last7OptionId},
    {displayText: trans('general.list.last15'), id: last15OptionId},
    {displayText: trans('general.list.last30'), id: last30OptionId},
  ];

  //provider pop up config
  const allProviderId = 'all';
  const provider1Id = 'pg';
  const provider2Id = 'joker';
  const provider3Id = 'sexy';
  const provider4Id = 'maxbet';
  const providerObjects = [
    {displayText: trans(allProviderId), id: allProviderId},
    {displayText: trans(provider1Id), id: provider1Id},
    {displayText: trans(provider2Id), id: provider2Id},
    {displayText: trans(provider3Id), id: provider3Id},
    {displayText: trans(provider4Id), id: provider4Id},
  ];

  const [data, setData] = useState([]);
  const [activeTabId, setActiveTabId] = useState(betRecordsTabId);
  const [selectedDayId, setSelectedDayId] = useState(todayOptionId);
  const [listingData, setListingData] = useState([]);
  const [openDayFilter, setOpenDayFilter] = useState(false);
  const [selectedProviderId, setSelectedProviderId] = useState(allProviderId);
  const [openProviderFilter, setOpenProviderFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    processAPIDataAndLocalStorage();
    callAPI();
  }, []);

  useEffect(() => {
    let newListingData = data.filter((item) =>
      item.type.toLowerCase().includes(activeTabId),
    );

    switch (selectedDayId) {
      case todayOptionId:
        newListingData = filterDay(newListingData, 0);
        break;
      case ytdOptionId:
        newListingData = filterDay(newListingData, 1);
        break;
      case last7OptionId:
        newListingData = filterDay(newListingData, 7);
        break;
      case last15OptionId:
        newListingData = filterDay(newListingData, 15);
        break;
      case last30OptionId:
        newListingData = filterDay(newListingData, 30);
        break;
      default:
        break;
    }
    setListingData(newListingData);
  }, [data, activeTabId, selectedDayId]);

  function callAPI() {
    api.RecordWallet({
      success: (res) => {
        setIsLoading(false);
        const data = res.data;

        processAPIDataAndLocalStorage(data ? data : []);
      },
      error: (err) => {
        setIsLoading(false);
      },
    });
  }

  function processAPIDataAndLocalStorage(data) {
    let finalData = data;
    const listLocal = JSON.parse(localStorage.getItem(statamentLocalKey));

    //if api res same with local dont re-render
    if (listLocal && isEqual(listLocal, data)) {
      return;
    }

    //if res empty then render local first.
    if (data == null) {
      if (listLocal) {
        finalData = listLocal;
      }
    }

    if (finalData) {
      localStorage.setItem(statamentLocalKey, JSON.stringify(finalData));
      setData(finalData);
    }
  }

  function filterDay(data, dayDistance) {
    const newData = data.filter((item) => {
      const iDate = moment(item.date).format('YYYY-MM-DD');
      const fDate = moment().subtract(dayDistance, 'days').format('YYYY-MM-DD');

      if (dayDistance == 7 || dayDistance == 15 || dayDistance == 30) {
        return fDate <= iDate;
      }

      return fDate == iDate;
    });

    return newData;
  }

  function getDisplayText(objects, selectedId) {
    return objects.find((item) => item.id === selectedId)?.displayText;
  }

  function RenderListingItem(item, index) {
    let statusClass = '';
    switch (item.status.toLowerCase()) {
      case 'approved':
      case 'complete':
        statusClass = 'gamesLog-item-status-success';
        break;

      case 'reject':
      case 'failed':
      case 'cancelled':
        statusClass = 'gamesLog-item-status-fail';
        break;
      default:
        statusClass = 'gamesLog-item-status-progress';
    }

    return (
      <GamesLogItem
        key={index}
        title={item.type}
        date={item.date}
        turnover={`1000000`}
        winlost={`100000000`}
        oddTheme={index % 2 === 0 ? true : false}
      />
    );
  }

  return (
    <div className="gamesLog-container container-padding">
      <div className="gamesLog-style">
        <div className="gamesLog-sticky-header">
          {hideBackNav ? null : (
            <BackNav className={'gamesLog-back-nav'}></BackNav>
          )}
          <PageTitleComponent
            title={trans('sideMenu.gamesLog')}
            showLoading={isLoading}
            rightComponent={
              <NavItem imgSrc={Common.info2} onClick={() => {}} />
            }
          />
          <div className="gamesLog-filter-container">
            <MultiTabComponent
              tabObjects={tabObjects}
              selectedTabId={activeTabId}
              onSelectCallBack={(selectedTabId) => {
                setActiveTabId(selectedTabId);
              }}
            />
            <div className="gamesLog-separator" />

            <div className="gamesLog-day-filter-container">
              {/* date filter button */}
              <PopUpComponent
                keyProps={1}
                className={`gamesLog-day-button gamesLog-day-filter-container-item`}
                onClick={() => {
                  setOpenDayFilter(true);
                }}
                isOpen={openDayFilter}
                setIsOpen={setOpenDayFilter}
                popUpElement={
                  <PopUpItem
                    objects={dayObjects}
                    onClickCallBack={(selectedDayId) => {
                      setOpenDayFilter(false);
                      setSelectedDayId(selectedDayId);
                    }}
                  />
                }>
                <div className={`gamesLog-day-button-txt`}>
                  {getDisplayText(dayObjects, selectedDayId)}
                </div>
              </PopUpComponent>

              {/* provider filter button */}
              <PopUpComponent
                keyProps={2}
                className={`gamesLog-provider-button gamesLog-provider-filter-container-item`}
                onClick={() => {
                  setOpenProviderFilter(true);
                }}
                isOpen={openProviderFilter}
                setIsOpen={setOpenProviderFilter}
                popUpElement={
                  <PopUpItem
                    objects={providerObjects}
                    onClickCallBack={(selectedId) => {
                      setOpenProviderFilter(false);
                      setSelectedProviderId(selectedId);
                    }}
                  />
                }>
                <div className={`gamesLog-provider-button-txt`}>
                  {getDisplayText(providerObjects, selectedProviderId)}
                </div>
              </PopUpComponent>
            </div>
          </div>
        </div>

        {/* <FlatList
          data={listingData}
          getStatus={isLoading ? null : trans('general.list.noRecord')}
          renderItem={RenderListingItem}></FlatList> */}
        <ButtonPaginationComponent
          data={listingData}
          itemsPerPage={5}
          getStatus={isLoading ? null : trans('general.list.noRecord')}
          renderItem={RenderListingItem}></ButtonPaginationComponent>
      </div>
    </div>
  );
}
