import {useState, useEffect, useRef} from 'react';
import './Timer.scss';
import '../../../App.js';

const Timer = ({className, expiresIn = 0, callback, style}) => {
  const [timeLeft, setTimeLeft] = useState(expiresIn);
  const expiresTimeStamp = useRef(null);

  const formatTime = (t) => (t < 10 ? '0' + t : t);

  useEffect(() => {
    let expireDate = new Date();
    expiresTimeStamp.current = expireDate.setSeconds(
      expireDate.getSeconds() + expiresIn,
    );

    let interval = null;
    if (expiresIn > 0) {
      setTimeLeft(GetDifferent(expiresTimeStamp.current));
      interval = setInterval(() => {
        setTimeLeft(() => {
          const timeDifference = GetDifferent(expiresTimeStamp.current);

          if (timeDifference > 0) {
            return timeDifference;
          } else {
            clearInterval(interval);

            return 0;
          }
        });
      }, 1000);
    } else {
      setTimeLeft(0);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [expiresIn]);

  useEffect(() => {
    if (timeLeft <= 0) {
      if (callback) callback();
    }
  }, [timeLeft]);

  function GetDifferent(expireDate) {
    let currentDate = new Date();
    return expireDate - currentDate;
  }

  function GetRemainingTime(timeDifference) {
    let hours = Math.floor(timeDifference / (1000 * 60 * 60));
    let minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.ceil((timeDifference % (1000 * 60)) / 1000);

    if (seconds == 60) {
      seconds = 0;
      minutes = minutes + 1;
    }

    if (minutes == 60) {
      minutes = 0;
      hours = hours + 1;
    }

    return {hours, minutes, seconds};
  }
  return (
    <span className={`timer-txt ${className}`} style={style}>
      {formatTime(GetRemainingTime(timeLeft).minutes) +
        ':' +
        formatTime(GetRemainingTime(timeLeft).seconds)}
    </span>
  );
};

export default Timer;
