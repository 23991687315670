import React from 'react';
import './TitleWithLoading.scss';
import {GetCommon} from '../../../resources/image/Image';

export default function TitleWithLoading({title, showLoading}) {
  const Common = GetCommon();
  return (
    <div className="title-loading-container">
      <div className="title-loading-txt">{title}</div>
      {showLoading ? (
        <img
          src={Common.titleLoading}
          className="title-loading-spinner"
          alt="loading-spinner-gif"
        />
      ) : null}
    </div>
  );
}
