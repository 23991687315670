import React from 'react';
import {GetCommon} from '../../../resources/image/Image';
import './BackButton.scss';
import {useNav} from '../../utils/helper';
import {useTranslation} from 'react-i18next';

const BackButton = ({className, style, onClick}) => {
  const navigate = useNav();
  const {t: trans} = useTranslation();
  const Common = GetCommon();
  return (
    <div
      className={`back-nav-back-btn-container ${className}`}
      style={style}
      onClick={
        onClick
          ? onClick
          : () => {
              navigate(-1);
            }
      }>
      <img
        src={Common.navBack}
        alt="back"
        style={{width: '12px', height: '20px', objectFit: 'fill'}}
      />
      <div className="back-nav-back-btn-txt">{trans('general.btn.back')}</div>
    </div>
  );
};

export default BackButton;
