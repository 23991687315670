import React from 'react';
import './PageTitleComponent.scss';
import TitleWithLoading from '../text/TitleWithLoading';

const PageTitleComponent = ({title, showLoading, rightComponent}) => {
  return (
    <div className="page-title-container">
      <TitleWithLoading title={title} showLoading={showLoading} />
      {rightComponent ? rightComponent : null}
    </div>
  );
};

export default PageTitleComponent;
