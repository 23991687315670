import React, {useEffect, useState, useRef} from 'react';
import './EnterPinModal.scss';
import {GetCommon, GetSign_In} from '../../../resources/image/Image';
import {useModal} from '../../context/ModalContext';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';

import CPPin from '../../../common/components/pin/CPPin';
import api from '../../../common/api';
import {useNav as useNavigate} from '../../utils/helper';
import {RESETPIN_MODAL_KEY} from '../../utils/loginHelper';
import ResetPinModal from './ResetPinModal';

function EnterPinModal({mobile, state}) {
  const {popLastAndPushModal, popModal} = useModal();
  const Common = GetCommon();
  const Sign_In = GetSign_In();
  const {t: trans} = useTranslation();
  let location = useLocation();
  const navigate = useNavigate();
  // const extraState = state?.extraState;
  const from = state?.from;

  // const {mobile} = location.state ?? {};
  const [pin, setPin] = useState('');
  const pinRef = useRef(null);
  let verify = localStorage.getItem('verified');

  useEffect(() => {
    // localStorage.removeItem('gList');

    if (pin.length === 6) {
      const elements = document.body.style;
      elements.backdropFilter = 'none';
      api.VerifyPassword({
        userId: mobile,
        password: pin,
        success: (res) => {
          popModal();
          //continue the journey before login
          if (from) {
            navigate(`${from}`, {
              state: {...state},
            });
          } else {
            navigate(`${location.pathname}`, {
              replace: true,
            });
          }
        },
        error: (res) => {
          if (res.errcode === '101') {
            localStorage.removeItem('verified');
          }

          pinRef.current.reset(true);
          setPin('');
        },
      });
    }
  }, [pin]);

  return (
    <div className="enterPin-modal-main-container">
      <img
        src={Common.modalClose}
        alt="close"
        className="enterPin-modal-close-icon"
        onClick={() => {
          popModal();
        }}></img>
      <div className="enterPin-modal-container">
        <div className="enterPin-modal-title">
          {trans('general.title.enterPin')}
        </div>
        <CPPin ref={pinRef} pin={6} onChangeValue={setPin} />
        <div
          className="enterPin-modal-forgot-pin"
          onClick={() => {
            popLastAndPushModal({
              key: RESETPIN_MODAL_KEY,
              content: (
                <ResetPinModal mobile={mobile} state={state}></ResetPinModal>
              ),
              showAnim: false,
            });
          }}>
          {trans('general.btn.forgotPin')}
        </div>
      </div>
    </div>
  );
}

export default EnterPinModal;
