import React from 'react';
import PropTypes from 'prop-types';

import './CPListing.scss';
import '../../../App.js';

CPListing.propTypes = {
  listStyle: PropTypes.string,
  onClick: PropTypes.func,
  leftItem: PropTypes.any,
  topLeftItem: PropTypes.any,
  topRightItem: PropTypes.any,
  bottomLeftItem: PropTypes.any,
  bottomRightItem: PropTypes.any,
  listBottomRightContainer: PropTypes.string,
};

CPListing.defaultProps = {
  onClick: () => console.log('no listing detail'),
  topLeftItem: 'top left',
  topRightItem: 'top right',
  bottomLeftItem: 'bottom left',
  bottomRightItem: 'bottom right',
};

export default function CPListing({
  listStyle,
  onClick,
  leftItem,
  topLeftItem,
  topRightItem,
  bottomLeftItem,
  bottomRightItem,
  listBottomRightContainer,
}) {
  return (
    <div
      className={`listing-container ${listStyle}`}
      onClick={() => {
        if (onClick) onClick();
      }}>
      {leftItem && <div className="listing-left-container">{leftItem}</div>}
      <div className={`listing-right-container`}>
        <div className={`listing-top`}>
          <div className={`listing-top-left`}>{topLeftItem}</div>
          <div className={`listing-top-right`}>{topRightItem}</div>
        </div>
        <div className={`listing-bottom`}>
          <div className={`listing-bottom-left`}>{bottomLeftItem}</div>
          <div className={`listing-bottom-right ${listBottomRightContainer}`}>
            {bottomRightItem}
          </div>
        </div>
      </div>
    </div>
  );
}
