import React, {Component} from 'react';
import '../../../../App.js';
import Invite from '../../../../pages/promo/Invite';
import PermanentAngpao from '../../../../pages/promo/PermanentAngpao.js';
import Wheel from '../../../../pages/promo/Wheel.js';
import {setAnim} from '../../../redux/optionSlice.js';
import {store} from '../../../redux/Store.js';
import {DETECT_FW_PATH} from '../../../utils/helper.js';

class Anim extends Component {
  static AnimInstance;
  state = {show: false};

  /**
   * @param {object} config
   * @param {boolean} config.verifiedBank
   * @param {string} [config.type]
   */

  static show(config) {
    this.animInstance.showAnim(config);
  }

  static hide() {
    this.animInstance.hideAnim();
  }

  showAnim(config) {
    // if (window.location.pathname === DETECT_FW_PATH) {
    store.dispatch(setAnim(true));
    this.setState({
      show: true,
      verifiedBank: config.verifiedBank,
      type: config.type,
      props: config.props,
    });
    document.body.style.overflow = 'hidden';
    // }
  }

  hideAnim() {
    this.setState({show: false});
    store.dispatch(setAnim(false));
    sessionStorage.removeItem('anim');
    document.body.style.overflow = 'scroll';
  }

  render() {
    const {show, verifiedBank, type, props} = this.state;

    return show ? (
      <div className="spinner-container">
        {type === 'invite' ? (
          <Invite verifiedBank={verifiedBank} />
        ) : type === 'permanent' ? (
          <PermanentAngpao />
        ) : type === 'wheel' ? (
          <Wheel {...props} />
        ) : null}
      </div>
    ) : null;
  }
}

export default Anim;
