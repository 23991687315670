import React, {createContext, useState, useContext, useEffect} from 'react';

const MsgModalContext = createContext();

// example of msgmodalStack object:
// key: RESETPIN_MODAL_KEY,
// content: (
//   <ResetPinModal mobile={mobile} state={state}></ResetPinModal>
// ), modal component
// showAnim: false, boolean
export class MsgModalObject {
  /**
   * @constructor
   * @param {object} param
   * @param {string} param.key
   * @param {React.ComponentType<any>} param.content
   * @param {boolean} param.showAnim
   */
  constructor({key = null, content, showAnim = false}) {
    this.key = key;
    this.content = content;
    this.showAnim = showAnim;
  }
}

export const MsgModalProvider = ({children}) => {
  const [msgModalStack, setMsgModalStack] = useState([]);

  const popLastAndPushMsgModal = (modalContent) => {
    popMsgModal();
    pushMsgModal(modalContent);
  };

  const pushMsgModal = (modalContent) => {
    setMsgModalStack((prevStack) => {
      let filterStack = prevStack;

      //this is to prevent added redundant modal with same key
      if (modalContent.key) {
        filterStack = filterStack.filter((value) => {
          return value.key != modalContent.key;
        });
      }

      return [...filterStack, modalContent];
    });
  };

  const popMsgModal = () => {
    setMsgModalStack((prevStack) => {
      if (prevStack.length === 1) {
        document.body.style.overflow = 'scroll';
      }
      return prevStack.slice(0, -1);
    });
  };

  const clearMsgModals = () => {
    document.body.style.overflow = 'scroll';
    setMsgModalStack([]);
  };

  return (
    <MsgModalContext.Provider
      value={{
        msgModalStack,
        pushMsgModal,
        popMsgModal,
        popLastAndPushMsgModal,
        clearMsgModals,
      }}>
      {children}
    </MsgModalContext.Provider>
  );
};

export const useMsgModal = () => {
  return useContext(MsgModalContext);
};
