import React, {useEffect, useRef, useState} from 'react';
import './ButtonPaginationComponent.scss';
import {GetPagination} from '../../../resources/image/Image';
import FlatList from '../listing/FlatList';

const ButtonPaginationComponent = ({
  data,
  itemsPerPage,
  renderItem,
  getStatus,
}) => {
  const Pagination = GetPagination();
  const mainContainerRef = useRef(null);
  const paginationRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate the total number of pages
  const totalPages = Math.max(Math.ceil(data.length / itemsPerPage), 1);

  // Slice the data to display only the items for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = data.slice(startIndex, endIndex);

  useEffect(() => {
    //whenever data changed, reset the to first page
    setCurrentPage(1);
  }, [data]);

  return (
    <div ref={mainContainerRef} className="button-pagination-listing-container">
      <div style={{paddingBottom: `${paginationRef?.current?.clientHeight}px`}}>
        <FlatList
          data={currentData}
          getStatus={getStatus}
          renderItem={renderItem}></FlatList>
      </div>

      <div ref={paginationRef} className="button-pagination-container">
        <div
          className="button-pagination-button"
          onClick={() => {
            setCurrentPage(1);
          }}>
          <img
            src={Pagination.firstArrow}
            alt="firstPage"
            className="button-pagination-button-img"
          />
        </div>
        <div
          className="button-pagination-button"
          onClick={() => {
            setCurrentPage((value) => {
              let newValue = value;
              if (newValue > 1) {
                newValue -= 1;
              }
              return newValue;
            });
          }}>
          <img
            src={Pagination.previousArrow}
            alt="previousPage"
            className="button-pagination-button-img"
          />
        </div>
        <div className="button-pagination-page-indicator">
          <div className="button-pagination-page-indicator-txt">
            {currentPage}/{totalPages}
          </div>
        </div>
        <div
          className="button-pagination-button"
          onClick={() => {
            setCurrentPage((value) => {
              let newValue = value;
              if (newValue < totalPages) {
                newValue += 1;
              }
              return newValue;
            });
          }}>
          <img
            src={Pagination.nextArrow}
            alt="nextPage"
            className="button-pagination-button-img"
          />
        </div>
        <div
          className="button-pagination-button"
          onClick={() => {
            setCurrentPage(totalPages);
          }}>
          <img
            src={Pagination.lastArrow}
            alt="lastPage"
            className="button-pagination-button-img"
          />
        </div>
      </div>
    </div>
  );
};

export default ButtonPaginationComponent;
