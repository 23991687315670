import React, {useEffect, useState, useRef} from 'react';
import './ExpandCollapseComponent.scss';

export default function ExpandCollapseComponent({
  expandedHeaderComponent,
  collapsedHeaderComponent,
  children,
}) {
  const [expanded, setExpanded] = useState(false);
  const contentRef = useRef();
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, [contentRef, children]);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div>
      <div onClick={toggleExpand}>
        {expanded ? expandedHeaderComponent : collapsedHeaderComponent}
      </div>
      <div
        ref={contentRef}
        className={`expand-collapse-container`}
        style={expanded ? {height: contentHeight} : {}}>
        {children}
      </div>
    </div>
  );
}
