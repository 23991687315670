import React, {useState, useEffect, useRef, useLayoutEffect} from 'react';
import './ComponentSlider.scss';
import {SetCssVariable} from '../../utils/helper';

const ComponentSlider = ({
  children,
  autoLoopInterval = 0,
  showInnerIndicator = false,
  showBottomFaded = false,
  sliderClassName,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [isAuto, setIsAuto] = useState(autoLoopInterval > 0 ? true : false);
  const sliderFrameRef = useRef(null);
  const sliderContentRef = useRef(null);
  const dragDiffRef = useRef(0);
  const touchStartX = useRef(0); //for mobile purposes
  const childrenCount = React.Children.count(children);

  //currently not using
  // useLayoutEffect(() => {
  //   const observer = new ResizeObserver((entries) => {
  //     const newWidth = sliderFrameRef.current.getBoundingClientRect().width;
  //     const newHeight = newWidth / (1920 / 1080);
  //     const root = document.documentElement;
  //     root.style.setProperty('--slider-height', `${newHeight}px`);
  //     ResetCurrentIndex();
  //   });

  //   observer.observe(sliderFrameRef.current);

  //   return () => {
  //     observer.disconnect();
  //   };
  // }, [sliderFrameRef, currentSlide]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (sliderFrameRef.current && sliderContentRef.current) {
        const transformedPosition = currentSlide * getSliderFrameWidth();
        sliderContentRef.current.style.transform = `translateX(-${transformedPosition}px)`;
      }
    });

    if (sliderFrameRef.current) {
      resizeObserver.observe(sliderFrameRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [currentSlide]);

  useLayoutEffect(() => {
    const sliderStyle = window.getComputedStyle(sliderFrameRef.current);
    const borderLeftWidth = parseFloat(
      sliderStyle.getPropertyValue('border-left-width'),
    );
    const borderRightWidth = parseFloat(
      sliderStyle.getPropertyValue('border-right-width'),
    );
    SetCssVariable(
      '--slider-frame-border-width',
      `${borderLeftWidth + borderRightWidth}px`,
    );
  }, []);

  useEffect(() => {
    let interval;

    if (isAuto) {
      interval = setInterval(() => {
        goToNextSlide();
      }, autoLoopInterval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [autoLoopInterval, isAuto]);

  const goToSlide = (index) => {
    setIsAuto(false);
    setCurrentSlide(index);
  };

  const goToNextSlide = () => {
    setCurrentSlide((prevIndex) => {
      // Increment the index by 1
      let newIndex = prevIndex + 1;

      // Check if the newIndex exceeds the last index
      if (newIndex >= childrenCount) {
        // Set the newIndex to the first index (loop back to the first image)
        newIndex = 0;
      }

      return newIndex;
    });
  };

  const goToPrevSlide = () => {
    setCurrentSlide((prevIndex) => {
      // Increment the index by 1
      let newIndex = prevIndex - 1;

      // Check if the newIndex exceeds the last index
      if (newIndex < 0) {
        // Set the newIndex to the first index (loop back to the first image)
        newIndex = childrenCount - 1;
      }

      return newIndex;
    });
  };

  const handleDragStart = (event) => {
    setIsAuto(false);
    setIsDragging(true);
    sliderContentRef.current.style.transition = 'none';
    dragDiffRef.current = 0;
    touchStartX.current = event.touches && event.touches[0].clientX;
  };

  const handleDragEnd = (event) => {
    const sliderWidth = getSliderFrameWidth();
    console.log(sliderWidth);
    const dragDistance = dragDiffRef.current;
    if (Math.abs(dragDistance) > sliderWidth / 3) {
      if (dragDistance > 0) {
        goToPrevSlide();
      } else {
        goToNextSlide();
      }
    } else {
      ResetCurrentIndex();
    }
    ReenableAuto();
    setIsDragging(false);
    sliderContentRef.current.style.transition = '';
    dragDiffRef.current = 0;
  };

  const handleDragMove = (event) => {
    if (isDragging) {
      const slider = sliderContentRef.current;
      let movementX;
      if (event.touches) {
        movementX = event.touches[0].clientX - touchStartX.current;
        dragDiffRef.current = movementX;
      } else {
        movementX =
          event.movementX || event.mozMovementX || event.webkitMovementX || 0;
        dragDiffRef.current += movementX;
      }
      // console.log(movementX);

      const transformedPosition =
        currentSlide * getSliderFrameWidth() - dragDiffRef.current;
      const maxTranslateX = (childrenCount - 1) * getSliderFrameWidth();
      if (transformedPosition < 0 || transformedPosition > maxTranslateX) {
        dragDiffRef.current = 0;
        return;
      }
      slider.style.transform = `translateX(-${transformedPosition}px)`;
    }
  };

  function ReenableAuto() {
    if (autoLoopInterval > 0) {
      setIsAuto(true);
    }
  }

  function ResetCurrentIndex() {
    const transformedPosition = currentSlide * getSliderFrameWidth();
    sliderContentRef.current.style.transform = `translateX(-${transformedPosition}px)`;
  }

  function GetIndicatorView(isInner = false) {
    return (
      <div
        className="slide-indicators-container"
        style={
          isInner
            ? {
                position: 'absolute',
                bottom: '37px',
                left: '0px',
                right: '0px',
                justifyContent: 'center',
              }
            : {}
        }>
        {React.Children.map(children, (_, index) => (
          <div
            className={
              currentSlide === index
                ? 'selected-slide-indicator'
                : 'slide-indicator'
            }
            onClick={() => {
              goToSlide(index);
            }}
            key={index}></div>
        ))}
      </div>
    );
  }

  function getSliderFrameWidth() {
    const element = sliderFrameRef.current;
    const rect = element.getBoundingClientRect();
    const actualWidth = rect.width;

    return actualWidth;
  }
  return (
    <div className="slider-main-container">
      <div
        className={`image-slider ${sliderClassName}`}
        ref={sliderFrameRef}
        onMouseDown={handleDragStart}
        onMouseUp={handleDragEnd}
        onMouseLeave={handleDragEnd}
        onMouseMove={handleDragMove}
        onTouchStart={handleDragStart}
        onTouchEnd={handleDragEnd}
        onTouchCancel={handleDragEnd}
        onTouchMove={handleDragMove}>
        <div
          className="slides"
          ref={sliderContentRef}
          style={{
            transform: `${
              sliderFrameRef.current
                ? `translateX(-${currentSlide * getSliderFrameWidth()}px)`
                : 'translateX(0)'
            }`,
          }}>
          {React.Children.map(children, (child, index) => (
            <div key={index} className="slide">
              {child}
            </div>
          ))}
        </div>
        {showBottomFaded ? <div className="slider-faded-container" /> : null}
        {showInnerIndicator ? GetIndicatorView(true) : null}
      </div>
      {showInnerIndicator ? null : GetIndicatorView(false)}
    </div>
  );
};

export default ComponentSlider;
