import React, {Component} from 'react';
import {
  GetCommonByUrl,
  GetWalletByUrl,
} from '../../../../resources/image/Image';
import api from '../../../api';
import {store} from '../../../redux/Store';
import CPButton from '../../button/CPButton';
import CPDropdown from '../../dropdown/CPDropdown';
import Plane from '../Plane/Plane';
import './Bank.scss';
import {connect} from 'react-redux';

class Bank extends Component {
  static addBankInstance;
  state = {
    show: false,
    collapset: false,
    selected: [],
    availBankList: [],
    accnum: '',
    disable: false,
  };

  static show(config = {}) {
    this.addBankInstance.showAddBank(config);
  }

  static hide() {
    this.addBankInstance.hideAddBank();
  }

  showAddBank(config) {
    document.getElementById('root-style').style.overflow = 'hidden';
    const {availBankList} = store.getState().company;
    this.setState({
      show: true,
      availBankList: availBankList,
      submitBtn: config.submitBtn,
      closeBtn: config.closeBtn,
    });
  }

  hideAddBank() {
    this.setState({show: false});
    document.getElementById('root-style').style.overflow = 'visible';
  }

  reset() {
    this.setState({accnum: '', selected: {}, collapse: false, disable: false});
  }

  render() {
    const {
      show,
      collapse,
      selected,
      accnum,
      availBankList,
      submitBtn,
      closeBtn,
      disable,
    } = this.state;
    const {trans, contentUrl} = this.props;
    const Common = GetCommonByUrl(contentUrl);
    const Wallet = GetWalletByUrl(contentUrl);

    return show ? (
      <div className="bank-container">
        <div className="bank-popup">
          <div className="bank-close-container">
            <button
              className="bank-close-btn"
              onClick={() => {
                if (closeBtn) closeBtn();
                this.reset();
                this.hideAddBank();
                Plane.hide();
              }}>
              <img
                className="bank-close-icon"
                src={Common.close2}
                alt="close"
              />
            </button>
          </div>
          <div className="bank-title">
            {this.props.trans('bankAccNum.title')}
          </div>
          <div className="bank-tips">{trans('bankAccNum.important')}</div>
          <div className="bank-label">{trans('general.label.bank')}</div>
          <CPDropdown
            placeholder={true}
            data={availBankList}
            isVisible={collapse}
            onClick={() => {
              this.setState({collapse: !collapse});
            }}
            onSelectIcon={Wallet.bank[selected.bankCode]}
            onSelectTitle={selected.bankName}
            labelContainerStyle={'bank-dropdown'}
            renderRow={(item, index) => {
              return (
                <div
                  key={index}
                  className="dropdown-inner-label-container"
                  onClick={() => {
                    this.setState({collapse: false, selected: item});
                  }}>
                  <div className="comm2Bank-bank-icon-container">
                    <img
                      alt={item.bankCode}
                      src={Wallet.bank[item.bankCode]}
                      className="dropdown-bank-icon"
                    />
                  </div>
                  <div className="dropdown-label-right-container">
                    <div className="dropdown-onSelect-txt comm2bank-dropdown-bank">
                      {item.bankName}
                    </div>
                  </div>
                </div>
              );
            }}
          />
          <div className="bank-label">{trans('general.label.accNo')}</div>
          <div className="bank-input-container">
            <input
              className="bank-input"
              placeholder={trans('general.placeholder.enterAcctNo')}
              value={accnum}
              maxLength="14"
              onChange={(e) => {
                this.setState({accnum: e.target.value.replace(/\D/g, '')});
              }}
            />
          </div>
          <div className="bank-btn-container">
            <CPButton
              disabled={!accnum || selected.length === 0 || disable}
              btnBg={'bank-btn-bg'}
              btnStyle={'bank-btn'}
              title={trans('general.btn.submit')}
              onClick={() => {
                this.setState({disable: true});
                api.AddBank({
                  bankCode: selected.bankCode,
                  accNum: accnum,
                  success: (res) => {
                    if (submitBtn) submitBtn();
                    this.hideAddBank();
                    this.reset();
                  },
                });
              }}
            />
          </div>
        </div>
      </div>
    ) : null;
  }
}

const mapStateToProps = (state) => ({
  contentUrl: state.company.contentUrl,
});

export default connect(mapStateToProps, null, null, {forwardRef: true})(Bank);
