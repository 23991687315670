import React, {Component} from 'react';
import CPButton from '../../button/CPButton';
import './Notification.scss';
class Notification extends Component {
  static notificationInstance;

  state = {
    items: [],
  };

  /**
   * @description  If error no cancel button
   * @description [btnAct] - button callback
   * @description [type] - By default 'option'
   * @param {object} config
   * @param {string} [config.msg]
   * @param {('error'| 'success' | 'option')} [config.type]
   * @param {string} [config.btnTxt]
   * @param {function} [config.btnAct]
   */
  static show(config) {
    this.notificationInstance.showNotification(config);
  }

  static hide() {
    this.notificationInstance.hideNotification();
  }

  showNotification(config) {
    this.setState((prevState) => ({
      items: [
        ...prevState.items,
        {
          msg: config.msg || 'Dialog here !',
          content: config.content || null,
          type: config.type || 'option',
          btnTxt: config.btnTxt,
          btnAct: config.btnAct,
          cancelBtn: config.cancelBtn,
        },
      ],
    }));
    document.getElementById('root-style').style.overflow = 'hidden';
  }

  hideNotification(index) {
    const nItems = [...this.state.items];
    nItems.splice(index, 1);

    this.setState({
      items: nItems,
    });
    if (!nItems.length)
      document.getElementById('root-style').style.overflow = 'visible';
  }

  renderItem({trans, items}) {
    return items.map((item, index) => {
      const {
        msg,
        content,
        type,
        btnTxt = trans('dialog.btn.ok'),
        btnAct,
      } = item;

      return (
        <div className="dialog-container notification-container" key={index}>
          <div className="dialog-content-container">
            <div className="dialog-title">{msg}</div>
            <div className="dialog-content">{content}</div>
          </div>
          <div className="dialog-btn-container">
            <CPButton
              btnBg={`dialog-${type}-bg`}
              btnStyle={'dialog-btn'}
              textStyle={'dialog-btn-txt'}
              title={btnTxt}
              onClick={() => {
                if (btnAct) btnAct();
                this.hideNotification(index);
              }}
            />
          </div>
        </div>
      );
    });
  }

  render() {
    const {trans} = this.props;
    const {items} = this.state;

    return items.length ? (
      <div className="spinner-container">{this.renderItem({trans, items})}</div>
    ) : null;
  }
}

export default Notification;
