import React, {useEffect, useRef, useState} from 'react';
import './SetPassword.scss';
import {useTranslation} from 'react-i18next';
import {GetLogin, GetTextField} from '../../resources/image/Image';
import FormComponent from '../../common/components/Form/FormComponent';
import LoginCommonTf from '../../common/components/TextField/LoginCommonTf';
import PasswordTextField from '../../common/components/TextField/PasswordTextField';
import ButtonComponent_v2 from '../../common/components/button/ButtonComponent_v2';
import {clientInfo} from '../../resources';
import ReferralTextField from '../../common/components/TextField/ReferralTextfield';
import api from '../../common/api';
import {clearReferral, useNav} from '../../common/utils/helper';
import {useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';

export default function SetPassword() {
  const location = useLocation();
  const navigate = useNav();
  const loginImg = GetLogin();
  const {mobile, lineUuid} = location.state || {};
  const emailTfName = 'email';
  const pwTfName = 'password';
  const confirmPwTfName = 'confirmPassword';
  const refTfName = 'referral';
  const {t: trans} = useTranslation();
  const tfImg = GetTextField();
  const containerRef = useRef(null);
  const formRef = useRef(null);
  const [formData, setFormData] = useState({});
  const registerConfig = clientInfo.register;
  const [isRegBtnDisable, setIsRegBtnDisable] = useState(false);
  const {skipBindBank} = useSelector(({company}) => company);
  // const enableRegisterBank = clientInfo.register.enableRegisterBank;

  const handleFieldChange = (e) => {
    const {name, value} = e;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleButtonClick = async () => {
    const isValid = await formRef.current.ValidateValue({});

    if (isValid) {
      const password = formData[pwTfName];
      const ref = formData[refTfName];
      if (!skipBindBank) {
        navigate('/registerBank', {
          state: {
            userData: {
              ...formData,
              mobile: mobile,
              lineUuid: lineUuid,
            },
          },
        });
      } else {
        SignUp(mobile, ref, password, lineUuid);
      }
    }
  };

  const SignUp = (mobile, ref, password, lineUuid) => {
    setIsRegBtnDisable(true);
    api
      .SignUp({
        mobileNumber: mobile,
        referrer: ref,
        password: password,
        lineUuid,
        success: () => {
          clearReferral();
          navigate('/enterPassword', {
            state: {
              mobile: mobile,
            },
          });
        },
        //test if error will the button re-enable
      })
      .finally(() => {
        setIsRegBtnDisable(false);
      });
  };

  return (
    <div className="setPassword-container container-padding">
      <div ref={containerRef} className="setPassword-style">
        <div className="setPassword-title-section">
          <div className="setPassword-title">{trans('setPassword.title')}</div>

          <img
            className="setPassword-step-img"
            alt="step"
            src={loginImg.step2}
          />
        </div>
        <FormComponent
          ref={formRef}
          frameRef={containerRef}
          className="setPassword-form-container">
          {registerConfig.email && (
            <LoginCommonTf
              isFormChild={true}
              icon={tfImg.email}
              name={emailTfName}
              placeholder={trans('general.placeholder.email')}
              value={formData[emailTfName] || ''}
              onChange={handleFieldChange}
              isRequired={true}
              validation={(value) => {
                const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

                if (emailRegex.test(value)) {
                  return '';
                } else {
                  return 'general.errMsg.emailFormat';
                }
              }}
            />
          )}

          {registerConfig.password && (
            <PasswordTextField
              isFormChild={true}
              name={pwTfName}
              placeholder={trans('general.placeholder.password')}
              value={formData[pwTfName] || ''}
              onChange={(e) => {
                const confirmTf =
                  formRef.current.getTextFieldRefByName(
                    confirmPwTfName,
                  )?.current;
                if (confirmTf && confirmTf.isRealTimeValidation) {
                  const confirmPwValue = formData[confirmPwTfName] ?? '';
                  const pwValue = e.value;
                  if (confirmPwValue != '') {
                    if (confirmPwValue != pwValue) {
                      confirmTf.setErrMsg('general.errMsg.pwNotMatch');
                    } else {
                      confirmTf.setErrMsg('');
                    }
                  }
                }
                handleFieldChange(e);
              }}
              isRequired={true}
              enableHideShow={true}
            />
          )}
          {registerConfig.confirmPw && (
            <PasswordTextField
              isFormChild={true}
              name={confirmPwTfName}
              placeholder={trans('forgotPass.label.confirmPass')}
              value={formData[confirmPwTfName] || ''}
              onChange={handleFieldChange}
              isRequired={true}
              enableHideShow={true}
              validation={(value) => {
                if (value != formData[pwTfName] ?? '') {
                  return 'general.errMsg.pwNotMatch';
                } else {
                  return '';
                }
              }}
            />
          )}

          <ReferralTextField name={refTfName} onChange={handleFieldChange} />
        </FormComponent>
        <div className="setPassword-btn-section-container">
          <ButtonComponent_v2
            text={trans('signIn.register')}
            btnClassName={'btnV2-container-row-center-p17-r10-mainshadow'}
            btnTextClassName={'btnV2-btn-txt-s20-w700-cMain'}
            disableBtnClassName={
              'btnV2-container-row-center-p17-r10-mainshadow-disabled'
            }
            disableBtnTextClassName={'btnV2-btn-txt-s20-w700-cMain-disabled'}
            disable={isRegBtnDisable}
            onClick={() => {
              handleButtonClick();
            }}
          />
        </div>
      </div>
    </div>
  );
}
