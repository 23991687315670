import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {GetCommon, GetGame} from '../../../resources/image/Image';
import './GameCardComponent.scss';
import GameInfoItemComponent from '../../../common/components/GameInformationComponent/GameInfoItemComponent';
import {useTranslation} from 'react-i18next';

export default function GameCardComponent({
  className,
  item,
  onClick,
  updateHeightListener = false,
}) {
  const {t: trans} = useTranslation();
  const mainContainerRef = useRef(null);
  const Common = GetCommon();
  const Game = GetGame();
  const gameName = item.gamename;
  const gamePlatform = item.platform ? item.platform : item.provider;
  const img = item.imgprefix;
  const imgName = `${item.provider}${item.gameid}`;
  const [isFlipped, setIsFlipped] = useState(false);

  useLayoutEffect(() => {
    const observer = new ResizeObserver((entries) => {
      const rect = mainContainerRef.current.getBoundingClientRect();
      const newHeight = rect.width * 1.25; //1.25 is the ratio follow figma 150(height)/120(width)
      const root = document.documentElement;
      root.style.setProperty('--game-card-height', `${newHeight}px`);
    });

    //just implement the listener to first grid item, because if many data it will be laggy.
    if (updateHeightListener) {
      observer.observe(mainContainerRef.current);
    }

    return () => {
      if (updateHeightListener) {
        observer.disconnect();
      }
    };
  }, [mainContainerRef]);

  function formatInfo(info) {
    return info ? info : '-';
  }
  return (
    <div
      ref={mainContainerRef}
      className={`game-card-perspective-container ${className}`}>
      <div
        className={`game-card-main-container ${
          isFlipped ? 'flipped-game-container' : ''
        }`}
        onClick={onClick}>
        <div className="game-item-container">
          <img
            alt="info"
            src={Game.commonIcon.info}
            className="game-info-img"
            onClick={(e) => {
              e.stopPropagation();
              setIsFlipped(true);
            }}
          />
          <img
            className="game-item-image"
            alt={`${gameName}`}
            src={
              img.trim() === ''
                ? Game.gameImg[imgName]
                  ? Game.gameImg[imgName]
                  : Game.defaultImg[gamePlatform]
                : img + imgName + '.webp'
            }
            // onClick={onClick}
          />
        </div>
        <div className="game-info-container">
          <img
            alt="close"
            src={Game.commonIcon.close}
            className="game-info-close-img"
            onClick={(e) => {
              e.stopPropagation();
              setIsFlipped(false);
            }}
          />
          <div className="game-info-scrollable-container">
            <img
              src={
                img.trim() === ''
                  ? Game.gameImg[imgName]
                    ? Game.gameImg[imgName]
                    : Game.defaultImg[gamePlatform]
                  : img + imgName + '.webp'
              }
              alt={`${gameName}`}
              className="game-info-small-logo"
            />
            <div className="game-info-display-container">
              {item.game_info?.map((info, index) => {
                return (
                  <GameInfoItemComponent
                    key={index}
                    title={trans(info.title)}
                    value={formatInfo(info.value)}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
