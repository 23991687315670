import React from 'react';
import PropTypes from 'prop-types';
import './PaymentSupportedCardComponent.scss';
import {GetWallet} from '../../../resources/image/Image';
import {useTranslation} from 'react-i18next';

PaymentSupportedCardComponent.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

PaymentSupportedCardComponent.defaultProps = {
  title: '',
};

export default function PaymentSupportedCardComponent({ title }) {
  
  const Wallet = GetWallet();
  const {t: trans} = useTranslation();

  //Add more join our community icons here.
  const paymentTypeList = [
    {imgSrc : Wallet.bank.KBANK  , imgAlt : "kbank-icon"},  
    {imgSrc : Wallet.bank.BANGKOKBANKIcon  , imgAlt : "bangkokbank-icon"},  
    {imgSrc : Wallet.bank.KTBIcon  , imgAlt : "ktb-icon"},  
    {imgSrc : Wallet.bank.KRUNGSRIBANKIcon  , imgAlt : "krungsribank-icon"},  
    {imgSrc : Wallet.bank.THANACHARTBANKIcon  , imgAlt : "thanachartbankicon-icon"},  
    {imgSrc : Wallet.bank.GSBIcon  , imgAlt : "gsb-icon"},  
    {imgSrc : Wallet.paymentmethod.truemoneywalletIcon  , imgAlt : "truemoneywallet-icon"},
    {imgSrc : Wallet.bank.SCBIcon  , imgAlt : "scb-icon"},  
    {imgSrc : Wallet.bank.CIMBTIcon  , imgAlt : "cimbt-icon"},  
    {imgSrc : Wallet.bank.TMBIcon  , imgAlt : "tmb-icon"},  
    // {imgSrc : Wallet.paymentmethod.bitcoinIcon  , imgAlt : "bitcoin-icon"},  
    // {imgSrc : Wallet.paymentmethod.tetherIcon  , imgAlt : "tether-icon"},  
    // {imgSrc : Wallet.paymentmethod.mastercardIcon  , imgAlt : "mastercard-icon"},  
    // {imgSrc : Wallet.paymentmethod.visaIcon  , imgAlt : "visa-icon"},  
    // {imgSrc : Wallet.paymentmethod.fpxIcon  , imgAlt : "fpx-icon"},  
    {imgSrc : Wallet.bank.BAACIcon  , imgAlt : "baac-icon"},  
    {imgSrc : Wallet.bank.CITIIcon  , imgAlt : "citi-icon"},  
    {imgSrc : Wallet.bank.UOBTIcon  , imgAlt : "uob-icon"},  
  ]

  function RenderIconElements() {
    return paymentTypeList.map((item, index) => {
      return (
        <div key={index}> <img src={item.imgSrc} alt={item.imgAlt}/> </div>
      );
    })
  }

  return (
      <div className="paymentSupported-container">
        {/* set text to change according to language selected */}
        <div className="paymentSupported-title">{title}</div>
        <div className="paymentSupported-icons"> 
          {/*Renders Icon Elements*/}
          <RenderIconElements/>
        </div> 
      </div>
  );
}
