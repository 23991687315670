import React, {forwardRef, useEffect, useRef, useState} from 'react';
import './LoginCommonTf.scss';
import BaseTextField from './BaseTextField';
import {useTranslation} from 'react-i18next';
import {GetTextField} from '../../../resources/image/Image';

const LoginCommonTf = forwardRef(
  (
    {
      icon,
      isLoading: externalIsLoading, //externalIsLoading null for auto show loading while validating.
      showErrImg = false,
      ...props
    },
    ref,
  ) => {
    const tfImg = GetTextField();
    const {t: trans} = useTranslation();
    const containerRef = useRef(null);
    const [errMsg, setErrMsg] = useState('');
    const [isFocused, setIsFocused] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      setIsLoading(externalIsLoading);
    }, [externalIsLoading]);

    const handleIsValidating = (value) => {
      if (externalIsLoading == null) {
        setIsLoading(value);
      }
    };

    const handleErrMsg = (errMsg) => {
      setErrMsg(errMsg);
    };

    return (
      <div
        ref={containerRef}
        className="loginCommonTf-container"
        style={props.disabled ? {opacity: '0.7'} : {}}>
        <div
          className={`loginCommonTf-input-container ${
            errMsg ? 'loginCommonTf-error-input-container' : ''
          } ${isFocused ? 'loginCommonTf-focus-input-container' : ''}`}>
          <div className="loginCommonTf-icon-container">
            <img
              className="loginCommonTf-icon-img"
              alt={props.name ? props.name : 'icon'}
              src={icon}
            />
          </div>
          <div style={{flex: '1'}}>
            <BaseTextField
              {...props}
              inputClassName={'loginCommonTf-input'}
              placeholderClassName={'loginCommonTf-placeholder'}
              containerRef={containerRef}
              ref={ref}
              errMsgCallBack={handleErrMsg}
              isFocusedCallBack={setIsFocused}
              isValidatingCallBack={handleIsValidating}
            />
          </div>
          {isLoading ? (
            <img
              className="loginCommonTf-loading-img"
              alt={`loading`}
              src={tfImg.loading}
            />
          ) : null}
          {showErrImg && errMsg && !isLoading ? (
            <img
              className="loginCommonTf-error-img"
              alt={`error`}
              src={tfImg.error}
            />
          ) : null}
        </div>
        {errMsg ? (
          <div className="loginCommonTf-error-msg">{trans(errMsg)}</div>
        ) : null}
      </div>
    );
  },
);

export default LoginCommonTf;
