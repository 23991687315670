import React, {Suspense} from 'react';
import ReactDom from 'react-dom';
import TagManager from 'react-gtm-module';
import {Provider} from 'react-redux';
import {BrowserRouter, MemoryRouter} from 'react-router-dom';

import App from './App';
import {store} from './common/redux/Store';
import {clientInfo} from './resources';
import {FocusComponentProvider} from './common/context/FocusComponentContext';
import {ModalProvider} from './common/context/ModalContext';
import {MsgModalProvider} from './common/context/MsgModalContext';

ReactDom.render(
  <Suspense fallback={<div>Loading...</div>}>
    <Provider store={store}>
      <BrowserRouter>
        <FocusComponentProvider>
          <ModalProvider>
            <MsgModalProvider>
              <App />
            </MsgModalProvider>
          </ModalProvider>
        </FocusComponentProvider>
      </BrowserRouter>
    </Provider>
  </Suspense>,

  document.querySelector('#root'),
);

if (module.hot) {
  module.hot.accept();
}
