import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import './PopUpComponent.scss';
import {useFocusComponent} from '../../context/FocusComponentContext';
import FocusComponent from '../FocusComponent/FocusComponent';

export default function PopUpComponent({
  keyProps, //if have more than two, need to specific key in order to specific the component
  children,
  popUpElement,
  className,
  onClick,
  isOpen = false,
  setIsOpen,
  sameWidth = true,
}) {
  const {focusComponent, createFocusComponent, clearFocusComponent} =
    useFocusComponent();
  const positionRef = useRef(null);

  useLayoutEffect(() => {
    return () => {
      clearFocusComponent(keyProps);
    };
  }, []);

  useEffect(() => {
    if (!focusComponent) {
      setIsOpen(false);
    }
  }, [focusComponent]);

  useEffect(() => {
    if (isOpen) {
      RenderFocusComponent();

      const handleResize = () => {
        RenderFocusComponent();
      };

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    } else {
      clearFocusComponent(keyProps);
    }
  }, [isOpen, popUpElement]);

  function RenderFocusComponent() {
    const focusStyle = {
      top: 0,
      left: 0,
    };
    if (positionRef.current) {
      const rect = positionRef.current.getBoundingClientRect();
      focusStyle.top = rect.top;
      focusStyle.left = rect.left;
      if (sameWidth) {
        focusStyle.width = rect.width;
      }
    }
    createFocusComponent({
      key: keyProps,
      content: (
        <FocusComponent style={focusStyle}>{popUpElement}</FocusComponent>
      ),
    });
  }

  return (
    <div className={className} onClick={onClick} ref={positionRef}>
      {children}
    </div>
  );
}
