import React from 'react';
import PropTypes from 'prop-types';
import './JoinOurCommunityCardComponent.scss';
import {GetCommon} from '../../../resources/image/Image';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {clientInfo} from '../../../resources';

JoinOurCommunityCardComponent.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

JoinOurCommunityCardComponent.defaultProps = {
  title: '',
};

export default function JoinOurCommunityCardComponent({title}) {
  const Common = GetCommon();
  const {t: trans} = useTranslation();
  const {csLineId} = useSelector(({company}) => company);

  //Add more join our community icons here.
  const joinOurCommunityList = [
    // {
    //   link: 'https://www.facebook.com/',
    //   imgSrc: Common.facebook,
    //   imgAlt: 'facebook-icon',
    // },
    {
      link: 'https://www.line.me',
      imgSrc: Common.line4,
      imgAlt: 'line-icon',
      onClick: () => {
        window.open(`${clientInfo.lineURL}${csLineId}`, '_blank');
      },
    },
    // {
    //   link: 'https://www.instagram.com/',
    //   imgSrc: Common.instagram,
    //   imgAlt: 'instagram-icon',
    // },
    // {
    //   link: 'https://www.youtube.com/',
    //   imgSrc: Common.youtube,
    //   imgAlt: 'youtube-icon',
    // },
    // {
    //   link: 'https://www.whatsapp.com/',
    //   imgSrc: Common.whatsapp,
    //   imgAlt: 'whatsapp-icon',
    // },
    // {link: 'https://www.vk.com/', imgSrc: Common.vk, imgAlt: 'vk-icon'},
    // {
    //   link: 'https://www.tiktok.com/',
    //   imgSrc: Common.tiktok,
    //   imgAlt: 'tiktok-icon',
    // },
    // {
    //   link: 'https://web.telegram.org/',
    //   imgSrc: Common.telegram,
    //   imgAlt: 'telegram-icon',
    // },
  ];

  function RenderIconElements() {
    return joinOurCommunityList.map((item, index) => {
      return (
        <div key={index} onClick={item.onClick}>
          <img src={item.imgSrc} alt={item.imgAlt} />
        </div>
      );
    });
  }

  return (
    <div className="joinourcommunity-container">
      {/* set text to change according to language selected */}
      <div className="joinourcommunity-title">{title}</div>
      <div className="joinourcommunity-icons">
        {/*Renders Icon Elements*/}
        <RenderIconElements />
      </div>
    </div>
  );
}
