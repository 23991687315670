import React from 'react';
import './BackNav.scss';
import BackButton from '../navigationItem/BackButton';

const BackNav = ({
  rightComponent = null,
  backAction = null,
  middleComponent = null,
  hideBackNav = false,
  className,
}) => {
  return (
    <div className={`back-nav-container ${className}`}>
      {hideBackNav ? <div></div> : <BackButton onClick={backAction} />}
      {middleComponent}
      {rightComponent}
    </div>
  );
};

export default BackNav;
