import React, {useEffect, useRef, useState} from 'react';
import './VerifyMobile.scss';
import {useTranslation} from 'react-i18next';
import {GetLogin, GetTextField} from '../../resources/image/Image';
import LoginCommonTf from '../../common/components/TextField/LoginCommonTf';
import ButtonComponent_v2 from '../../common/components/button/ButtonComponent_v2';
import PinTextField from '../../common/components/TextField/PinTextField';
import FormComponent from '../../common/components/Form/FormComponent';
import Timer from '../../common/components/timer/Timer';
import {clearReferral, useNav} from '../../common/utils/helper';
import {Navigate, useLocation, useParams} from 'react-router-dom';
import {millisecondToTime} from '../../common/utils/format';
import api from '../../common/api';
import Dialog from '../../common/components/root/dialog/Dialog';
import {
  ContinueJourneyNavigate,
  LoginAccount,
  VERIFYMOBILE_PREV_PAGE_KEY,
} from '../../common/utils/loginHelper';
import LoginMobileTf from '../../common/components/TextField/LoginMobileTf';
import {clientInfo} from '../../resources';
import RegisterStepImgComponent from '../../common/components/Register/RegisterStepImgComponent';
import {
  MsgModalObject,
  useMsgModal,
} from '../../common/context/MsgModalContext';
import ErrMsgModal from '../../common/components/modal/ErrMsgModal';
import {useSelector} from 'react-redux';


export default function VerifyMobile() {
  const {section} = useParams();
  const location = useLocation();
  const {mobile} = location.state || {};

  let isInvalid = false;
  switch (section) {
    //empty state mobile, lineuuid from local
    case VERIFYMOBILE_PREV_PAGE_KEY.line:
      const lineUuid = localStorage.getItem('line-uuid');
      if (!lineUuid) isInvalid = true;
      break;
    //prefilled with state mobile
    case VERIFYMOBILE_PREV_PAGE_KEY.login:
    case VERIFYMOBILE_PREV_PAGE_KEY.register:
      if (!mobile) isInvalid = true;
      break;
    case VERIFYMOBILE_PREV_PAGE_KEY.forgotPassword:
      isInvalid = false;
      break;
    default:
      isInvalid = true;
      break;
  }

  if (isInvalid) return <Navigate to="/" />;

  return <VerifyMobileContent section={section} />;
}

function VerifyMobileContent({section}) {
  const {pushMsgModal} = useMsgModal();
  const navigate = useNav();
  const {t: trans} = useTranslation();
  const loginImg = GetLogin();
  const tfImg = GetTextField();
  const containerRef = useRef(null);
  const location = useLocation();
  const {mobile, fromForgotPin, userData} = location.state || {};

  const countdown = 300;
  const otpTfName = 'otp';
  const mobileTfName = 'mobile';
  const mobileCountryCode = 'mCountryCode';
  const mobileFormRef = useRef(null);
  const formRef = useRef(null);
  const submitBtnRef = useRef(null);
  const [formData, setFormData] = useState({
    [mobileCountryCode]: clientInfo.defaultMobileCode,
  });
  const [prefix, setPrefix] = useState('');
  const [time, setTime] = useState(0);
  const [refreshKey, setRefreshKey] = useState(0);
  const [req, setReq] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const isExistedUser = useRef(false);
  let shouldSetIsVerifying = true;
  const {skipBindBank} = useSelector(({company}) => company); 
  // const enableRegisterBank = clientInfo.register.enableRegisterBank;
  const showOtp = prefix || mobile;
  useEffect(() => {
    let finalMobile = null;
    if (mobile) {
      finalMobile = mobile;
    } else {
      if (mobileFormRef.current.isValid) finalMobile = formData[mobileTfName];
    }

    //to re-trigger for those invalid phone.
    if (finalMobile) RequestOTP(finalMobile);
  }, [refreshKey]);

  useEffect(() => {
    if (time <= 0) {
      setReq(false);
    } else {
      setReq(true);
    }
  }, [time]);

  const GetCurrentLocalOTP = () => {
    const localTime = JSON.parse(sessionStorage.getItem('otp')) ?? {};
    return localTime;
  };

  function RequestOTP(mobile) {
    const localTime = GetCurrentLocalOTP();
    const {mobile: oldMobile} = localTime;

    if (
      mobile &&
      (localTime === null || localTime == {} || oldMobile !== mobile)
    ) {
      ReqOTP(mobile);
    } else {
      const countdownTimer = millisecondToTime(localTime.timer, countdown);

      if (countdownTimer === undefined || countdownTimer === 0) {
        ReqOTP(mobile);
        return;
      }

      setPrefix(localTime.prefix);
      setTime(countdownTimer);
    }
  }

  const ResetOTPInfo = () => {
    setTime(0);
    setPrefix('');
    handleFieldChange({name: otpTfName, value: ''});
  };

  function ReqOTP(userMobile) {
    console.log(
      `checking ${mobile} and ${fromForgotPin} and ${isExistedUser.current}`,
    );
    api.RequestOtp({
      mobileNumber: userMobile,
      check: mobile
        ? fromForgotPin
          ? 'no'
          : 'yes'
        : isExistedUser.current
        ? 'no'
        : 'yes',
      error: (res) => {
        Dialog.show({
          show: true,
          msg: res.msg,
          type: 'error',
          btnAct: () => {
            // if (fromForgotPin) {
            setReq(false);
            // } else {
            //   setRefreshKey((value) => value + 1);
            // }
          },
        });
      },
      success: (res) => {
        const timestamp = Math.floor(Date.now() / 1000);
        const newPrefix = res.data.prefix;
        const data = {
          timer: timestamp,
          prefix: newPrefix,
          mobile: userMobile,
        };

        setPrefix(res.data.prefix);
        sessionStorage.setItem('otp', JSON.stringify(data));
        setTime(countdown);
      },
    });
  }

  const handleFieldChange = (e) => {
    const {name, value} = e;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleContinueClicked = async () => {
    //either the mobile get from previous page or enter by user
    let finalMobile = mobile ? mobile : formData[mobileTfName];
    const lineUuid = localStorage.getItem('line-uuid');
    const isValid = await formRef.current.ValidateValue({});

    if (isValid) {
      setIsVerifying(true);
      api
        .VerifyOtp({
          mobileNumber: finalMobile,
          otp: `${prefix}-${formData[otpTfName]}`,
          success: () => {
            localStorage.setItem('verified', finalMobile);
            switch (section) {
              case VERIFYMOBILE_PREV_PAGE_KEY.login:
                if (userData) {
                  LoginAccount(userData);
                  ContinueJourneyNavigate(navigate);
                  // navigate('/', {
                  //   replace: true,
                  //   state: {
                  //     mobile: finalMobile,
                  //     from: from,
                  //     extraState: extraState,
                  //   },
                  // });
                }
                break;
              case VERIFYMOBILE_PREV_PAGE_KEY.line:
                if (isExistedUser.current) {
                  //still got api to call, remain button disabled first
                  shouldSetIsVerifying = false;
                  LineUpdate(finalMobile, lineUuid);
                } else {
                  navigate('/setPassword', {
                    state: {
                      lineUuid: lineUuid,
                      mobile: finalMobile,
                    },
                  });
                }
                break;
              case VERIFYMOBILE_PREV_PAGE_KEY.register:
                if (!skipBindBank) {
                  navigate('/registerBank', {
                    state: {
                      userData,
                    },
                  });
                } else {
                  const mobile = userData.mobile;
                  const referral = userData.referral;
                  const registerPw = userData.password;

                  SignUp(mobile, referral, registerPw);
                }
                break;
              case VERIFYMOBILE_PREV_PAGE_KEY.forgotPassword:
                navigate('/forgotPassword', {
                  state: {
                    mobile: finalMobile,
                  },
                });
              default:
                break;
            }
          },
        })
        .finally(() => {
          if (shouldSetIsVerifying) {
            setIsVerifying(false);
          }
        });

      sessionStorage.removeItem('otp');
    }
  };

  const SignUp = async (mobile, ref, password) => {
    await api.SignUp({
      mobileNumber: mobile,
      referrer: ref,
      password: password,
      success: () => {
        clearReferral();
        AutoLogin(mobile, password);
      },
    });
  };

  const AutoLogin = async (mobile, password) => {
    await api.VerifyPassword({
      userId: mobile,
      password: password,
      showErrDialog: false,
      success: () => {
        ContinueJourneyNavigate(navigate);
      },
      error: () => {
        localStorage.removeItem(`verified`);
        navigate('/login');
      },
    });
  };

  const LineUpdate = (mobile, lineUuid) => {
    api
      .LineUpdate({
        lineUuid,
        mobile: mobile,
        success: () => {
          navigate('/enterPassword', {
            state: {
              ...location?.state,
              mobile: mobile,
            },
          });
        },
      })
      .finally(() => {
        setIsVerifying(false);
      });
  };

  const ResendAction = async () => {
    if (mobile) {
      setReq(true);
      ResetOTPInfo();
      RequestOTP(mobile);
    } else {
      const isValid = await mobileFormRef.current.ValidateValue({});
      if (isValid) {
        setReq(true);
        ResetOTPInfo();
        let finalMobile = formData[mobileTfName];
        VerifyMobileNumber(finalMobile, () => {
          RequestOTP(finalMobile);
        });
      }
    }
  };

  const VerifyMobileNumber = (mobile, callback) => {
    api.VerifyMobileNumber({
      mobileNumber: mobile,
      success: (res) => {
        if (res.noData) {
          isExistedUser.current = false;
          switch (section) {
            case VERIFYMOBILE_PREV_PAGE_KEY.forgotPassword:
              setReq(false);
              pushMsgModal(
                new MsgModalObject({
                  content: <ErrMsgModal title={'dialog.err.userNotExist'} />,
                }),
              );
              return;
            default:
              break;
          }
        } else {
          isExistedUser.current = true;
        }

        if (callback) callback();
      },
      error: (_) => {
        setReq(false);
      },
    });
  };

  const mobileOnChange = (e) => {
    handleFieldChange(e);

    //if same number within the count down, it will disable resend btn
    //else it will enable the resend btn
    const currentLocalOtp = GetCurrentLocalOTP();
    if (currentLocalOtp != {}) {
      const countdownTimer = millisecondToTime(
        currentLocalOtp.timer,
        countdown,
      );

      if (
        countdownTimer != undefined &&
        countdownTimer > 0 &&
        e.value == currentLocalOtp.mobile &&
        time > 0
      ) {
        setReq(true);
      } else {
        setReq(false);
      }
    }
  };

  return (
    <div className="verifyMobile-container container-padding">
      <div className="verifyMobile-style" ref={containerRef}>
        <div className="verifyMobile-title-section">
          <div className="verifyMobile-title">
            {trans('verifyMobile.verifyTitle')}
          </div>

          {fromForgotPin ? null : <RegisterStepImgComponent step={2} />}
        </div>
        <div className="verifyMobile-content-container">
          {mobile ? (
            <LoginCommonTf
              icon={tfImg.mobile}
              placeholder={trans('general.placeholder.enterMobile')}
              value={mobile}
              disabled={true}
            />
          ) : (
            <FormComponent ref={mobileFormRef} frameRef={containerRef}>
              <LoginMobileTf
                isFormChild={true}
                name={mobileTfName}
                placeholder={trans('general.placeholder.enterMobile')}
                countryCode={formData[mobileCountryCode] || ''}
                value={formData[mobileTfName] || ''}
                isRequired={true}
                onChange={mobileOnChange}
                countryCodeOnChange={(value) => {
                  handleFieldChange({name: mobileCountryCode, value: value});
                }}
              />
            </FormComponent>
          )}

          <div className="verifyMobile-sendOTP-section">
            <div className="verifyMobile-countdown-section">
              <div className="verifyMobile-desc-section">
                <div className="verifyMobile-otp-desc">
                  {trans('verifyMobile.otpSent')}
                </div>
                <div className="verifyMobile-otp-desc">
                  {trans('verifyMobile.resendIn')}
                  <Timer
                    className={'timer-txt-s16-w700-cMain'}
                    expiresIn={time}
                    callback={() => {
                      setTime(0);
                    }}
                  />
                </div>
              </div>
            </div>
            <ButtonComponent_v2
              text={trans('button.resendOtp')}
              btnClassName={'btnV2-container-verify-mobile-resend'}
              btnTextClassName={'btnV2-container-verify-mobile-resend-cMain'}
              disableBtnClassName={
                'btnV2-container-verify-mobile-resend-disabled'
              }
              disableBtnTextClassName={'btnV2-container-verify-mobile-resend-cMain-disabled'}
              disable={req}
              onClick={async () => {
                ResendAction();
              }}
            />
          </div>

          {showOtp ? (
            <div className="verifyMobile-otp-container">
              <div className="verifyMobile-otp-code">
                {prefix ? prefix : '-'}
              </div>
              <FormComponent
                submitBtnRef={submitBtnRef}
                ref={formRef}
                frameRef={containerRef}>
                <PinTextField
                  value={formData[otpTfName] || ''}
                  isFormChild={true}
                  name={otpTfName}
                  length={6}
                  isRequired={true}
                  onChange={handleFieldChange}
                />
              </FormComponent>
            </div>
          ) : null}
        </div>

        {showOtp ? (
          <ButtonComponent_v2
            ref={submitBtnRef}
            text={trans('button.continue')}
            btnClassName={'btnV2-container-row-center-p17-r10-mainshadow'}
            btnTextClassName={'btnV2-btn-txt-s20-w700-cMain'}
            disable={isVerifying}
            onClick={handleContinueClicked}
          />
        ) : null}
      </div>
    </div>
  );
}
