import React, {useEffect, useState} from 'react';
import {GetSign_In} from '../../../resources/image/Image';
import './LanguageComponent.scss';
import i18n from '../../../i18n';
import {useModal} from '../../context/ModalContext';
import LanguageModal from '../modal/LanguageModal';
import {LANGUAGE_MODAL_KEY} from '../../utils/modalHelper';
import {GetLanguageObject} from '../../utils/helper';

const LanguageComponent = ({
  containerClassName,
  onClick,
  preventDefault = false,
}) => {
  const {pushModal} = useModal();
  const Sign_In = GetSign_In();
  const [flag, setFlag] = useState(Sign_In.languageFlag.TH);
  const getLanguageObject = GetLanguageObject();

  useEffect(() => {
    const currentLanguage = i18n.language;
    const languageObject = getLanguageObject(currentLanguage);

    setFlag(languageObject.icon);
  }, [i18n.language]);

  return (
    <div
      className={`language-container ${
        containerClassName && containerClassName
      }`}
      onClick={() => {
        if (onClick) {
          onClick();
        }

        if (!preventDefault) {
          pushModal({
            key: LANGUAGE_MODAL_KEY,
            content: <LanguageModal></LanguageModal>,
            showAnim: true,
          });
        }
      }}>
      <img className="language-icon" alt="language-icon" src={flag} />
    </div>
  );
};

export default LanguageComponent;
