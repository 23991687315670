// import React, {useState} from 'react';
// import Modal from './Modal';
// import {useDispatch, useSelector} from 'react-redux';
// import {popModal} from '../../redux/modalSlice';

// const ModalStack = () => {
//   const modalStack = useSelector((state) => state.modal.modalStack);
//   const dispatch = useDispatch();

//   const handleModalClose = () => {
//     dispatch(popModal()); // Implement the closeModal action to remove the modal from the stack
//   };

//   return (
//     <div>
//       {modalStack.map((modalContent, index) => (
//         <Modal
//           key={index}
//           isOpen={index === modalStack.length - 1}
//           handleClose={handleModalClose}>
//           {modalContent}
//         </Modal>
//       ))}
//     </div>
//   );
// };

// export default ModalStack;

import React from 'react';
import {useModal} from '../../context/ModalContext';
import ContentModal from './ContentModal';

const ModalStack = () => {
  const {modalStack, popModal} = useModal();

  return (
    <div>
      {modalStack.map((modalObj, index) => (
        <ContentModal
          key={index}
          isOpen={index === modalStack.length - 1}
          handleClose={popModal}
          showAnim={modalObj?.showAnim != null ? modalObj.showAnim : true}>
          {modalObj.content}
        </ContentModal>
      ))}
    </div>
  );
};

export default ModalStack;
