import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  open_modal: {
    open: false,
    page: '',
  },
  close_modal: {
    close: false,
    page: '',
  },
};

export const pagesSlice = createSlice({
  name: 'pages',
  initialState,
  reducers: {
    openModal: (state, action) => {
      state.open_modal = action.payload;
    },
    closeModal: (state, action) => {
      state.close_modal = action.payload;
    },
  },
});

export const {openModal, closeModal} = pagesSlice.actions;
export default pagesSlice.reducer;
