import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  sideMenuIsOpen: false,
};

export const sideMenuSlice = createSlice({
  name: 'sideMenu',
  initialState,
  reducers: {
    openSideMenu: (state, action) => {
      state.sideMenuIsOpen = action.payload;
    },
  },
});

export const {openSideMenu} = sideMenuSlice.actions;
export default sideMenuSlice.reducer;
