import axios from 'axios';
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import api from '../../common/api';
import Dialog from '../../common/components/root/dialog/Dialog';
import {GetCommon} from '../../resources/image/Image';
import {clientInfo} from '../../resources';
import {useModal} from '../../common/context/ModalContext';
import {
  ENTERPIN_MODAL_KEY,
  LINE_VERIFY_MOBILE_MODAL_KEY,
  VERIFYMOBILE_PREV_PAGE_KEY,
} from '../../common/utils/loginHelper';
import EnterPinModal from '../../common/components/modal/EnterPinModal';
import LineVerifiedMobileModal from '../../common/components/modal/LineVerifiedMobileModal';

export default function LineLogin() {
  const {pushModal} = useModal();
  const Common = GetCommon();
  const {t: trans} = useTranslation();
  const navigate = useNavigate();
  const {lineClientId, lineClientSecret} = useSelector(({company}) => company);

  useEffect(() => {
    if (lineClientId) handleLine();
  }, [lineClientId]);

  async function handleLine() {
    const params = Object.fromEntries(
      new URLSearchParams(document.location.search).entries(),
    );

    const {code, state} = params;
    if (!code || !state) {
      navigate('/signIn', {replace: true});
      return;
    }

    if (state !== localStorage.getItem('line-state')) {
      navigate('/signIn', {replace: true});
      return;
    }
    localStorage.removeItem('line-state');

    const data = new URLSearchParams({
      grant_type: 'authorization_code',
      code: code,
      redirect_uri: `${window.location.origin}/linelogin`,
      client_id: lineClientId,
      client_secret: lineClientSecret,
    });

    const resToken = await axios(clientInfo.lineToken, {
      method: 'post',
      headers: {'content-type': 'application/x-www-form-urlencoded'},
      data,
    }).catch((err) => {
      console.log('### res err', err.response.data);
      return {err: err.response};
    });
    if (resToken.err) {
      Dialog.show({
        show: true,
        msg: `${trans('dialog.err.lineLogin')} - ${
          resToken.err.data.error_description
        }`,
        type: 'error',
      });
      navigate('/signIn', {replace: true});
      return;
    }

    console.log('### res', resToken.data);
    const {id_token} = resToken.data;

    const resVerify = await axios(clientInfo.lineVerify, {
      method: 'post',
      headers: {'content-type': 'application/x-www-form-urlencoded'},
      data: new URLSearchParams({
        id_token,
        client_id: lineClientId,
      }),
    }).catch((err) => {
      console.log('### res2 err', err.response.data);
      return {err: err.response};
    });
    if (resVerify.err) {
      Dialog.show({show: true, msg: 'LINE login error', type: 'error'});
      navigate('/signIn', {replace: true});
      return;
    }

    console.log('### res2', resVerify.data);
    const {sub: lineUuid} = resVerify.data;

    api.LineLogin({
      lineUuid,
      success: (res) => {
        const {mobile} = res.data || {};

        if (mobile) {
          localStorage.setItem('verified', mobile);
          navigate('/enterPassword', {
            state: {
              mobile: mobile,
            },
          });
          // navigate('/', {
          //   replace: true,
          // });
          // pushModal({
          //   key: ENTERPIN_MODAL_KEY,
          //   content: <EnterPinModal mobile={mobile}></EnterPinModal>,
          //   showAnim: true,
          // });
        } else {
          localStorage.setItem('line-uuid', lineUuid);
          navigate(`/${VERIFYMOBILE_PREV_PAGE_KEY.line}/verifyMobile`, {
            replace: true,
          });
          // navigate('/', {
          //   replace: true,
          // });
          // pushModal({
          //   key: LINE_VERIFY_MOBILE_MODAL_KEY,
          //   content: (
          //     <LineVerifiedMobileModal
          //       mobile={mobile}></LineVerifiedMobileModal>
          //   ),
          //   showAnim: true,
          // });
        }
      },
      error: () => {
        navigate('/signIn', {replace: true});
      },
    });
  }

  return (
    <div className="spinner-container">
      <img
        src={Common.loadingSpinner}
        className="loading-spinner-gif"
        alt="loading-spinner-gif"
      />
    </div>
  );
}
