import React, {useEffect} from 'react';
import Marquee from 'react-fast-marquee';

import './Marquee.scss';
import {GetCommon} from '../../resources/image/Image';
import api from '../../common/api';
import {useSelector} from 'react-redux';

export default function MarqueeComponent({darkTheme = false}) {
  const Common = GetCommon();
  const {marquee} = useSelector(({company}) => company);

  useEffect(() => {
    getMarquee();
  }, []);

  function getMarquee() {
    const anim = JSON.parse(sessionStorage.getItem('anim'));
    if (anim) return;
    api.AppMarquee({});
  }

  return (
    <div className="marquee-container">
      <img
        src={darkTheme ? Common.announcement2 : Common.announcement}
        alt={'marquee-icon'}
        className="marquee-icon"
      />
      <Marquee
        pauseOnHover={true}
        className={`marquee-txt ${darkTheme && 'marquee-txt-dark'}`}
        gradient={false}
        style={{zIndex: 0}}>
        {marquee}
      </Marquee>
      {/* <img
        src={Common.speaker}
        alt={'speaker-icon'}
        className="speaker-icon"
      /> */}
    </div>
  );
}
