import React, {useState} from 'react';
import PropTypes from 'prop-types';
import './CPDropdown.scss';
import {useTranslation} from 'react-i18next';
import Plane from '../root/Plane/Plane';

CPDropdown.propTypes = {
  labelContainerStyle: PropTypes.any,
  leftContainer: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onSelectTitle: PropTypes.string,
  onSelectItem: PropTypes.string,
  onSelectIcon: PropTypes.string,
  rightStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onClick: PropTypes.func,
  isVisible: PropTypes.bool,
  data: PropTypes.array,
  renderRow: PropTypes.any,
  placeholder: PropTypes.bool,
};

CPDropdown.defaultProps = {
  onClick: () => alert('dropdown'),
  isVisible: false,
  placeholder: false,
};

export default function CPDropdown({
  labelContainerStyle,
  onSelectTitle,
  onSelectItem,
  onSelectIcon,
  rightStyle,
  onClick,
  isVisible,
  data,
  renderRow,
  placeholder,
}) {
  const {t: trans} = useTranslation();
  const [hidePlaceholder, setHidePlaceholder] = useState(
    placeholder ? false : true,
  );

  return (
    <div
      className={`dropdown-label-container ${labelContainerStyle}`}
      style={{zIndex: isVisible ? 1002 : 'unset'}}
      onClick={() => {
        if (isVisible) {
          Plane.hide();
        } else {
          Plane.show();
        }
        if (onClick) onClick();
      }}>
      {!hidePlaceholder ? (
        <div className="dropdown-placeholder">
          {trans('general.placeholder.selectBank')}
        </div>
      ) : (
        <div className="dropdown-inner-label-container">
          <div className="dropdown-label-left-container">
            {onSelectIcon ? (
              <img
                alt="dropdown-bank-icon"
                src={onSelectIcon}
                className="dropdown-bank-icon"
              />
            ) : (
              <div className="bank-dropdown-list-icon" />
            )}
          </div>
          <div className="dropdown-label-right-container" style={rightStyle}>
            <div className="dropdown-onSelect-txt">{onSelectTitle}</div>
            {onSelectItem && (
              <div className="dropdown-onSelect-txt dropdown-item">
                {onSelectItem}
              </div>
            )}
          </div>
        </div>
      )}
      <div
        id="dropdown-list"
        className={`${
          isVisible ? 'dropdown-list-style' : 'dropdown-list-hide'
        }`}>
        {data.map((item, index) => {
          return (
            <div
              key={index}
              className="dropdown-list-container"
              onClick={() => {
                if (onClick) onClick();
                Plane.hide();
                setHidePlaceholder(true);
              }}>
              {renderRow(item, index)}
            </div>
          );
        })}
      </div>
    </div>
  );
}
