import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  more_Slider: false,
  window_Id: '',
};

export const othersSlice = createSlice({
  name: 'others',
  initialState,
  reducers: {
    moreSlider: (state, action) => {
      state.more_Slider = action.payload;
    },
    setWindowId: (state, action) => {
      state.window_Id = action.payload;
    },
  },
});

export const {moreSlider, setWindowId} = othersSlice.actions;
export default othersSlice.reducer;
