import {forwardRef, useRef, useState} from 'react';

import './CPPin.scss';
import '../../../App.js';
import {GetCommon} from '../../../resources/image/Image';

const CPPin = forwardRef(({pin = 4, onChangeValue}, ref) => {
  const Common = GetCommon();
  if (ref) {
    ref.current = {
      reset: (animation) => {
        if (animation) {
          pinFieldContainerRef.current.classList.add('incorrect');
          setTimeout(() => {
            setInput('');
          }, 200);
          setTimeout(() => {
            pinFieldContainerRef.current.classList.remove('incorrect');
          }, 500);
        } else setInput('');
      },
    };
  }

  const pinFieldContainerRef = useRef(null);
  const [input, setInput] = useState('');

  const handleInput = (e) => {
    if (input.length < pin) {
      setInput(input + e.target.value);
      if (onChangeValue && typeof onChangeValue == 'function') {
        onChangeValue(input + e.target.value);
      }
    }
  };

  const handleBackspace = (e) => {
    setInput(input.slice(0, -1));
    if (onChangeValue && typeof onChangeValue == 'function') {
      onChangeValue(input.slice(0, -1));
    }
  };

  return (
    <div className="CPPin-container">
      <div ref={pinFieldContainerRef} className="CPPin-field-container">
        {[...Array(pin)].map((val, i) => {
          let className = 'CPPin-field';
          if (input[i]) {
            className += ' active';
          }

          return (
            <div className={className} key={i}>
              <span />
            </div>
          );
        })}
      </div>
      <div className="CPPin-button-container">
        <button className="CPPin-button" value={1} onClick={handleInput}>
          1
        </button>
        <button className="CPPin-button" value={2} onClick={handleInput}>
          2
        </button>
        <button className="CPPin-button" value={3} onClick={handleInput}>
          3
        </button>
        <button className="CPPin-button" value={4} onClick={handleInput}>
          4
        </button>
        <button className="CPPin-button" value={5} onClick={handleInput}>
          5
        </button>
        <button className="CPPin-button" value={6} onClick={handleInput}>
          6
        </button>
        <button className="CPPin-button" value={7} onClick={handleInput}>
          7
        </button>
        <button className="CPPin-button" value={8} onClick={handleInput}>
          8
        </button>
        <button className="CPPin-button" value={9} onClick={handleInput}>
          9
        </button>
        <button
          className={`CPPin-button ${!null ? 'hidden' : ''}`}
          disabled={!null}
          value={'x'}>
          {'x'}
        </button>
        <button className="CPPin-button" value={0} onClick={handleInput}>
          0
        </button>
        <button
          className={`CPPin-button custom ${!input.length ? 'hidden' : ''}`}
          onClick={handleBackspace}
          disabled={!input.length}>
          <img
            className="CPPin-backspace"
            alt="backspace"
            src={Common.backspace}
          />
        </button>
      </div>
    </div>
  );
});

export default CPPin;
