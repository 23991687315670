import React from 'react';
import './ButtonComponent.scss';
import scssVariales from '../../../resources/theme/Common.scss';

export default function ButtonComponent({
  className = null,
  backgroundColor = scssVariales.GameContainerBg,
  fontSize = scssVariales.FontSize16,
  fontColor = scssVariales.MainTxt,
  text,
  onClick,
  border = scssVariales.MainBorder,
  padding = '17px 33px',
}) {
  return (
    <div  className={`button-container-component ${className === null ? '' : className}`}
          style={{
            background: backgroundColor,
            border: border,
            padding: padding,
          }}
          onClick={onClick}>
      <div className="button-text-component" style={{ color: fontColor, fontSize: fontSize}}> {text} </div>
    </div>
  );
}
