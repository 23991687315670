import React, {useEffect, useState} from 'react';
import './SignInModal.scss';
import {GetCommon, GetSign_In} from '../../../resources/image/Image';

import {useLocation, useSearchParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {customAlphabet} from 'nanoid';
import api from '../../../common/api';
import Dialog from '../../../common/components/root/dialog/Dialog';
import {clientInfo} from '../../../resources/index';
import CPBottomModal from '../../../common/components/bottomModal/CPBottomModal';
import CPButton from '../../../common/components/button/CPButton';
import {
  SPECIAL_USER,
  useNav as useNavigate,
  IsAppWrap,
} from '../../../common/utils/helper';
import {useModal} from '../../context/ModalContext';
import {
  ENTERPIN_MODAL_KEY,
  LoginWithLine,
  SIGNUP_MODAL_KEY,
} from '../../utils/loginHelper';
import EnterPinModal from './EnterPinModal';
import SignUpModal from './SignUpModal';

function SignInModal({state}) {
  const {popLastAndPushModal, popModal} = useModal();
  const Common = GetCommon();
  const Sign_In = GetSign_In();
  const {t: trans} = useTranslation();
  const location = useLocation();
  const {search} = location;
  const from = state?.from;
  const extraState = state?.extraState;
  const {lineClientId} = useSelector(({company}) => company);
  const navigate = useNavigate();

  const devMode = useSearchParams()[0].get('dev') ?? false;
  const {csLineId} = useSelector(({company}) => company);

  const [countryCode, setCountryCode] = useState(
    clientInfo.optionMobileCode[0].id,
  );
  const [mobile, setMobile] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [langModal, setLangModal] = useState('none');
  const [countryModal, setCountryModal] = useState('none');

  const validation = !mobile.length || mobile.trim().length < 7 || disabled;

  var modal = document.getElementById('signIn_modal');

  const mobileCode = [
    ...clientInfo.optionMobileCode,
    ...(devMode === 'true' ? clientInfo.devUse.mobileCode : []),
  ];

  // Hacking way to load page
  document.onreadystatechange = () => {
    setLoaded(document.readyState === 'complete');
  };

  window.onclick = function (event) {
    if (event.target === modal) {
      setCountryModal('none');
    }
  };

  function CountryOpt({title, id, img, onClick}) {
    const selected = id === countryCode;
    return (
      <div
        id={id}
        className={`signIn-bottom-modal-selected-container ${
          selected
            ? 'signIn-bottom-modal-selected-bg'
            : 'signIn-bottom-modal-unselect-bg'
        }`}
        onClick={onClick}>
        <img
          alt="signIn-bottom-modal-country-icon"
          src={Sign_In.country[img]}
          className="signIn-bottom-modal-country-icon"
        />
        <div>{title}</div>
      </div>
    );
  }

  function mobileBtn() {
    // validation
    const regEx = RegExp(/\D/g);
    if (mobile.trim().length === 0) {
      Dialog.show({
        show: true,
        msg: 'Please enter mobile number',
        type: 'error',
      });
      return;
    } else if (mobile.trim().length < 7 || regEx.test(mobile)) {
      Dialog.show({
        show: true,
        msg: 'Invalid mobile number',
        type: 'error',
      });
      return;
    }

    // prevent multiple click
    setDisabled(true);

    // calling api
    const mobileNum =
      mobile != SPECIAL_USER.substring(2) ? mobile.replace(/^0/g, '') : mobile;
    const userMobile = (countryCode + mobileNum).substring(1);
    const verify = localStorage.getItem('verified');
    const isAppWrap = IsAppWrap();
    if (userMobile == SPECIAL_USER && isAppWrap) {
      localStorage.setItem('verified', userMobile);
      popLastAndPushModal({
        key: ENTERPIN_MODAL_KEY,
        content: (
          <EnterPinModal mobile={userMobile} state={state}></EnterPinModal>
        ),
        showAnim: false,
      });
    } else {
      api
        .VerifyMobileNumber({
          mobileNumber: userMobile,
          success: (res) => {
            if (res.noData) {
              popLastAndPushModal({
                key: SIGNUP_MODAL_KEY,
                content: (
                  <SignUpModal
                    mobile={userMobile}
                    verify={false}
                    state={state}></SignUpModal>
                ),
                showAnim: false,
              });
              return;
            } else {
              if (verify) {
                localStorage.setItem('verified', userMobile);
                popLastAndPushModal({
                  key: ENTERPIN_MODAL_KEY,
                  content: (
                    <EnterPinModal
                      mobile={userMobile}
                      state={state}></EnterPinModal>
                  ),
                  showAnim: false,
                });
              } else {
                popLastAndPushModal({
                  key: SIGNUP_MODAL_KEY,
                  content: (
                    <SignUpModal
                      mobile={userMobile}
                      verify={true}
                      fromForgotPin={true}
                      state={state}></SignUpModal>
                  ),
                  showAnim: false,
                });
              }
            }
          },
          error: () => {
            localStorage.removeItem('verified');
          },
        })
        .finally(() => {
          setDisabled(false);
        });
    }
  }

  return (
    <div className="signIn-modal-main-container">
      <img
        src={Common.modalClose}
        alt="close"
        className="signIn-modal-close-icon"
        onClick={() => {
          popModal();
        }}></img>
      <div className="signIn-modal-container">
        <CPBottomModal
          id="signIn_modal"
          icon={Common.close}
          isVisible={countryModal}
          callback={() => setCountryModal('none')}
          content={
            <div className="signIn-bottom-modal-select-container">
              <div className="signIn-bottom-modal-title">
                {trans('general.label.chooseCountry')}
              </div>
              <div className="signIn-bottom-modal-select-style">
                {mobileCode.map((item, index) => {
                  return (
                    <CountryOpt
                      key={index}
                      id={item.id}
                      title={item.title}
                      img={item.code}
                      onClick={() => {
                        setCountryCode(item.id);
                        setCountryModal('none');
                        modal.style.display = 'none';
                      }}
                    />
                  );
                })}
              </div>
            </div>
          }
        />
        <div className="signIn-modal-title-container">
          {trans('general.title.welcome2')}
        </div>

        <div className="signIn-modal-num-style">
          <div
            className="signIn-modal-dropdown-mobile"
            onClick={() => {
              setCountryModal('block');
            }}>
            {countryCode}
            <div className="signIn-modal-mobile-separator"></div>
          </div>

          <input
            className="signIn-modal-input"
            name="mobile"
            placeholder={trans('general.placeholder.mobileNumber')}
            value={mobile}
            type="tel"
            maxLength={13}
            onChange={(e) => {
              setMobile(e.target.value.trim().replace(/\D/g, ''));
            }}
          />
        </div>
        <CPButton
          btnBg={'signIn-modal-btn'}
          name="button"
          disabled={validation}
          btnStyle={`signIn-modal-btn-style ${
            validation && 'signIn-modal-disabled-btn'
          } `}
          textStyle={`signIn-modal-txt ${
            validation && 'signIn-modal-disabled-txt'
          }`}
          title={trans('general.btn.next')}
          onClick={() => mobileBtn()}
        />

        <div className="signIn-modal-or-container">
          <div className="signIn-modal-or-underline" />
          <div className="signIn-modal-or-text">{trans('signIn.or')}</div>
          <div className="signIn-modal-or-underline" />
        </div>
        <button
          className="signIn-modal-line"
          onClick={() => {
            LoginWithLine(lineClientId);
            // const url = new URL(clientInfo.lineAuthorize);
            // const state = customAlphabet(
            //   '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
            //   10,
            // )();

            // const params = {
            //   response_type: 'code',
            //   client_id: lineClientId,
            //   redirect_uri: `${window.location.origin}/linelogin`,
            //   state,
            //   scope: 'profile openid',
            // };

            // for (const [key, value] of Object.entries(params)) {
            //   url.searchParams.append(key, value);
            // }

            // localStorage.setItem('line-state', state);
            // window.open(url.toString(), '_top');
          }}
          type="button">
          <div className="signIn-modal-line-icon-container">
            <img
              src={Sign_In.signInLine}
              className="signIn-modal-login-line-icon"
              alt="signIn-modal-login-line-icon"
            />
          </div>
          <div className="signIn-modal-line-label">
            {trans('signIn.loginLine')}
          </div>
        </button>
      </div>
      <img
        src={Common.step1}
        alt="step-img"
        className="signIn-modal-step-icon"
      />
    </div>
  );
}

export default SignInModal;
