import React from 'react';
import {GetHome} from '../../../resources/image/Image';
import './NavItem.scss';

const NavItem = ({imgSrc, onClick}) => {
  return (
    <div className="nav-item-container" onClick={onClick}>
      <img className="nav-item-img" src={imgSrc} alt={imgSrc} />
    </div>
  );
};

export default NavItem;
