import React from 'react';
import './MultiTabComponent.scss';
import TabItem from './TabItem';

//example object
// const transactionTabId = 'transactionId';
// const promotionTabId = 'promotionId';
// const affiliateId = 'affiliateId';
// const gamesTabId = 'gamesId';
// const tabObjects = [
//   {displayText: trans('statement.transaction'), id: transactionTabId},
//   {displayText: trans('statement.promotion'), id: promotionTabId},
//   {displayText: trans('statement.affiliate'), id: affiliateId},
//   {displayText: trans('statement.games'), id: gamesTabId},
// ];

export default function MultiTabComponent({
  tabObjects,
  selectedTabId,
  onSelectCallBack,
  className,
  itemClassName,
  selectedItemClassName,
  itemTxtClassName,
}) {
  return (
    <div className={`multi-tab-container ${className ? className : ''}`}>
      {tabObjects.map((tab, index) => {
        return (
          <div className="multi-tab-item-container" key={index}>
            <TabItem
              className={itemClassName}
              selectedClassName={selectedItemClassName}
              txtClassName={itemTxtClassName}
              title={tab.displayText}
              isSelected={tab.id == selectedTabId}
              onClick={() => {
                onSelectCallBack(tab.id);
              }}
            />
          </div>
        );
      })}
    </div>
  );
}
