import React, {useState, useRef} from 'react';
import './ChangePassModal.scss';
import {GetCommon} from '../../../resources/image/Image';
import {useTranslation} from 'react-i18next';
import CPButton from '../../../common/components/button/CPButton';
import {useModal} from '../../context/ModalContext';
import FormComponent from '../Form/FormComponent';
import ChangePasswordTextField from '../TextField/ChangePasswordTextField';
import {useModalScrollContext} from './ContentModal';
import {useDispatch, useSelector} from 'react-redux';
import api from '../../api';
import { updateAccountPw } from '../../redux/game/appGameSlice';
import ErrMsgModal from './ErrMsgModal';
import { MsgModalObject, useMsgModal } from '../../context/MsgModalContext';
import { useNav } from '../../utils/helper';

function ChangePassModal({state}) {
  const {pushMsgModal} = useMsgModal();
  const {modalScrollRef} = useModalScrollContext();
  const {popModal} = useModal();
  const Common = GetCommon();
  const {t: trans} = useTranslation();
  const oldPwTfName = 'oldPassword';
  const passwordTfName = 'password';
  const confirmPasswordTfName = 'confirmPassword';
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [formData, setFormData] = useState('');
  const formRef = useRef(null);
  const {profile} = useSelector(({user}) => user);
  const userMobile = profile?.mobileNumber?.toString() || '';
  const {selectedAccount, accounts} = useSelector((state) => state.appGame);
  const dispatch = useDispatch();
  const handleFieldChange = (e) => {
    const {name, value} = e;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const navigate = useNav();

  const handleUpdateButtonClick = async () => {
    const isValid = await formRef.current.ValidateValue();
    if (isValid) {
      const password = formData[passwordTfName];
      api.UpdatePassword({
        showSpinner: false,
        showErrDialog: false,
        username: selectedAccount.user,
        password: password,
        userId: profile.userId,
        provider: selectedAccount.gameGroup,
        gameGroup: selectedAccount.gameGroup,

        success: (res) => {
          dispatch(updateAccountPw({selectedAccount: selectedAccount,password: password}));
          pushMsgModal(
            new MsgModalObject({
              content: (
                <ErrMsgModal
                  title={'dialog.msg.changePass'}
                  onClick={() => {
                    popModal();
                  }}
                />
              ),
            }),
          );
        },
        error: (err) => {
          const {errcode, msg} = err || {};
          if (errcode == '102') {
            const oldPwTf =
              formRef.current.getTextFieldRefByName(oldPwTfName)?.current;
            if (oldPwTf) {
              oldPwTf.setErrMsg('dialog.err.wrongPass', true);
            }
          } else {
            pushMsgModal(
              new MsgModalObject({
                content: (
                  <ErrMsgModal
                    title={errcode ? `${errcode}: ${msg}` : 'dialog.err.someErr'}
                  />
                ),
              }),
            );
          }
          // setIsInProgress(false);
        },
      });
      
      // popModal();
    }
  };

  return (
    <div className="changePass-modal-main-container">
      <img
        src={Common.modalClose}
        alt="close"
        className="changePass-modal-close-icon"
        onClick={() => {
          popModal();
        }}></img>
      <div className="changePass-modal-container">
        <div className="changePass-modal-title-container">
          {trans('general.title.changePass')}
        </div>
        <FormComponent
          className="changePass-form-container"
          ref={formRef}
          scrollRef={modalScrollRef}>
          <ChangePasswordTextField
            isFormChild={true}
            title={trans('forgotPass.label.newPass')}
            titleClassName={'changePass-modal-title'}
            name={passwordTfName}
            containerClassName={'changePass-modal-title-container'}
            placeholder={trans('general.placeholder.enterNewPw')}
            value={formData[passwordTfName] || ''}
            isRequired={true}
            onChange={(e) => {
              const confirmPass = formRef.current.getTextFieldRefByName(
                confirmPasswordTfName,
              )?.current;
              if (confirmPass && confirmPass.isRealTimeValidation) {
                const confirmPwValue = formData[confirmPasswordTfName] ?? '';
                const pwValue = e.value;
                if (confirmPwValue != '') {
                  if (confirmPwValue != pwValue) {
                    confirmPass.setErrMsg('general.errMsg.pwNotMatch');
                  } else {
                    confirmPass.setErrMsg('');
                  }
                }
              }
              handleFieldChange(e);
            }}
            error={passwordError}
          />
          <ChangePasswordTextField
            isFormChild={true}
            title={trans('forgotPass.label.confirmPass')}
            titleClassName={'changePass-modal-title'}
            name={confirmPasswordTfName}
            containerClassName={'changePass-modal-title-container'}
            placeholder={trans('general.placeholder.confirmNewPw')}
            value={formData[confirmPasswordTfName] || ''}
            isRequired={true}
            onChange={handleFieldChange}
            validation={(value) => {
              if (value != formData[passwordTfName] ?? '') {
                return 'general.errMsg.pwNotMatch';
              } else {
                return '';
              }
            }}
            error={confirmPasswordError}
          />
        </FormComponent>
        <div className="changePass-modal-btn-container">
          <CPButton
            btnBg={'changePass-modal-btn'}
            name="button"
            btnStyle={`changePass-modal-btn-style`}
            textStyle={`changePass-modal-txt`}
            title={trans('general.btn.update')}
            onClick={handleUpdateButtonClick}
          />
          <CPButton
            btnBg={'changePass-modal-cancel-btn'}
            name="button"
            btnStyle={`changePass-modal-btn-cancel-style`}
            textStyle={`changePass-modal-cancel-txt`}
            title={trans('general.btn.cancel')}
            onClick={() => popModal()}
          />
        </div>
      </div>
    </div>
  );
}

export default ChangePassModal;
