import React from 'react';
import './ErrMsgModal.scss';
import CPButton from '../button/CPButton';
import {useTranslation} from 'react-i18next';
import {useMsgModal} from '../../context/MsgModalContext';

function ErrMsgModal({title, content, onClick}) {
  const {popMsgModal} = useMsgModal();
  const {t: trans} = useTranslation();

  return (
    <div className="errMsgModal-main-container">
      <div className="errMsgModal-container">
        <div className="errMsgModal-content-container">
          {title ? (
            <div className="errMsgModal-title">{trans(title)}</div>
          ) : null}
          {content ? (
            <div className="errMsgModal-content">{trans(content)}</div>
          ) : null}
        </div>
        <div className="errMsgModal-btn-container">
          <CPButton
            btnBg={`errMsgModal-error-bg-btn`}
            btnStyle={'errMsgModal-btn'}
            textStyle={'errMsgModal-btn-txt'}
            title={trans('dialog.btn.ok')}
            onClick={() => {
              popMsgModal();
              if (onClick) {
                onClick();
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default ErrMsgModal;
