import {createSlice} from '@reduxjs/toolkit';
import {setCompany} from '../companySlice';
import {isEqual} from 'lodash';

const initialState = {
  CMCategoryGame: [
    // 'slot',
    // 'live',
    // 'sports',
    // 'fish',
    // 'ecasino',
    // 'bingo',
    // 'arcade',
  ],
  isDefault: true, //for game list to detect whether is loading
};

const categorySliceStorage = localStorage.getItem('categorySlice');
let categorySliceObj = categorySliceStorage
  ? JSON.parse(categorySliceStorage)
  : null;
const finalInitialState = categorySliceObj
  ? {...initialState, CMCategoryGame: categorySliceObj, isDefault: false}
  : initialState;

export const categorySlice = createSlice({
  name: 'category',
  initialState: finalInitialState,
  extraReducers: (builders) => {
    builders.addCase(setCompany, (state, action) => {
      let currentCategory = state.CMCategoryGame;
      let incomingCategory = action.payload.gameCategory;
      if (!isEqual(currentCategory, incomingCategory)) {
        localStorage.setItem('categorySlice', JSON.stringify(incomingCategory));
        state.CMCategoryGame = incomingCategory;
      }
      state.isDefault = false;
    });
  },
});

export default categorySlice.reducer;
