import React, {Component} from 'react';
import {GetCommonByUrl, GetInfoByUrl} from '../../../../resources/image/Image';
import CPButton from '../../button/CPButton';
import './Info.scss';
import '../../../../App.scss';
import {connect} from 'react-redux';

class Info extends Component {
  static infoInstance;
  state = {show: false, type: '', check: false};

  static show(config = {}) {
    this.infoInstance.showInfo(config);
  }

  static hide() {
    this.infoInstance.hideInfo();
  }

  showInfo(config) {
    this.setState({
      show: true,
      type: config.type,
      dontShowAgain: config.dontShowAgain,
    });
    document.getElementById('root-style').style.overflow = 'hidden';
  }

  hideInfo() {
    this.setState({show: false});
    document.getElementById('root-style').style.overflow = 'visible';
  }

  render() {
    const {show, type, dontShowAgain, check} = this.state;
    const {trans, contentUrl} = this.props;
    const Common = GetCommonByUrl(contentUrl);
    const Img = GetInfoByUrl(contentUrl);

    function Content({type}) {
      let img = Common.info,
        title = '',
        desc = null;

      switch (type) {
        case 'mission':
          img = Img.mission;
          title = trans('info.mission.title');
          desc = trans('info.mission.desc');
          break;

        case 'comm':
          img = Img.commDetails;
          title = trans('info.commDetail.title');
          desc = (
            <div className="info-desc-content comm">
              <p>{trans('info.commDetail.desc_1')}</p>
              <p>
                {trans('info.commDetail.desc_2')}
                {` `}
                <strong>{trans('info.commDetail.desc_2_amt')}</strong>
              </p>
              <p>
                {trans('info.commDetail.desc_3')}
                {` `}
                <strong>{trans('info.commDetail.desc_3_amt')}</strong>
              </p>
            </div>
          );
          break;

        case 'fund':
          img = Img.fundTransfer;
          title = trans('info.fundTransfer.title');
          desc = trans('info.fundTransfer.desc');
          break;

        case 'affiliate':
          img = Img.affiliate;
          title = trans('info.affiliate.title');
          desc = (
            <div className="info-desc-content">
              <div className="info-desc-grid">
                <div className="info-desc-step">
                  {trans('info.affiliate.step_1')}
                </div>
                <div className="info-desc-step-desc">
                  {trans('info.affiliate.step_1_desc')}
                </div>
                <div className="info-desc-step">
                  {trans('info.affiliate.step_2')}
                </div>
                <div className="info-desc-step-desc">
                  {trans('info.affiliate.step_2_desc')}
                </div>
                <div className="info-desc-step">
                  {trans('info.affiliate.step_3')}
                </div>
                <div className="info-desc-step-desc">
                  {trans('info.affiliate.step_3_desc')}
                </div>
                <div className="info-desc-step">
                  {trans('info.affiliate.step_4')}
                </div>
                <div className="info-desc-step-desc">
                  {trans('info.affiliate.step_4_desc')}
                </div>
                <div className="info-desc-step">
                  {trans('info.affiliate.step_5')}
                </div>
                <div className="info-desc-step-desc">
                  {trans('info.affiliate.step_5_desc')}
                </div>
              </div>
              <div className="info-desc-remark">
                {trans('info.affiliate.remark')}
              </div>
            </div>
          );
          break;

        case 'downline':
          img = Img.downline;
          title = trans('info.downline.title');
          desc = trans('info.downline.desc');
          break;

        case 'topup':
          img = Img.topup;
          title = trans('topUp.tips.howToDeposit');
          desc = (
            <div className="info-desc-content">
              <div className="info-desc-grid topup">
                <div className="info-desc-step">
                  {trans('topUp.label.step1')}
                </div>
                <div className="info-desc-step-desc">
                  {trans('topUp.tips.1')}
                </div>
                <div className="info-desc-step">
                  {trans('topUp.label.step2')}
                </div>
                <div className="info-desc-step-desc">
                  {trans('topUp.tips.2')}
                </div>
                <div className="info-desc-step">
                  {trans('topUp.label.step3')}
                </div>
                <div className="info-desc-step-desc">
                  {trans('topUp.tips.3')}
                </div>
              </div>
            </div>
          );
          break;

        default:
      }

      return (
        <div className="info-content-container">
          <img className={`info-img ${type}`} src={img} alt={`${type}`} />
          <div className="info-content">
            <div className="info-title-txt">{title}</div>
            <div className="info-desc">{desc}</div>
          </div>
        </div>
      );
    }

    return show ? (
      <div className="info-container">
        <div className="container info-item container-padding">
          <div className="info-close-container">
            <button className="info-close-btn" onClick={() => this.hideInfo()}>
              <img
                className="info-close-icon"
                src={Common.close2}
                alt="close"
              />
            </button>
          </div>
          <Content type={type} />
          <div className="info-cta-container">
            <CPButton
              btnBg={'info-cta-bg'}
              btnStyle={'info-cta'}
              textStyle={'info-cta-txt'}
              title={trans('general.btn.gotIt')}
              onClick={() => this.hideInfo()}
            />
            {dontShowAgain ? (
              <div
                className="info-show-container"
                onClick={() => {
                  localStorage.setItem(`info-${type}`, !this.state.check);
                }}>
                <img
                  alt="checkbox"
                  className="info-show-icon"
                  src={check ? Common.checkBox : Common.uncheckBox}
                  onClick={() => this.setState({check: !this.state.check})}
                />
                <div>{trans('info.label.hide')}</div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    ) : null;
  }
}

const mapStateToProps = (state) => ({
  contentUrl: state.company.contentUrl,
});

export default connect(mapStateToProps, null, null, {forwardRef: true})(Info);
