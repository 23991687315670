import React from 'react';
import PropTypes from 'prop-types';

import './TopNav.scss';
import {GetCommon} from '../../resources/image/Image';
import {useNav as useNavigate} from '../../common/utils/helper';

TopNav.propTypes = {
  title: PropTypes.any,
  leftBtn: PropTypes.func,
  rightBtn: PropTypes.func,
  rightIcon: PropTypes.string,
};

TopNav.defaultProps = {
  title: 'title',
  leftBtn: null,
  rightBtn: null,
};

/**
 * @param props
 * @param {string} title
 * @param {function} leftBtn
 * @param {function} rightBtn
 * @param {string} rightIcon
 **/

export default function TopNav({title, leftBtn, rightBtn, rightIcon = null}) {
  const navigate = useNavigate();
  const Common = GetCommon();

  return (
    <div className="topNav-container">
      <div
        className="topNav-left-btn"
        onClick={() => {
          document.body.style.overflow = 'scroll';
          if (leftBtn) {
            leftBtn();
            return;
          }
          navigate(-1);
        }}>
        <img alt="topNav-icon" src={Common.back} className="topNav-icon" />
      </div>
      <div className="topNav-title-container">{title}</div>
      <div
        className="topNav-right-btn"
        onClick={() => {
          if (rightBtn) {
            rightBtn();
            return;
          }
          // alert('info');
          // document.getElementsByTagName('iframe')[0].className = 'fullScreen';
        }}>
        {rightBtn ? (
          <img
            alt="topNav-icon-2"
            src={rightIcon ? rightIcon : Common.info}
            className="topNav-icon-2"
          />
        ) : (
          <div className="topNav-icon-2" />
        )}
      </div>
    </div>
  );
}
