import React from 'react';
import './GamesLogItem.scss';
import {useTranslation} from 'react-i18next';

export default function GamesLogItem({
  title,
  date,
  turnover,
  winlost,
  oddTheme,
}) {
  const {t: trans} = useTranslation();
  return (
    <div
      className={`gamesLog-item-container ${
        oddTheme
          ? 'gamesLog-item-container-odd-theme'
          : 'gamesLog-item-container-even-theme'
      }`}>
      <div className="gamesLog-item-left-container">
        <div className="gamesLog-item-title">{title}</div>
        <div className="gamesLog-item-date">{date}</div>
      </div>
      <div className="gamesLog-item-right-container">
        <div className="gamesLog-item-turnover-container">
          <div className="gamesLog-item-turnover-title">
            {trans('gamesLog.bets')}
          </div>
          <div className="gamesLog-item-turnover-value">{turnover}</div>
        </div>
        <div className="gamesLog-item-winlost-container">
          <div className="gamesLog-item-winlost-title">
            {trans('gamesLog.winlost')}
          </div>
          <div className="gamesLog-item-winlost-value">{winlost}</div>
        </div>
      </div>
    </div>
  );
}
