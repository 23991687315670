import React, {useLayoutEffect} from 'react';
import {useLocation} from 'react-router-dom';

const ScrollToTop = () => {
  const {pathname} = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    window.parent.postMessage(
      {
        type: 'routeChange',
        route: pathname,
      },
      '*',
    );
  }, [pathname]);

  return null;
};

export default ScrollToTop;
