import React, {Component} from 'react';
import api from '../../../api';
import {setAnim} from '../../../redux/optionSlice';
import {store} from '../../../redux/Store';
import {balance} from '../../../redux/userSlice';
import Loading from '../loading_spinner/Loading';
import './notif.scss';
import {DETECT_FW_PATH} from '../../../utils/helper';

class Notif extends Component {
  static notifInstance;

  state = {
    items: [],
  };

  /**
   * @param {object} config
   * @param {string} [config.msg]
   * @param {any} [config.cta.type]
   * @param {string} [config.cta.text]
   */
  static show(config) {
    this.notifInstance.showNotif(config);
  }

  static hide() {
    this.notifInstance.hideNotif();
  }

  showNotif(config) {
    const {cta, item, msg} = config;

    const repeat = this.state.items.filter(
      (item) => item.cta?.type === cta?.type,
    );

    if (!repeat.length) {
      this.setState((prevState) => ({
        items: [
          ...prevState.items,
          {
            msg: msg || 'Dialog here !',
            cta: cta || {
              type: '/',
              text: 'Dismiss',
            },
            item: item || {},
          },
        ],
      }));
    }

    if (cta?.type.toUpperCase() === 'FW') {
      let arr = [];
      const flag = JSON.parse(localStorage.getItem('FW'));
      if (flag) {
        flag.push(item);
        localStorage.setItem('FW', JSON.stringify(flag));
      } else {
        arr.push(item);
        localStorage.setItem('FW', JSON.stringify(arr));
      }
    }

    setTimeout(() => {
      this.hideNotif(0);
    }, 5000);
  }

  hideNotif(index) {
    const nItems = [...this.state.items];
    nItems.splice(index, 1);

    this.setState({
      items: nItems,
    });
  }

  goto(getData) {
    const {cta} = getData;
    switch (cta?.type.toUpperCase()) {
      case 'FW':
        store.dispatch(setAnim(true));
        this.props.navigate(DETECT_FW_PATH);
        api.GetBalance({
          success: (res) => {
            store.dispatch(balance(res.data));
          },
        });
        break;

      default:
    }
  }

  handle(index, getData) {
    return (
      <button
        className="notif-btn-container"
        onClick={() => {
          this.goto(getData ? getData : '');
          this.hideNotif(index);
          Loading.hide();
        }}>
        {getData.cta.text}
      </button>
    );
  }

  renderItem({items}) {
    return (
      <div className="notif-style">
        {items.map((item, index) => {
          const {msg, cta, item: data} = item;
          const getData = {cta: cta, data: data};

          return (
            <div className="notif-container" key={index}>
              <div className="notif-content-style notif-content-container">
                <div>{msg}</div>
              </div>
              <div className="notif-content-style">
                {this.handle(index, getData)}
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    const {trans} = this.props;
    const {items} = this.state;

    return items.length ? this.renderItem({trans, items}) : null;
  }
}

export default Notif;
