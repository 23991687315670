import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useLayoutEffect,
  lazy,
  Suspense,
} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {isIOS} from 'react-device-detect';
import {useLocation} from 'react-router-dom';

import './GameListMay23.scss';
import '../sign_in_out/SignIn.scss';
import {GetGame, GetHome} from '../../resources/image/Image';
import MarqueeComponent from './Marquee';
import api from '../../common/api';
import {setShowClub, setDummy} from '../../common/redux/optionSlice';
import {
  useNav as useNavigate,
  IsMobileDevice,
  ShowLoginDialog,
  NavigateToLogin,
} from '../../common/utils/helper';
// import Dialog from '../../common/components/root/dialog/Dialog';
// import useAutosizeTextArea from '../../common/components/autoResizeTextArea/CPTextArea';
import scssVariales from '../../resources/theme/Common.scss';
import CustomAlert from '../../common/components/root/alert/CustomAlert';
import Loading from '../../common/components/root/loading_spinner/Loading';
import i18n from '../../i18n';
import SuspenseLoading from '../../common/components/root/loading_spinner/SuspenseLoading';
import {useModal} from '../../common/context/ModalContext';
import {getProviderObject} from '../../common/utils/gameListHelper';
import {setAppGameInfo} from '../../common/redux/game/appGameSlice';
import {APPGAME_CHANGEPW_MODAL_KEY} from '../../common/utils/modalHelper';
import AppGameChangePasswordModal from '../../common/components/modal/AppGameChangePasswordModal';
import {JourneyObject} from '../../common/redux/journeySlice';
import ButtonComponent_v2 from '../../common/components/button/ButtonComponent_v2';

import {
  MsgModalObject,
  useMsgModal,
} from '../../common/context/MsgModalContext';
import ErrMsgModal from '../../common/components/modal/ErrMsgModal';
import InnerGameList from './InnerGameList';
const GameCardComponent = lazy(() =>
  import('./pageComponent/GameCardComponent'),
);
const AppGame = lazy(() => import('./AppGame'));
const AppGameInfoComponent = lazy(() =>
  import('../../common/components/TextField/AppGameInfoComponent'),
);
const ButtonComponent = lazy(() =>
  import('../../common/components/button/ButtonComponent'),
);
// const promiseAny = require('promise-any');

// GetStylePath('pages/home/GameListMay23.scss');
export default function GameList({gameListing, isLoading, isPage}) {
  const useModalContext = useModal();
  const {pushMsgModal} = useMsgModal();
  const Game = GetGame();
  const Home = GetHome();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {t: trans} = useTranslation();
  const {CMSelectedFilterGameBg} = useSelector((state) => state.styling);
  const {anim, dummy} = useSelector((state) => state.option);
  const {CMCategoryGame, isDefault} = useSelector((state) => state.category);
  const {contentUrl} = useSelector((state) => state.company);
  const {isLogin} = useSelector((state) => state.user);
  const {gameInfo} = useSelector((state) => state.journey);

  const [filterGameType, setFilterGameType] = useState(() =>
    getInitialCategoryValue(),
  );
  const [all, setAll] = useState([]);
  const [filter, setFilter] = useState(gameListing ? gameListing : []);
  const [providerSel, setProviderSel] = useState(() =>
    getInitialProviderValue(),
  );
  const categoryRefs = useRef([]);
  const [isProviderScreen, setIsProviderScreen] = useState(() =>
    getInitialIsProviderValue(),
  );
  const {CMSelectedProvider} = useSelector((state) => state.provider);
  const [providerList, setProviderList] = useState([]);
  const [filteredProviderList, setFilteredProviderList] = useState([]);
  const [searchProviderValue, setSearchProviderValue] = useState('');
  const summarizedGameTypesByProvider = useRef(null);
  const allCategory = 'all';
  const categoryScrollDivRef = useRef(null);
  const categoryWidthDivRef = useRef(null);
  const categoryContainerDivRef = useRef(null);
  const [isCatScrollable, setIsCatScrollable] = useState(false);
  const isOpenedAppRef = useRef(false);
  const isMobile = IsMobileDevice();

  //for scroll up show category
  const initInnerHeight = useRef(null);
  const [translateY, setTranslateY] = useState(0);
  const prevScrollPosition = useRef(0);
  const prevWidthRef = useRef(window.innerWidth);

  //setup category type included all
  const [newCategoryArray, setNewCategoryArray] = useState([]);

  //inner game search --start
  const [gameIsSearching, setGameIsSearching] = useState(false);
  const isSearchingRef = useRef(gameIsSearching);
  const [searchGameValue, setSearchGameValue] = useState('');
  const searchInputRef = useRef(null);
  const searchTextFieldViewRef = useRef(null);
  const soonKey = 'soon';

  const mainContainerRef = useRef(null);
  const {disallow} = useSelector((state) => state.game);

  //app game
  const {selectedAccount, iosDownloadLink, androidDownloadLink} = useSelector(
    (state) => state.appGame,
  );

  function handleTouchMove(e) {
    e.preventDefault();
  }

  useEffect(() => {
    setProviderSel(getInitialProviderValue());
    setFilterGameType(getInitialCategoryValue());
    setIsProviderScreen(getInitialIsProviderValue());
  }, [location.state]);

  useEffect(() => {
    let isAutoStarted = sessionStorage.getItem('isAutoStarted');

    if (!isAutoStarted && isLogin) {
      //trigger auto start game

      // const playGameState = location.state?.extraState?.playGameState;
      // const getAppGameState = location.state?.extraState?.getAppGameState;
      const playGameState = gameInfo?.playGameState;
      const getAppGameState = gameInfo?.getAppGameState;
      if (playGameState) {
        PlayGame(...playGameState);
        sessionStorage.setItem('isAutoStarted', true);
      }
      if (getAppGameState) {
        GetAppGameUserInfo(...getAppGameState);
        sessionStorage.setItem('isAutoStarted', true);
      }
    }
  }, [isLogin]);

  //gameIsSearching used when search provider and game
  useEffect(() => {
    if (gameIsSearching) {
      document.body.style.overflow = 'hidden';
      document.addEventListener('touchmove', handleTouchMove, {passive: false});
      if (searchInputRef.current) {
        searchInputRef.current.focus();
      }
    }
    isSearchingRef.current = gameIsSearching;

    return () => {
      document.removeEventListener('touchmove', handleTouchMove);
      document.body.style.overflow = 'scroll';
    };
  }, [gameIsSearching]);

  useEffect(() => {
    // setTranslateY(0);
    // resetScroll();
    resetTranslateYIsPageChecking();
    FilterGameList(filterGameType, providerSel);
  }, [searchGameValue]);

  //inner game search --end

  function getDashboardInfoFromSessionStorage() {
    const dashboardInfoString = sessionStorage.getItem('dashboardInfo');
    if (dashboardInfoString) {
      return JSON.parse(dashboardInfoString);
    }
    return null;
  }

  function getInitialIsProviderValue() {
    //state value priority
    const stateValue = location.state?.selectedProvider;
    if (stateValue) {
      return false;
    }

    const dashboardInfo = getDashboardInfoFromSessionStorage();
    if (dashboardInfo) {
      const isProvider = dashboardInfo.isProviderScreen;
      return isProvider;
    }
    //default value
    return true;
  }

  function getInitialProviderValue() {
    //state value priority
    const stateValue = location.state?.selectedProvider;
    if (stateValue) {
      dispatch(setAppGameInfo(stateValue));
      return stateValue;
    }

    const dashboardInfo = getDashboardInfoFromSessionStorage();
    if (dashboardInfo && dashboardInfo.selectedProvider) {
      const selectedProvider = dashboardInfo.selectedProvider;
      dispatch(setAppGameInfo(selectedProvider));
      return selectedProvider;
    }
    //default value
    return {};
  }

  function getInitialCategoryValue() {
    //state value priority
    const stateValue = location.state?.selectedCategory;
    if (stateValue) {
      return stateValue;
    }

    const dashboardInfo = getDashboardInfoFromSessionStorage();
    if (dashboardInfo && dashboardInfo.selectedCategory) {
      const selectedCategory = dashboardInfo.selectedCategory;
      return selectedCategory;
    }
    //default value
    return {};
  }

  const handleClickOutside = (event) => {
    if (
      searchTextFieldViewRef.current &&
      !searchTextFieldViewRef.current.contains(event.target) &&
      isSearchingRef.current
    ) {
      setGameIsSearching(false);
    }
  };

  //if minimize the web or swithed to other tab will triggered.
  const visibilityChangeHandler = () => {
    if (isMobile) {
      isOpenedAppRef.current = true;
    }
  };
  useLayoutEffect(() => {
    const handleResize = () => {
      const currentWidth = window.innerWidth;
      if (currentWidth !== prevWidthRef.current) {
        setTranslateY(0);

        // Update the previous width
        prevWidthRef.current = currentWidth;
      }
    };

    window.addEventListener('visibilitychange', visibilityChangeHandler);
    window.addEventListener('resize', handleResize);
    document.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('visibilitychange', visibilityChangeHandler);
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  // useLayoutEffect(() => {
  //   const observer = new ResizeObserver((entries) => {
  //     const newHeight = topSectionRef.current.getBoundingClientRect().height;
  //     const root = document.documentElement;
  //     root.style.setProperty('--top-header-height', `${newHeight}px`);
  //   });

  //   observer.observe(topSectionRef.current);

  //   return () => {
  //     observer.disconnect();
  //   };
  // }, [topSectionRef]);

  useEffect(() => {
    if (initInnerHeight.current === null) {
      initInnerHeight.current = window.innerHeight;
    }
    const handleScroll = () => {
      if (isSearchingRef.current) return;
      const currentScrollPos = document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;
      const maxScroll = scrollHeight - clientHeight;
      //when url bar hide show will affect innerHeight
      const innerHeight = window.innerHeight;
      // const isShowingUrlBar = innerHeight === clientHeight ? true : false;
      const diffInnerHeight = innerHeight - initInnerHeight.current;
      const rootStyles = getComputedStyle(document.documentElement);
      const topHeaderHeight = parseFloat(
        rootStyles.getPropertyValue('--top-header-height'),
      );
      const categoryRect =
        categoryWidthDivRef?.current?.getBoundingClientRect();

      let maxHeight = 0;
      if (categoryRect) {
        maxHeight = categoryRect.height;
      }

      if (currentScrollPos > prevScrollPosition.current) {
        //scrolling down
        const scrollGap = currentScrollPos - prevScrollPosition.current;

        if (currentScrollPos < 0 || currentScrollPos >= maxScroll) {
          prevScrollPosition.current = currentScrollPos;
          return;
        }
        setTranslateY((value) => {
          const categoryRectTopPos = categoryRect?.top;
          //the value only will set when it reach the sticky position.
          if (categoryRectTopPos > topHeaderHeight) {
            return value;
          }

          let newValue = value - scrollGap;
          if (newValue <= -maxHeight) {
            newValue = -maxHeight;
          }
          return newValue;
        });
      } else if (currentScrollPos < prevScrollPosition.current) {
        //scrolling up
        const scrollGap = prevScrollPosition.current - currentScrollPos;

        //this cater for safari, safari scroll abit different with chrome
        if (
          currentScrollPos < 0 ||
          (currentScrollPos > maxHeight &&
            currentScrollPos + diffInnerHeight >= maxScroll)
        ) {
          prevScrollPosition.current = currentScrollPos;
          return;
        }
        setTranslateY((value) => {
          let newValue = value + scrollGap;
          if (newValue >= 0) {
            newValue = 0;
          }
          return newValue;
        });
      }

      prevScrollPosition.current = currentScrollPos;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // scroll into selected category
  useEffect(() => {
    ScrollIntoSelectedCategory();

    FilterGameList(filterGameType, providerSel);
    FilterProviderList();
  }, [filterGameType, trans]);

  useEffect(() => {
    FilterProviderList();
    // setTranslateY(0);
    // resetScroll();
    resetTranslateYIsPageChecking();
  }, [searchProviderValue]);

  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const {target} = entries[0];
      setCategoryScrollable(target);
    });
    // resetScroll();

    //reset game search value
    setSearchGameValue('');

    //reset category view
    // setTranslateY(0);
    resetTranslateYIsPageChecking();

    //default category is not scrollable, so just check whether want to make it scrollable with the categoryWidthDivRef
    // setCategoryScrollable(categoryWidthDivRef.current);

    if (categoryScrollDivRef.current) {
      resizeObserver.observe(categoryScrollDivRef.current);
    }
    // if (categoryWidthDivRef.current) {
    //   resizeObserver.observe(categoryWidthDivRef.current);
    // }

    return () => {
      resizeObserver.disconnect();
    };
  }, [isProviderScreen, newCategoryArray]);

  function getSelectedCategoryIndex(arrayCategory, selectedCategory) {
    return arrayCategory.findIndex(
      (obj) => obj.categoryen === selectedCategory.categoryen,
    );
  }
  function ScrollIntoSelectedCategory(behavior = 'smooth') {
    let selectedIndex = getSelectedCategoryIndex(
      newCategoryArray,
      filterGameType,
    );
    if (categoryRefs.current[selectedIndex]) {
      const target =
        categoryRefs.current[selectedIndex].getBoundingClientRect();
      const targetParent = categoryScrollDivRef.current.getBoundingClientRect();
      const targetInParentLeftPosition = target.left - targetParent.left;
      const currentPosition = categoryScrollDivRef.current.scrollLeft;
      const finalPosition = currentPosition + targetInParentLeftPosition;
      const isVisible =
        target.left >= targetParent.left && target.right <= targetParent.right;

      if (!isVisible) {
        categoryScrollDivRef.current.scrollTo({
          behavior: 'smooth',
          left: finalPosition,
        });
      }

      // categoryRefs.current[selectedIndex].scrollIntoView({
      //   behavior: behavior,
      //   block: 'nearest',
      // });
    }
  }

  useEffect(() => {
    //after get the gamelisting, inside GrabGameList function will do setup for provider also
    const linkElements = [];

    const createLinkElement = (imgUrl) => {
      const link = document.createElement('link');
      link.rel = 'preload';
      link.as = 'image';
      link.href = imgUrl;
      document.head.appendChild(link);
      linkElements.push(link); // Add the link to the array
    };

    for (const provider of CMSelectedProvider) {
      if (provider.status == '1') {
        var imgUrl =
          contentUrl + `/provider/${provider.code.toLowerCase()}.webp`;
        createLinkElement(imgUrl);
      }
    }

    GrabGameList(gameListing);

    return () => {
      linkElements.forEach((link) => {
        document.head.removeChild(link);
      });
    };
  }, [gameListing, CMSelectedProvider, contentUrl]);

  useEffect(() => {
    SetupCategoryList();
  }, [CMCategoryGame]);
  function setCategoryScrollable(target) {
    //if categoryScrollDiv then will detect whether to show arrow.
    // if (target == categoryScrollDivRef.current) {
    //   let categoryContainerStyle = getComputedStyle(
    //     categoryContainerDivRef.current,
    //   );
    //   const widthWithoutPadding =
    //     parseFloat(categoryContainerStyle.width) -
    //     parseFloat(categoryContainerStyle.paddingLeft) -
    //     parseFloat(categoryContainerStyle.paddingRight);
    //   //if scroll content can fit into the width, make it cant scroll, related to css display: inline-block and flex
    //   if (target.scrollWidth < widthWithoutPadding) {
    //     setIsCatScrollable(false);
    //   }
    // }
    // if (target == categoryWidthDivRef.current) {
    //   //when the width div smaller than category content size, then make it scrollable, related to css display: inline-block and flex
    //   if (target.clientWidth < categoryContainerDivRef.current.clientWidth) {
    //     setIsCatScrollable(true);
    //   }
    // }

    if (target == categoryScrollDivRef.current) {
      let categoryContainerStyle = getComputedStyle(
        categoryContainerDivRef.current,
      );
      const widthWithoutPadding =
        parseFloat(categoryContainerStyle.width) -
        parseFloat(categoryContainerStyle.paddingLeft) -
        parseFloat(categoryContainerStyle.paddingRight);
      if (target.scrollWidth > target.clientWidth) {
        if (target.scrollWidth < widthWithoutPadding) {
          setIsCatScrollable(false);
        } else {
          setIsCatScrollable(true);
        }
      } else {
        setIsCatScrollable(false);
      }
    }
    ScrollIntoSelectedCategory();
  }

  function SetupCategoryList() {
    //setup category list --start
    let categoryListApi = [...CMCategoryGame].sort((a, b) => {
      let value = a.displayOrder - b.displayOrder;
      if (value === 0) {
        value = a.categoryen.localeCompare(b.categoryen);
      }
      return value;
    });

    categoryListApi = categoryListApi.filter((value) => value.status == '1');
    setFilterGameType((value) => {
      let isEmpty = Object.keys(value).length === 0;
      if (isEmpty) {
        return categoryListApi[0] === undefined ? {} : categoryListApi[0];
      }
      return value;
    });
    setNewCategoryArray(categoryListApi);
    //setup category list --end
  }

  function SetupProviderList(game) {
    const excluded = game.filter(
      (item) =>
        !(
          item.provider.includes('glive') || item.provider.includes('taokaebet')
        ),
    );

    var providerList = Object.keys(excluded).length
      ? excluded.reduce(function (a, b) {
          if (!a.some((provider) => provider.name === b.provider))
            a.push(getProviderObject(b.provider, b));
          return a;
        }, [])
      : [];
    var platformList = Object.keys(excluded).length
      ? excluded.reduce(function (a, b) {
          if (!a.some((provider) => provider.name === b.platform)) {
            if (b.platform) {
              a.push(getProviderObject(b.platform, b));
            }
          }
          return a;
        }, [])
      : [];

    var optionList = Object.keys(excluded).length
      ? excluded.reduce(function (a, b) {
          let options = b.option;
          for (const optionType of options) {
            if (
              optionType.trim() != '' &&
              !a.some((provider) => provider.name === optionType)
            ) {
              //option can have many game type so no nid pass in object to get the gameType
              a.push(getProviderObject(optionType));
            }
          }
          return a;
        }, [])
      : [];

    const providerArr = platformList.concat(providerList).concat(optionList);
    const providerArrs = providerArr.filter((item) => !(item.name === 'awc'));
    let finalProviderList = CMSelectedProvider.map((item) => {
      let isExist = providerArrs.find((obj) => obj.name === item.code);
      if (isExist && item.status == '1') {
        return {...isExist, config: {...item}};
      }
      return undefined;
    }).filter(Boolean);
    finalProviderList = finalProviderList.sort((a, b) => {
      let value = a.config.displayOrder - b.config.displayOrder;
      if (value === 0) {
        value = a.name.localeCompare(b.name);
      }
      return value;
    });
    setProviderList(finalProviderList);
    setFilteredProviderList(finalProviderList);

    //filter game list every time gameListing changed, if user refresh at innergame page it will be use
    FilterProviderList(finalProviderList, game);
  }

  function PreviousCategoryClicked() {
    let dashboardInfo = {
      isProviderScreen: true,
    };
    resetScroll();
    switchToGameScreen(dashboardInfo);
    setFilterGameType((currentValue) => {
      let currentIndex = getSelectedCategoryIndex(
        newCategoryArray,
        currentValue,
      );
      if (currentIndex === 0) return currentValue;
      let newIndex = currentIndex - 1;
      return {...newCategoryArray[newIndex]};
    });
  }

  function resetScroll() {
    const currentScrollPos = document.documentElement.scrollTop;
    const mainContainerRect =
      mainContainerRef?.current?.getBoundingClientRect();
    const rootStyles = getComputedStyle(document.documentElement);
    const topHeaderHeight = parseFloat(
      rootStyles.getPropertyValue('--top-header-height'),
    );
    const distance = (mainContainerRect?.top ?? 0) - topHeaderHeight;
    const targetPosition = currentScrollPos + distance;
    if (isPage) {
      window.scrollTo(0, targetPosition);
    }
  }

  function resetTranslateYIsPageChecking() {
    if (isPage) {
      setTranslateY(0);
    }
  }

  function NextCategoryClicked() {
    let dashboardInfo = {
      isProviderScreen: true,
    };
    resetScroll();
    switchToGameScreen(dashboardInfo);

    setFilterGameType((currentValue) => {
      let currentIndex = getSelectedCategoryIndex(
        newCategoryArray,
        currentValue,
      );
      if (currentIndex === newCategoryArray.length - 1) return currentValue;
      let newIndex = currentIndex + 1;
      return {...newCategoryArray[newIndex]};
    });
  }

  async function ConfigureGameList(game) {
    const newGameList = [...game];

    let fil = [...newGameList];

    //sort by game name
    fil = [...fil].sort((x, y) =>
      x.gamename.toLowerCase() < y.gamename.toLowerCase()
        ? -1
        : x.gamename.toLowerCase() > y.gamename.toLowerCase()
        ? 1
        : 0,
    );

    setAll(newGameList);
    setFilter(fil);

    //get a summarized object of all provider having what game type. when change category no nid loop again all game list.
    let tmpGameTypesByProvider = {};
    for (const {provider, gametype, platform, option} of newGameList) {
      if (provider) {
        if (tmpGameTypesByProvider[provider]) {
          if (!tmpGameTypesByProvider[provider].includes(gametype)) {
            tmpGameTypesByProvider[provider].push(gametype);
          }
        } else {
          tmpGameTypesByProvider[provider] = [gametype];
        }
      }

      if (platform) {
        if (tmpGameTypesByProvider[platform]) {
          if (!tmpGameTypesByProvider[platform].includes(gametype)) {
            tmpGameTypesByProvider[platform].push(gametype);
          }
        } else {
          tmpGameTypesByProvider[platform] = [gametype];
        }
      }

      for (const optionType of option) {
        if (tmpGameTypesByProvider[optionType]) {
          if (!tmpGameTypesByProvider[optionType].includes(gametype)) {
            tmpGameTypesByProvider[optionType].push(gametype);
          }
        } else {
          tmpGameTypesByProvider[optionType] = [gametype];
        }
      }
    }
    summarizedGameTypesByProvider.current = tmpGameTypesByProvider;

    //setup provider after getting all game list
    SetupProviderList(newGameList);

    //filter game list every time gameListing changed, if user refresh at innergame page it will be use
    FilterGameList(filterGameType, providerSel, newGameList);
  }

  async function GrabGameList(gameListing) {
    function games(data) {
      dispatch(setShowClub(data));
      ConfigureGameList(data);
    }

    games(gameListing);
  }

  function FilterGameList(type, providerObj, gameListing = null) {
    if (Object.keys(type).length === 0) return;
    let ori = gameListing ? gameListing : all;
    let provider = providerObj.name;
    ori = ori.filter(
      (item) =>
        !(
          item.provider.includes('glive') || item.provider.includes('taokaebet')
        ),
    );

    let fil = ori;

    //filter provider first, can get all the game under a provider.
    if (provider) {
      fil = fil.filter((item) => {
        if (
          item.platform === provider ||
          item.provider === provider ||
          item.option.includes(provider)
        )
          return true;
        return false;
      });
    }

    if (type.categoryen.toLowerCase() !== allCategory) {
      fil = fil.filter(
        (item) => item.gametype === type.categoryen.toLowerCase(),
      );
    }

    //filter by search textfield
    let trimmedSearchValue = searchGameValue.trim().toLowerCase();
    if (trimmedSearchValue !== '') {
      fil = fil.filter((game) => {
        return game.gamename.toLowerCase().includes(trimmedSearchValue);
      });
    }

    //sort the game by game name
    // fil = [...fil].sort((x, y) =>
    //   x.gamename.toLowerCase() < y.gamename.toLowerCase()
    //     ? -1
    //     : x.gamename.toLowerCase() > y.gamename.toLowerCase()
    //     ? 1
    //     : 0,
    // );
    setFilter(fil);
    return fil;
  }

  function FilterProviderList(providerListing = null, games = null) {
    if (Object.keys(filterGameType).length === 0) return;
    let filterType = filterGameType.categoryen.toLowerCase(); //filterGameType === 'ecasino' ? 'e-casino' : filterGameType;
    let filteredProvider = providerListing
      ? [...providerListing]
      : [...providerList];

    //filter by search textfield
    let trimmedSearchValue = searchProviderValue.trim().toLowerCase();
    if (trimmedSearchValue !== '') {
      filteredProvider = filteredProvider.filter((provider) => {
        return provider.name.toLowerCase().includes(trimmedSearchValue);
      });
    }
    if (allCategory !== filterType) {
      //filter by category
      filteredProvider = filteredProvider.filter((provider) => {
        if (
          summarizedGameTypesByProvider.current[provider.name] &&
          summarizedGameTypesByProvider.current[provider.name].includes(
            filterType,
          )
        ) {
          return true;
        }
        return false;
      });
    }

    //search for game
    if (trimmedSearchValue !== '' || filterGameType.skipProvider == '1') {
      let fil = all.length == 0 && games != null ? games : all;

      if (filterType !== allCategory) {
        fil = fil.filter((item) => item.gametype === filterType);
      }

      //sort the game by game name
      // fil = [...fil].sort((x, y) =>
      //   x.gamename.toLowerCase() < y.gamename.toLowerCase()
      //     ? -1
      //     : x.gamename.toLowerCase() > y.gamename.toLowerCase()
      //     ? 1
      //     : 0,
      // );

      //filter by search textfield
      if (trimmedSearchValue !== '') {
        fil = fil.filter((game) => {
          return game.gamename.toLowerCase().includes(trimmedSearchValue);
        });
      }
      filteredProvider =
        filterGameType.skipProvider == '1'
          ? fil
          : [...filteredProvider, ...fil];
    }
    setFilteredProviderList(filteredProvider);
  }

  // eg: dashboardInfo = {
  //   isProviderScreen: false,
  //   selectedProvider: refer to getProviderObject,
  //   selectedCategory: "category",
  // }
  function switchToGameScreen(dashboardInfo) {
    sessionStorage.setItem('dashboardInfo', JSON.stringify(dashboardInfo));
    resetScroll();
    setIsProviderScreen(dashboardInfo.isProviderScreen);
  }

  const FilterList = useMemo(() => {
    return newCategoryArray.map((objItem, index) => {
      let item = objItem.categoryen;
      const isSelected = filterGameType.categoryen === item;
      const club = item === 'club';

      var img =
        contentUrl +
        `/categories/${item.toLowerCase()}${isSelected ? 'Selected' : ''}.webp`;
      var name = objItem[`category${i18n.language}`];
      return (
        <div
          key={index}
          ref={(el) => {
            categoryRefs.current[index] = el;
          }}
          onClick={() => {
            let dashboardInfo = {
              isProviderScreen: true,
            };
            switchToGameScreen(dashboardInfo);
            resetScroll();
            setFilterGameType(objItem);
          }}
          className={`game-filter-btn-container ${
            isSelected && 'game-filter-btn-container-selected'
          }`}>
          <img alt="game-filter-icon" src={img} className="game-filter-icon" />
          <div
            className={`game-filter-txt ${
              isSelected ? 'game-filter-txt' : 'game-filter-unselect-txt'
            }`}>
            {name}
          </div>
        </div>
      );
    });
  }, [filterGameType, i18n.language, newCategoryArray, contentUrl]);

  const ProviderView = useMemo(() => {
    let firstGame = true;
    return filteredProviderList.map((item, index) => {
      if (item.isProvider === true) {
        const providerName = item.name;
        var img = contentUrl + `/provider/${providerName.toLowerCase()}.webp`;

        return (
          <div
            className="game-provider-item-container"
            key={index}
            onClick={() => {
              if (disallow.includes(providerName)) {
                pushMsgModal(
                  new MsgModalObject({
                    content: (
                      <ErrMsgModal title={'dialog.err.gameNotAvailable'} />
                    ),
                  }),
                );
                return;
              }
              if (filterGameType.categoryen.toLowerCase() === 'club') return;
              let gameInProvider = FilterGameList(filterGameType, item);
              if (
                gameInProvider.length === 1 &&
                providerName !== 'hot' &&
                providerName !== 'new' &&
                item.type !== 'app'
              ) {
                PlayGame(gameInProvider[0], dummy);
              } else if (item.type === 'app') {
                GetAppGameUserInfo(item, gameInProvider[0]);
              } else {
                let dashboardInfo = {
                  isProviderScreen: false,
                  selectedProvider: item,
                  selectedCategory: filterGameType,
                };

                resetScroll();
                setTimeout(() => {
                  switchToGameScreen(dashboardInfo);
                  setProviderSel(item);
                }, 10);
              }
            }}>
            <img
              src={img}
              alt={`${providerName}-game-provider-icon`}
              className="game-provider-item-image"></img>
          </div>
        );
      } else {
        //if object then is game
        const GameComponent = (
          <GameCardComponent
            key={index}
            item={item}
            updateHeightListener={firstGame}
            onClick={() => {
              PlayGame(item, dummy);
            }}
          />
        );
        firstGame = false;
        return GameComponent;
      }
    });
  }, [filteredProviderList, searchGameValue, dummy, contentUrl, disallow]);

  function ShowPlayGameAlert(providerObj, deepLink, game) {
    const alertView = (
      <div className="app-game-in-progress-alert-main-container">
        <div className="app-game-in-progress-top-view">
          <img
            alt={`${providerObj.name}`}
            className="app-game-in-progress-img"
            src={Game.providerBanner[providerObj.name]}></img>
          <div className="app-game-in-progress-title">
            {trans('dialog.msg.gameInProgress')}
          </div>
        </div>
        <div className="app-game-in-progress-middle-view">
          <AppGameInfoComponent
            infoText={selectedAccount?.user}
            leftImgSrc={Home.appGame.userBlack}
            rightImgSrc={Home.appGame.copyBlack}
            textColor={scssVariales.AppGameUserInfoTextColor}
            backgroundColor={scssVariales.AppGameUserInfoBackground}
          />
          <AppGameInfoComponent
            infoText={selectedAccount?.password}
            leftImgSrc={Home.appGame.lockWhite}
            rightImgSrc={Home.appGame.copyWhite}
            textColor={scssVariales.AppGamePwInfoTextColor}
            backgroundColor={scssVariales.AppGamePwInfoBackground}
          />
        </div>
        <div className="app-game-in-progress-bottom-view">
          <ButtonComponent
            className="app-game-alert-play-button"
            text={trans('dialog.btn.play')}
            fontSize={scssVariales.FontSize20}
            border={scssVariales.AppGamePlayGameBtnBorder}
            fontColor={scssVariales.AppGamePlayGameText}
            backgroundColor={scssVariales.AppGameUserInfoBackground}
            padding="13px 33px"
            onClick={() => {
              window.location.href = deepLink;
            }}
          />
          <ButtonComponent
            className="app-game-alert-exit-button"
            text={trans('dialog.btn.exit')}
            fontSize={scssVariales.FontSize20}
            border={'none'}
            backgroundColor={scssVariales.AppGameWarningColor}
            padding="13px 33px"
            onClick={() => {
              ExitGame(game, () => {
                CustomAlert.hide();
              });
            }}
          />
        </div>
      </div>
    );

    CustomAlert.show({
      backgroundColor: scssVariales.AppGameAlertBackground,
      border: 'none',
      show: true,
      view: alertView,
    });
  }

  function ShowGameNotFoundAlert(providerObj, deepLink, game) {
    const alertView = (
      <div className="app-game-not-found-alert-main-container">
        <div className="app-game-not-found-top-view">
          <img
            alt={`${providerObj.name}`}
            className="app-game-not-found-img"
            src={Game.providerBanner[providerObj.name]}></img>
          <div className="app-game-not-found-top-sub-view">
            <div className="app-game-not-found-title">
              {trans('dialog.err.gameNotFound')}
            </div>
            <div className="app-game-not-found-desc">
              {trans('dialog.err.gameNotFoundDesc', {
                appGame: providerObj.game.gamename,
              })}
            </div>
          </div>
        </div>
        <div className="app-game-not-found-middle-view">
          <ButtonComponent
            className="app-game-alert-download-button"
            text={trans('general.btn.downloadApp')}
            padding="13px 33px"
            fontSize={scssVariales.FontSize20}
            onClick={() => {
              OpenDownloadLink();
            }}
          />
        </div>
        <div className="app-game-not-found-bottom-view">
          <ButtonComponent
            className="app-game-alert-play-button"
            text={trans('general.btn.play')}
            fontSize={scssVariales.FontSize20}
            border={scssVariales.AppGamePlayGameBtnBorder}
            fontColor={scssVariales.AppGamePlayGameText}
            backgroundColor={scssVariales.AppGameUserInfoBackground}
            padding="13px 33px"
            onClick={() => {
              CustomAlert.hide();
              PlayGame(providerObj, dummy, true);
            }}
          />
          <ButtonComponent
            className="app-game-alert-exit-button"
            text={trans('dialog.btn.exit')}
            fontSize={scssVariales.FontSize20}
            border={'none'}
            backgroundColor={scssVariales.AppGameWarningColor}
            padding="13px 33px"
            onClick={() => {
              ExitGame(game, () => {
                CustomAlert.hide();
              });
            }}
          />
        </div>
      </div>
    );

    CustomAlert.show({
      backgroundColor: scssVariales.AppGameAlertBackground,
      border: 'none',
      show: true,
      view: alertView,
    });
  }
  function authorized(extraState) {
    if (!isLogin) {
      console.log(`im here wor`);
      const journeyObject = new JourneyObject(location.pathname, extraState);
      NavigateToLogin(navigate, dispatch, journeyObject.toPlainObject());

      // NavigateToLogin(navigate, {
      //   from: '/game',
      //   extraState: {...extraState},
      // });
      // ShowLoginDialog(useModalContext, {
      //   from: '/game',
      //   extraState: {...extraState},
      // });
      // navigate('/signIn', {
      //   state: {from: location, extraState: {...extraState}},
      // });
      return false;
    }
    return true;
  }
  function PlayGame(object, dummyParams, isAppGame = false) {
    let playGameState = {playGameState: [object, dummyParams, isAppGame]};
    if (!authorized(playGameState)) {
      return;
    }
    if (dummyParams) {
      window.open(dummyParams.toString(), '_blank');
      dispatch(setDummy(''));
      return;
    }
    if (anim) return;
    let item = isAppGame ? object.game : object;
    if (item.gametype === 'club') return;

    // if empty password force user to update pw first
    // if (isAppGame) {
    //   if (!selectedAccount || selectedAccount?.password.trim() == '') {
    //     useModalContext.pushModal({
    //       key: APPGAME_CHANGEPW_MODAL_KEY,
    //       content: (
    //         <AppGameChangePasswordModal
    //           changedCB={() => {
    //             console.log(
    //               `here changed password ${selectedAccount.password}`,
    //             );
    //           }}
    //         />
    //       ),
    //       showAnim: true,
    //     });
    //     return;
    //   }
    // }

    if (item.option && item.option.length > 0) {
      const isSoon = item.option.find((element) => {
        return element == soonKey;
      });

      if (isSoon) {
        return;
      }
    }
    let gameGroup = {};
    if (isAppGame) {
      gameGroup = {gameGroup: selectedAccount.gameGroup};
    }
    api.PlayGame({
      provider: item.provider,
      gameid: item.gameid,
      gametype: item.gametype,
      platform: item.platform,
      gameData: item.gameData,
      ...gameGroup,
      success: (res) => {
        const {url, game} = res.data;
        if (!isAppGame) {
          navigate('/playgame', {
            // replace: true,
            state: {
              gamename: item.gamename,
              url: url,
              game: game,
              from: location.pathname,
            },
          });
        } else {
          isOpenedAppRef.current = false;
          if (isMobile) {
            if (isIOS) {
              window.location.href = url.ios;
            } else {
              window.location.href = url.android;
            }
          }

          Loading.show();
          setTimeout(
            () => {
              Loading.hide();
              if (isOpenedAppRef.current) {
                ShowPlayGameAlert(object, url, game);
              } else {
                ShowGameNotFoundAlert(object, url, game);
              }
            },
            isMobile ? 5000 : 0,
          );
        }
      },
      error: (res) => {
        return;
      },
    });
  }

  function ExitGame(game, success) {
    api.ExitGame({
      game: game,
      success: () => {
        success();
      },
    });
  }

  async function GetAppGameUserInfo(item, game) {
    let getAppGameState = {getAppGameState: [item, game]};
    if (!authorized(getAppGameState)) {
      return;
    }
    api.GetAppGameUserInfo({
      provider: item.name,
      success: (res) => {
        const resData = res.data;
        const newItem = {...item, ...resData, game: game};
        //set into redux, so we can modify easily.
        dispatch(setAppGameInfo(resData));
        let dashboardInfo = {
          isProviderScreen: false,
          selectedProvider: newItem,
          selectedCategory: filterGameType,
        };
        //let window scroll to top trigger the url bar show up first. currently donno why got issue so use timeout first.(happened on iphone safari only)
        resetScroll();
        setTimeout(() => {
          switchToGameScreen(dashboardInfo);
          setProviderSel(newItem);
        }, 10);
      },
      error: (res) => {
        return;
      },
    });
  }

  async function OpenDownloadLink() {
    let url = androidDownloadLink;
    if (isMobile) {
      if (isIOS) {
        url = iosDownloadLink;
      }
    }

    // Create a temporary anchor element
    const anchor = document.createElement('a');
    anchor.href = url;

    // Programmatically click on the anchor to trigger the download
    anchor.click();

    // Loading.show();
    // let windowVariable;
    // if (isSafari) {
    //   windowVariable = window.open();
    // }

    // const fetchPromises = urls.map(
    //   (url) =>
    //     new Promise((resolve, reject) => {
    //       fetch(url)
    //         .then((res) => {
    //           if (res.ok) {
    //             resolve(url);
    //           } else {
    //             reject(`invalid url`);
    //           }
    //         })
    //         .catch((err) => {
    //           reject(err);
    //         });
    //     }),
    // );

    // const timeoutPromise = new Promise((resolve, reject) => {
    //   setTimeout(() => {
    //     resolve(false);
    //   }, 10000);
    // });

    // promiseAny([...fetchPromises, timeoutPromise])
    //   .then((res) => {
    //     Loading.hide();
    //     if (res !== false) {
    //       if (isSafari) {
    //         windowVariable.location = res.toString();
    //       } else {
    //         window.open(res.toString(), '_blank');
    //       }
    //     } else {
    //       Dialog.show({
    //         show: true,
    //         msg: trans('dialog.err.downloadLinkErrDesc'),
    //         type: 'error',
    //       });
    //     }
    //     return res;
    //   })
    //   .catch((err) => {
    //     Loading.hide();
    //     return false;
    //   });
  }

  const SearchTextField = useMemo(() => {
    return (
      <div className="game-search-textfield">
        <img
          alt="search-icon"
          src={Home.footer.searchIcon}
          className="game-search-icon"
        />
        <input
          disabled={isLoading || isDefault == true ? true : false}
          className="search-input"
          placeholder={trans('game.search')}
          value={searchProviderValue}
          onFocus={() => setGameIsSearching(true)}
          onBlur={() => setGameIsSearching(false)}
          onChange={(e) => {
            resetScroll();
            setSearchProviderValue(e.target.value);
          }}
        />
      </div>
    );
  }, [searchProviderValue, trans, isLoading, isDefault]);

  //inner game search --start

  const SearchIconView = useMemo(() => {
    let imgSrc = Home.footer.searchIcon;
    return (
      <div
        className={`${gameIsSearching ? 'inner-game-hide-css' : null}`}
        style={{
          background: scssVariales.GameSearchIconBg,
          width: '46px',
          height: '46px',
          minWidth: '46px',
          minHeight: '46px',
          padding: '11px',
          borderRadius: '8px',
          border: scssVariales.GameSearchIconBorder,
          boxShadow: scssVariales.GameSearchIconShadow,
        }}
        onClick={() => {
          setGameIsSearching(true);
        }}>
        <img
          src={imgSrc}
          alt={imgSrc}
          height="100%"
          width="100%"
          style={{objectFit: 'fill'}}
        />
      </div>
    );
  }, [gameIsSearching]);

  const BackBtnView = useMemo(() => {
    let imgSrc = Home.footer.backArrow;
    return (
      <div
        className="inner-game-back-container"
        onClick={() => {
          resetScroll();
          let dashboardInfo = {
            isProviderScreen: true,
          };
          setTimeout(() => {
            switchToGameScreen(dashboardInfo);
          }, 10);
        }}>
        <img
          src={imgSrc}
          alt={imgSrc}
          style={{width: '12px', height: '20px', objectFit: 'fill'}}
        />
        <div className="inner-game-back-text">{trans('general.btn.back')}</div>
      </div>
    );
  }, [trans]);

  const ProviderBannerView = useMemo(() => {
    return ({className}) => {
      let imgSrc = Game.providerBanner[providerSel.config?.code];
      if (
        Object.keys(providerSel).length !== 0 &&
        providerSel.name !== 'hot' &&
        providerSel.name !== 'new' &&
        providerSel.config
      ) {
        return (
          <div className={className}>
            <img
              src={imgSrc}
              alt={imgSrc}
              style={{
                maxWidth: '108px',
                minWidth: '108px',
                minHeight: '34px',
                maxHeight: '34px',
                objectFit: 'fill',
              }}
            />
            <div className="provider-banner-text">
              {providerSel.config[`description${i18n.language}`]}
            </div>
          </div>
        );
      } else {
        return null;
      }
    };
  }, [providerSel, i18n.language]);

  const GameSearchTextFieldView = useMemo(() => {
    return (
      <div
        ref={searchTextFieldViewRef}
        className={`inner-game-search-textfield ${
          gameIsSearching ? null : 'inner-game-hide-css'
        } `}>
        <img
          alt="search-icon"
          src={Home.footer.searchIcon}
          className="inner-game-search-icon"
        />
        <input
          ref={searchInputRef}
          className="inner-search-input"
          placeholder={trans('game.search')}
          value={searchGameValue}
          onBlur={() => {
            setGameIsSearching(false);
          }}
          onChange={(e) => {
            resetScroll();
            setSearchGameValue(e.target.value);
          }}
        />
      </div>
    );
  }, [gameIsSearching, searchGameValue]);
  //inner game search --end

  return (
    <div
      ref={mainContainerRef}
      className={`game-container ${isPage ? 'container-padding' : ''}`}>
      <div className="game-style" style={isPage ? {} : {padding: '0px'}}>
        <div>
          <div
            className="game-search-background-container"
            style={{
              transform: `translateX(-10px) translateY(${translateY}px)`,
            }}>
            <div
              ref={categoryWidthDivRef}
              className="game-category-width-container">
              <div
                ref={categoryContainerDivRef}
                className={`game-category-container ${
                  isCatScrollable ? 'game-category-container-flex' : ''
                }`}>
                {isCatScrollable ? (
                  <img
                    alt="game-previous-icon"
                    src={Home.footer.previousBtn}
                    className="game-arrow-icon"
                    onClick={PreviousCategoryClicked}
                  />
                ) : null}
                {isDefault ? (
                  <div className="category-loading">
                    {trans('game.loading')}
                  </div>
                ) : newCategoryArray.length === 0 ? null : (
                  <div
                    ref={categoryScrollDivRef}
                    className="game-category-scroll-container">
                    {FilterList}
                  </div>
                )}

                {isCatScrollable ? (
                  <img
                    alt="game-next-icon"
                    src={Home.footer.nextBtn}
                    className="game-arrow-icon"
                    onClick={NextCategoryClicked}
                  />
                ) : null}
              </div>
            </div>
            {isProviderScreen ? (
              <div className="game-search-filter-container">
                {SearchTextField}
              </div>
            ) : providerSel.type === 'app' ? (
              <div>
                <div className="inner-game-search-background-container">
                  <div className="inner-game-search-filter-container">
                    {BackBtnView}
                    <ProviderBannerView
                      className={`provider-banner-container ${
                        gameIsSearching ? 'inner-game-hide-css' : null
                      }`}
                    />
                    <ButtonComponent_v2
                      text={trans('general.btn.play')}
                      btnClassName={'btnV2-container-row-center-p17-r10-mainshadow'}
                      padding="15px 33px"
                      btnTextClassName={'btnV2-btn-txt-s20-w700-cMain'}
                      disableBtnClassName={'btnV2-container-row-center-p17-r10-mainshadow-disabled'}
                      disableBtnTextClassName={'btnV2-btn-txt-s20-w700-cMain-disabled'}
                      onClick={() => {
                        PlayGame(providerSel, dummy, true);
                      }}
                    />
                    {/* <ButtonComponent
                      className="app-game-play-game-button"
                      text={trans('general.btn.play')}
                      fontSize={scssVariales.FontSize16}
                      backgroundColor={scssVariales.AppGamePlayGameBackground}
                      fontColor={scssVariales.AppGamePlayGameText}
                      border={'none'}
                      padding="15px 33px"
                      onClick={() => {
                        PlayGame(providerSel, dummy, true);
                      }}
                    /> */}
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className="inner-game-search-background-container">
                  <div className="inner-game-search-filter-container">
                    {BackBtnView}
                    <ProviderBannerView
                      className={`provider-banner-container ${
                        gameIsSearching ? 'inner-game-hide-css' : null
                      }`}
                    />
                    {SearchIconView}
                    {GameSearchTextFieldView}
                  </div>
                </div>
              </div>
            )}
          </div>
          <Suspense fallback={<SuspenseLoading show={true} />}>
            {isProviderScreen ? (
              isLoading || isDefault == true ? (
                <div className="game-nodata">{trans('game.loading')}</div>
              ) : filteredProviderList.length == 0 ? (
                <div className="game-nodata">
                  {trans('general.list.noRecord')}
                </div>
              ) : (
                <div className="game-provider-list-container">
                  {ProviderView}
                </div>
              )
            ) : providerSel.type === 'app' ? (
              <div>
                <ProviderBannerView
                  className={`provider-banner-container-below`}
                />

                <AppGame
                  playOnClick={() => {
                    PlayGame(providerSel, dummy, true);
                  }}
                  downloadOnClick={() => {
                    OpenDownloadLink();
                  }}
                />
              </div>
            ) : (
              <div>
                <ProviderBannerView
                  className={`provider-banner-container-below`}
                />
                {isLoading || isDefault == true ? (
                  <div className="game-nodata">{trans('game.loading')}</div>
                ) : (
                  <InnerGameList
                    filteredGameList={filter}
                    dummyParams={dummy}
                    gameOnClick={(game, dummyParams) => {
                      PlayGame(game, dummyParams);
                    }}
                  />
                )}
              </div>
            )}
          </Suspense>
        </div>
      </div>
    </div>
  );
}
