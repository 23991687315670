import React from 'react';
import './RememberComponent.scss';
import {GetLogin} from '../../../resources/image/Image';
import {useTranslation} from 'react-i18next';

export default function RememberComponent({isTick, onChange}) {
  const {t: trans} = useTranslation();
  const loginImg = GetLogin();
  return (
    <div className="remember-container">
      <img
        className="remember-tick-img"
        alt={isTick ? 'ticked' : 'untick'}
        src={isTick ? loginImg.rmbTick : loginImg.rmbUntick}
        onClick={() => {
          onChange(!isTick);
        }}
      />
      <div className="remember-txt">{trans('login.rmbPw')}</div>
    </div>
  );
}
