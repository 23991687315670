import {createSlice} from '@reduxjs/toolkit';
import {finalInitialState as CompanyInitialState} from './companySlice';

const initialState = {
  avatar: `${CompanyInitialState.contentUrl}/avatar/female_1.webp`,
  showClub: false,
  anim: false,
  dummy: '', //this for marketing to open some predefined url, when home page click on the brand logo will show dialog to enter url.
};

export const optionSlice = createSlice({
  name: 'option',
  initialState,
  reducers: {
    setAvatar: (state, action) => {
      state.avatar = action.payload;
    },
    setShowClub: (state, action) => {
      state.showClub =
        action.payload.filter((item) => item.gametype === 'club').length === 0;
    },
    setAnim: (state, action) => {
      state.anim = action.payload;
    },
    setDummy: (state, action) => {
      state.dummy = action.payload;
    },
  },
});

export const {setAvatar, setShowClub, setAnim, setDummy} = optionSlice.actions;
export default optionSlice.reducer;
