import React, {useEffect, useState} from 'react';
import {GetSideMenu} from '../../../resources/image/Image';
import './SideMenuItem.scss';

const SideMenuItem = ({imgSrc, title, isVerified = false, onClick}) => {
  const SideMenu = GetSideMenu();
  return (
    <div className="side-menu-item-container" onClick={onClick}>
      <div className="side-menu-item-horizontal-container">
        <div className="side-menu-item-left-section">
          <img src={imgSrc} alt={title} className="side-menu-item-icon"></img>

          <div className="side-menu-item-title">{title}</div>
        </div>

        {isVerified ? (
          <img
            src={SideMenu.verified}
            alt="verified"
            className="side-menu-verified-icon"
          />
        ) : null}
      </div>
    </div>
  );
};

export default SideMenuItem;
