import React from 'react';
import './CPProgressBar.scss';
import '../../../App.js';

export default function CPProgressBar({container, color, width}) {
  return (
    <div className={`CPProgressbar-container ${container}`}>
      <div className={`CPProgressbar-style ${color}`} style={{width: width}} />
    </div>
  );
}
