import React, {createContext, useContext, useEffect, useRef} from 'react';
import './ContentModal.scss';

const ModalScrollContext = createContext();

export function useModalScrollContext() {
  return useContext(ModalScrollContext);
}

function ContentModal({children, isOpen, handleClose, showAnim = true}) {
  const modalScrollRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'scroll';
    }

    //currently dont support to close modal by using escape key, it will affect other modal.
    // const closeOnEscapeKey = (e) => {
    //   return e.key === 'Escape' && isOpen ? handleClose() : null;
    // };
    // document.body.addEventListener('keydown', closeOnEscapeKey);
    // return () => {
    //   document.body.removeEventListener('keydown', closeOnEscapeKey);
    // };
  }, [handleClose, isOpen]);

  if (!isOpen) return null;

  return (
    <ModalScrollContext.Provider value={{modalScrollRef}}>
      <div
        className="content-modal"
        style={{
          animation: showAnim ? 'contentModalAnim 0.5s ease-out' : 'none',
        }}>
        <div ref={modalScrollRef} className="content-modal-content">
          {children}
        </div>
      </div>
    </ModalScrollContext.Provider>
  );
}

export default ContentModal;
