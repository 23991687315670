import React, {useEffect, useState} from 'react';
import {GetSign_In} from '../../../resources/image/Image';
import './LanguageItem.scss';

const LanguageItem = ({imgSrc, languageTxt, isSelected, onClick}) => {
  return (
    <div className="language-item-container">
      <div className="language-item-icon-container">
        <img
          src={imgSrc}
          alt={languageTxt}
          className="language-item-icon"></img>
      </div>

      <div
        className={`language-item-btn ${
          isSelected && 'language-item-btn-selected'
        }`}
        onClick={onClick ? onClick : null}>
        <div
          className={`language-item-btn-txt ${
            isSelected && 'language-item-btn-txt-selected'
          }`}>
          {languageTxt}
        </div>
      </div>
    </div>
  );
};

export default LanguageItem;
