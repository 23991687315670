import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  from: '', //page to navigate after success login
  gameInfo: null,
};

export const journeySlice = createSlice({
  name: 'journey',
  initialState,
  reducers: {
    saveJourney: (state, action) => {
      state.from = action.payload.from;
      state.gameInfo = action.payload.gameInfo;
    },
  },
});

export class JourneyObject {
  /**
   * @constructor
   * @param {string} from
   * @param {object} gameInfo
   */
  constructor(from, gameInfo = null) {
    this.from = from;
    this.gameInfo = gameInfo;
  }

  toPlainObject() {
    return {
      from: this.from,
      gameInfo: this.gameInfo,
    };
  }
}

export const {saveJourney} = journeySlice.actions;
export default journeySlice.reducer;
