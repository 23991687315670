import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import './LoginMain.scss';
import BackNav from '../../common/components/navigationBar/BackNav';
import NavItem from '../../common/components/navigationItem/NavItem';
import MultiTabComponent from '../../common/components/tab/MultiTabComponent';
import {GetLogin} from '../../resources/image/Image';
import Login from './Login';
import Register from './Register';
import RegisterStepImgComponent from '../../common/components/Register/RegisterStepImgComponent';
import {useNav} from '../../common/utils/helper';
import {clientInfo} from '../../resources';
import {useSelector} from 'react-redux';

export const loginTabId = 'login';
export const registerTabId = 'register';

export default function LoginMain({selectedTabId}) {
  const {t: trans} = useTranslation();
  const tabObjects = [
    {displayText: trans('signIn.login'), id: loginTabId},
    {displayText: trans('signIn.register'), id: registerTabId},
  ];

  const navigate = useNav();

  const handleTabSelection = (selectedTabId) => {
    switch (selectedTabId) {
      case loginTabId:
        navigate('/login');
        break;
      case registerTabId:
        navigate('/register');
        break;
      default:
        break;
    }
  };

  const {regSkipOtp, skipBindBank} = useSelector(({company}) => company);
  const registerConfig = clientInfo.register;
  // const enableRegisterBank = registerConfig.enableRegisterBank

  return (
    <div className="loginMain-container container-padding">
      <div className="loginMain-style">
        <div className="loginMain-sticky-header">
          <MultiTabComponent
            className={'loginMain-multi-tab-container'}
            itemClassName={'loginMain-tab-item-container'}
            selectedItemClassName={'loginMain-tab-item-container-selected'}
            itemTxtClassName={'loginMain-tab-item-txt'}
            tabObjects={tabObjects}
            selectedTabId={selectedTabId}
            onSelectCallBack={handleTabSelection}
          />
          {selectedTabId === registerTabId ? (
            (!regSkipOtp || !skipBindBank) ? (
              <div className="loginMain-step-img-container">
                <RegisterStepImgComponent step={1} />
              </div>
            ) : (
              null
            )
          ) : null}
        </div>
        {selectedTabId === loginTabId && <Login />}

        {selectedTabId === registerTabId && (
          <Register regSkipOtp={regSkipOtp} />
        )}
      </div>
    </div>
  );
}
