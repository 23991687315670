import React, {Component} from 'react';
import './Plane.scss';

class Plane extends Component {
  static planeInstance;
  state = {show: false};

  static show() {
    this.planeInstance.showLoading();
  }

  static hide() {
    this.planeInstance.hideLoading();
  }

  showLoading() {
    this.setState({show: true});
  }

  hideLoading() {
    setTimeout(() => {
      this.setState({show: false});
    }, 1); //setState bug too fast cannot close
  }

  render() {
    const {show} = this.state;

    return show ? <div className="plane-container" /> : null;
  }
}

export default Plane;
