import React, {useState} from 'react';

import './PermanentAngpao.scss';
import {GetPromo} from '../../resources/image/Image';
import {config, useSpring, animated} from 'react-spring';
import Anim from '../../common/components/root/animation/Anim';

export default function PermanentAngpao() {
  const Promo = GetPromo();
  const [open, setOpen] = useState(false);
  const [choiShen, setChoiShen] = useState(false);

  const [{number}, api] = useSpring(() => ({
    from: {number: 0},
    number: 0,
    config: config.molasses,
  }));

  function ShakeAngpao() {
    if (open) {
      Anim.hide();
      return;
    }

    const angpao = document.getElementById('permanent-angpao-icon');
    const smallAngpao = document.getElementById('permanent-smallAngpao-icon');
    const label = document.getElementById('permanent-label-icon');

    label.style.display = 'none';
    smallAngpao.style.display = 'none';
    angpao.style.animation = '0.1s permanentShakeAngpao forwards';
    angpao.style.animationIterationCount = 10;
    setOpen(true);
  }

  function ClaimAngpao() {
    if (open) {
      setChoiShen(true);
      const angpao = document.getElementById('permanent-angpao-icon');
      const coins = document.getElementById('permanent-smallAngpao-icon');
      const light = document.getElementById('permanent-light');
      const amt = document.getElementById('permanent-amt');

      angpao.style.animation = '0.5s permanentChoiShen forwards';
      coins.style.display = 'flex';
      coins.style.animation =
        '0.5s linear 0.1s permanentSmallAngpaoZoom forwards';
      light.style.display = 'flex';
      amt.style.display = 'flex';

      api.start({
        number: 10.3,
        delay: 1000,
        onRest: () => {},
      });
    }
  }

  return (
    <div className="permanent-container">
      <img
        alt="smallAngpao"
        id="permanent-smallAngpao-icon"
        src={
          choiShen
            ? Promo.permanentAngpao.coins
            : Promo.permanentAngpao.angpaoSmall
        }
        className="permanent-smallAngpao-icon"
        onAnimationEnd={() => {
          const smallAngpao = document.getElementById(
            'permanent-smallAngpao-icon',
          );
          if (!open) {
            smallAngpao.style.animation = `permanentSmallAngpaoHeartbeat 5s ease-in-out infinite forwards`;
          } else {
            smallAngpao.style.animation =
              'permanentSmallAngpaoHeartbeat 3s ease-in-out infinite forwards';
          }
        }}
      />
      <div className="permanent-stage-container">
        <img
          alt="light"
          id="permanent-light"
          src={Promo.permanentAngpao.light}
          className="permanent-light"
        />
        <img
          alt="angpao"
          id="permanent-angpao-icon"
          src={
            choiShen
              ? Promo.permanentAngpao.angpaoOpen
              : Promo.permanentAngpao.angpao
          }
          className="permanent-angpao-icon"
          onClick={() => ShakeAngpao()}
          onAnimationEnd={() => ClaimAngpao()}
        />
        <animated.div id="permanent-amt" className="permanent-amt">
          {number.to((n) => n.toFixed(2))}
        </animated.div>
        <img
          alt="stage"
          src={Promo.permanentAngpao.stageFront}
          className="permanent-stageFront-icon"
          onClick={() => ShakeAngpao()} // permanent angpao position absolute bottom part unable to click
        />
      </div>
      <img
        alt="label"
        id="permanent-label-icon"
        src={Promo.permanentAngpao.angpaoLabel}
        className="permanent-label-icon"
      />
    </div>
  );
}
