import Anim from '../components/root/animation/Anim';

export function CheckLocalFortuneWheel() {
  const flag = JSON.parse(localStorage.getItem('FW'));

  if (flag) {
    const firstElement = flag[0];
    const {position, bonus, bonusPecent, depositAmt, depositTime, type} =
      firstElement;

    Anim.show({
      type: 'wheel',
      props: {
        position: position,
        bonus: bonus,
        bonusPecent: bonusPecent,
        depositAmt: depositAmt,
        depositTime: depositTime,
        type: type,
      },
    });
  }
  return flag;
}
