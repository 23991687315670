import React, {Component} from 'react';
import CPButton from '../../button/CPButton';
import '../loading_spinner/Loading.scss';
import './Dialog.scss';

class Dialog extends Component {
  static dialogInstance;

  state = {
    show: false,
  };

  /**
   * @description  If error no cancel button
   * @description [btnAct] - button callback
   * @description [type] - By default 'option'
   * @description [disabledCancelBtn] - By default 'false'
   * @param {object} config
   * @param {boolean} config.show
   * @param {string} [config.msg]
   * @param {('error'| 'success' | 'option')} [config.type]
   * @param {string} [config.btnTxt]
   * @param {function} [config.btnAct]
   * @param {boolean} [config.cancelBtn]
   */
  static show(config) {
    this.dialogInstance.showDialog(config);
  }

  static hide() {
    this.dialogInstance.hideDialog();
  }

  showDialog(config) {
    this.setState({
      show: config.show,
      msg: config.msg || 'Dialog here !',
      content: config.content || null,
      type: config.type || 'option',
      btnTxt: config.btnTxt,
      // btnAct: config.btnAct || this.defaultcallback,
      btnAct: config.btnAct,
      cancelBtn: config.cancelBtn,
      customize: config.customize,
    });
    document.body.style.overflow = 'hidden';
  }

  defaultcallback() {
    return alert('callback');
  }

  hideDialog() {
    this.setState({show: false});
    document.body.style.overflow = 'scroll';
  }

  render() {
    const {trans} = this.props;
    const {
      show,
      msg,
      content,
      type,
      btnTxt = trans('dialog.btn.ok'),
      btnAct,
      // disabledCancelBtn = type === 'error' ? true : false,
      cancelBtn,
      customize,
    } = this.state;

    return show ? (
      <div className="spinner-container">
        <div className="dialog-container">
          <div className="dialog-content-container">
            {customize ? (
              customize
            ) : (
              <>
                <div className="dialog-title">{msg}</div>
                <div className="dialog-content">{content}</div>
              </>
            )}
          </div>
          <div className="dialog-btn-container">
            <CPButton
              btnBg={`dialog-${type}-bg`}
              btnStyle={'dialog-btn'}
              textStyle={'dialog-btn-txt'}
              title={btnTxt}
              onClick={() => {
                if (btnAct) btnAct();
                this.hideDialog();
              }}
            />
            {cancelBtn ? (
              <div className="dialog-cancel" onClick={() => this.hideDialog()}>
                {trans('general.btn.cancel')}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    ) : null;
  }
}

export default Dialog;
