import React, {useEffect, useState} from 'react';
import './SignUpModal.scss';
import {GetCommon, GetSign_In} from '../../../resources/image/Image';
import {useModal} from '../../context/ModalContext';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import '../../../pages/sign_in_out/SignUp.scss';
import '../../../pages/sign_in_out/SignIn.scss';
import Timer from '../../../common/components/timer/Timer';
import api from '../../../common/api';
import {useTranslation} from 'react-i18next';
import CPButton from '../../../common/components/button/CPButton';
import OtpInput from 'react18-input-otp';
import {getReferral} from '../../../common/utils/helper';
import {millisecondToTime} from '../../../common/utils/format';
import Dialog from '../../../common/components/root/dialog/Dialog';
import {ENTERPIN_MODAL_KEY, SETPIN_MODAL_KEY} from '../../utils/loginHelper';
import EnterPinModal from './EnterPinModal';
import SetPinModal from './SetPinModal';

function SignUpModal({mobile, verify, fromForgotPin, state, lineUuid}) {
  const {popLastAndPushModal, popModal} = useModal();
  const Common = GetCommon();
  const Sign_In = GetSign_In();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from;
  const extraState = location.state?.extraState;
  const {t: trans} = useTranslation();
  const {seoRefcode, refcodeExp} = useSelector(({company}) => company);
  const refcode = useSearchParams()[0].get('rc') || getReferral(refcodeExp);
  const countdown = 300;

  //#region ===> referrer code
  const [ref, setRef] = useState(refcode);
  //#endregion

  //#region ===> OTP
  const [otp, setOtp] = useState('');
  const [time, setTime] = useState(0);
  const [prefix, setPrefix] = useState('');
  const [req, setReq] = useState(true);
  //#endregion
  const [refreshKey, setRefreshKey] = useState(0); //just a key for trigger the useEffect action

  useEffect(() => {
    if (!mobile) {
      popModal();
      return;
    }

    RequestOTP();
    VerifyRef();
  }, [refreshKey]);

  useEffect(() => {
    if (!ref) {
      const refStore = getReferral(refcodeExp);
      if (refStore) setRef(refStore);
    }
  }, [refcodeExp]);

  function RequestOTP() {
    const localTime = JSON.parse(sessionStorage.getItem('otp')) ?? {};
    const {mobile: oldMobile} = localTime;

    if (localTime === null || oldMobile !== mobile) {
      ReqOTP();
    } else {
      const countdownTimer = millisecondToTime(localTime.timer, countdown);
      const getOtp = JSON.parse(sessionStorage.getItem('otp'));

      if (countdownTimer === undefined || countdownTimer === 0) {
        ReqOTP();
        return;
      }

      setPrefix(getOtp.prefix);
      setTime(countdownTimer);
    }
  }

  function ReqOTP() {
    api.RequestOtp({
      mobileNumber: mobile,
      check: fromForgotPin ? 'no' : 'yes',
      error: (res) => {
        Dialog.show({
          show: true,
          msg: res.msg,
          type: 'error',
          btnAct: () => {
            setRefreshKey((value) => value + 1);
          },
        });
      },
      success: (res) => {
        const timestamp = Math.floor(Date.now() / 1000);
        const newPrefix = res.data.prefix;
        const data = {
          timer: timestamp,
          prefix: newPrefix,
          mobile: mobile,
        };

        setPrefix(res.data.prefix);
        sessionStorage.setItem('otp', JSON.stringify(data));
        setTime(countdown);
      },
    });
  }

  function VerifyRef() {
    if (fromForgotPin) return;

    const isFromGoogleSEO = localStorage.getItem('isFromGoogleSEO') === 'true';

    api.VerifyReferrer({
      referrer: ref,
      success: (res) => {
        if (res.noData) {
          setRef(isFromGoogleSEO ? seoRefcode : '');
        }
      },
      error: () => {
        setRef(isFromGoogleSEO ? seoRefcode : '');
      },
      option: {
        dialog: false,
      },
    });
  }

  const handleChange = (enteredOtp) => {
    setOtp(enteredOtp);
  };

  return (
    <div className="signUp-modal-main-container">
      <img
        src={Common.modalClose}
        alt="close"
        className="signUp-modal-close-icon"
        onClick={() => {
          popModal();
        }}></img>
      <div className="signUp-modal-container">
        <div className="signUp-modal-title">
          {trans('general.label.verifyCode')}
        </div>
        <img
          alt="verify-modal-mobile"
          src={Sign_In.verifyMobile}
          className="signUp-modal-verify-mobile-logo"
        />
        <div className="signUp-modal-tips">{trans('signUp.otp.tips1')}</div>
        <div className="signUp-modal-mobile">+{mobile}</div>
        <div className="signUp-modal-otp-container">
          <div className="signUp-modal-prefix">{prefix}: </div>
          <OtpInput
            value={otp}
            onChange={handleChange}
            numInputs={6}
            separateAfter={3}
            isInputNum={true}
            autoComplete={'on'}
            shouldAutoFocus={true}
            inputStyle={'signUp-modal-otp-input'}
            containerStyle={'signUp-modal-otp-group'}
          />
        </div>
        <div className="signUp-modal-tips signUp-modal-timer">
          {trans('signUp.otp.tips3')}
          {time ? (
            <Timer
              style={{
                marginLeft: 5,
              }}
              className={'signUp-modal-countdown'}
              expiresIn={time}
              callback={() => {
                setTime(0);
                setReq(false);
              }}
            />
          ) : null}
        </div>
        <CPButton
          btnBg={'signUp-modal-verify-btn-bg'}
          disabled={otp.length <= 5}
          btnStyle={`signUp-modal-verify-btn ${
            otp.length <= 5 && 'signUp-modal-disabled-btn'
          } `}
          title={trans('general.btn.verify')}
          textStyle={`signUp-modal-txt ${
            otp.length <= 5 && 'signUp-modal-verify-disabled-txt'
          }`}
          onClick={() => {
            api.VerifyOtp({
              mobileNumber: mobile,
              otp: `${prefix}-${otp}`,
              success: () => {
                if (verify) {
                  localStorage.setItem('verified', mobile);
                  popLastAndPushModal({
                    key: ENTERPIN_MODAL_KEY,
                    content: (
                      <EnterPinModal
                        mobile={mobile}
                        state={state}></EnterPinModal>
                    ),
                    showAnim: false,
                  });
                } else {
                  popLastAndPushModal({
                    key: SETPIN_MODAL_KEY,
                    content: (
                      <SetPinModal
                        mobile={mobile}
                        fromForgotPin={fromForgotPin ? 'forgotPin' : null}
                        refcode={fromForgotPin ? '' : ref}
                        lineUuid={lineUuid}
                        state={state}></SetPinModal>
                    ),
                    showAnim: false,
                  });
                }
              },
            });
            sessionStorage.removeItem('otp');
          }}
        />

        <CPButton
          btnBg={'signUp-modal-resend-btn'}
          disabled={req}
          btnStyle={`signUp-modal-timer-btn ${
            req && 'signUp-modal-disabled-resend-btn'
          } `}
          textStyle={`signUp-modal-resend-txt ${
            req && 'signUp-modal-disabled-txt'
          }`}
          title={trans('general.btn.reSend')}
          onClick={() => {
            setOtp('');
            setReq(true);
            VerifyRef();
          }}
        />
      </div>
      {verify || fromForgotPin ? null : (
        <img
          src={Common.step2}
          alt="step-img"
          className="signUp-modal-step-icon"
        />
      )}
    </div>
  );
}

export default SignUpModal;
