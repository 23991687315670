import {createSlice} from '@reduxjs/toolkit';
import {clientInfo} from '../../resources';

const initialState = {
  CMWalletDetail: [
    {
      name: 'Balance',
      prop: {
        background: 'linear-gradient(to right, #350BC8, #8633C3, #B72DBC)',
      },
    },
    {
      name: 'Comm',
      prop: {
        background: 'linear-gradient(to right, #D7437C, #F6B56B)',
        // backgroundImage:
        //   'url("https://static.gwvkyk.com/media/8b6b8aff6ac16.gif")',
      },
    },
  ],
  CMSelectedFilterGameBg: '#FFFFFF', //'linear-gradient(to right, #d7437c, #f6b56b',
  CMFooter: ['home', 'reward', 'affiliate', 'fund', 'vip', 'chat'],
  CMOpt: ['hot', 'sort', 'fav'],
  showHeaderFooter: true,
  iframeContent: false, //when the page like movie is using webview to show content
  theme: clientInfo.defaultTheme,
};

export const stylingSlice = createSlice({
  name: 'styling',
  initialState,
  reducers: {
    setCMWalletDetail: (state, action) => {
      state.CMWalletDetail = action.payload;
    },
    setCMSelectedFilterGameBg: (state, action) => {
      state.CMSelectedFilterGameBg = action.payload;
    },
    setCMFooter: (state, action) => {
      state.CMFooter = action.payload;
    },
    setCMOpt: (state, action) => {
      state.CMOpt = action.payload;
    },
    setTheme: (state, action) => {
      document.body.classList.remove(state.theme);
      document.body.classList.add(action.payload);
      state.theme = action.payload;
    },
    setShowHeaderFooter: (state, action) => {
      state.showHeaderFooter = action.payload;
    },
    setIframeContent: (state, action) => {
      state.iframeContent = action.payload;
    },
  },
});

export const {
  setCMWalletDetail,
  setCMSelectedFilterGameBg,
  setCMFooter,
  setCMOpt,
  setTheme,
  setShowHeaderFooter,
  setIframeContent,
} = stylingSlice.actions;
export default stylingSlice.reducer;
