import React from 'react';
import './FocusComponent.scss';

export default function FocusComponent({children, style}) {
  return (
    <div className={'focus-component-container'} style={style}>
      {children}
    </div>
  );
}
