import React from 'react';
import './PopUpItem.scss';

//example object
// const todayOptionId = 'today';
// const ytdOptionId = 'yesterday';
// const last7OptionId = 'last7day';
// const last15OptionId = 'last15day';
// const last30OptionId = 'last30day';
// const dayObjects = [
//   {displayText: trans('general.list.today'), id: todayOptionId},
//   {displayText: trans('general.list.yesterday'), id: ytdOptionId},
//   {displayText: trans('general.list.last7'), id: last7OptionId},
//   {displayText: trans('general.list.last15'), id: last15OptionId},
//   {displayText: trans('general.list.last30'), id: last30OptionId},
// ];
export default function PopUpItem({objects, onClickCallBack}) {
  return (
    <div className="popUpItem-container">
      {objects.map((item, index) => {
        return (
          <div
            key={index}
            className="popUpItem-item-container"
            onClick={() => {
              if (onClickCallBack) {
                onClickCallBack(item.id);
              }
            }}>
            <div className="popUpItem-item-txt">{item.displayText}</div>
            {index === objects.length - 1 ? null : (
              <div className="popUpItem-item-separator" />
            )}
          </div>
        );
      })}
    </div>
  );
}
