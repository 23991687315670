import React from 'react';
import './RegisterStepImgComponent.scss';
import {GetLogin} from '../../../resources/image/Image';
// import {clientInfo} from '../../../resources';
import {useSelector} from 'react-redux';

export default function RegisterStepImgComponent({step}) {
  const loginImg = GetLogin();
  // const registerConfig = clientInfo.register;
  // const isRequiredOtp = !registerConfig.enableSkipOtp
  const {regSkipOtp, skipBindBank} = useSelector(({company}) => company);

  const GetStepImg = () => {
    return loginImg[
      `step${step}${(!skipBindBank && !regSkipOtp) ? '' : '_2'}`
    ];
  };

  return (
    <div className="registerStepImg-container">
      <img
        className="registerStepImg-img"
        alt={`step ${step}`}
        src={GetStepImg()}
      />
    </div>
  );
}
