import {useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {io} from 'socket.io-client';
import {clientInfo} from '../../resources';
import Dialog from '../components/root/dialog/Dialog';
import {balance} from '../redux/userSlice';
import {isStag} from '../utils/envHelper';
import {useNav as useNavigate} from '../../common/utils/helper';
import Notif from '../components/root/notification/notif';

function Socket() {
  const socketUrl = isStag ? clientInfo.stagSocketUrl : clientInfo.socketUrl;
  const {profile} = useSelector(({user}) => user);
  const isConnected = useRef(false);
  const dispatch = useDispatch();
  const {t: trans} = useTranslation();
  const navigate = useNavigate();

  const socketInit = () => {
    const socket = io(socketUrl, {
      transportOptions: {
        polling: {
          extraHeaders: {
            Authorization: profile.userId,
          },
        },
      },
    });

    socket.on('connect', () => {
      isConnected.current = true;
    });

    socket.on('getNotification', (data) => {
      const {msg, cta, data: item} = data;
      Notif.show({
        msg,
        cta,
        item,
      });
    });

    socket.on('getWalletBalance', (data) => {
      const path = window.location.pathname;
      if (path === '/topupDetail/') {
        Dialog.show({
          show: true,
          msg: trans('dialog.msg.topUpSuccess'),
          type: 'success',
          btnAct: () => {
            navigate('/fund', {replace: true});
          },
        });
      }

      dispatch(balance(data));
      const prevData = JSON.parse(sessionStorage.getItem('logIn'));
      prevData.wallet.currbal = data.currbal;
      prevData.wallet.comm = data.comm;
      sessionStorage.setItem('logIn', JSON.stringify(prevData));
    });

    socket.on('disconnect', () => {
      isConnected.current = false;
    });
  };

  useEffect(() => {
    if (profile.userId && isConnected.current === false) {
      socketInit();
    }
  }, [profile.userId]);

  return null;
}

export default Socket;
