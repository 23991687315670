import React from 'react';
import './GameInfoItemComponent.scss';

export default function GameInfoItemComponent({title, value}) {
  return (
    <div className="game-info-item-container">
      <div className="game-info-item-title">{title}</div>
      <div className="game-info-item-separator"></div>
      <div className="game-info-item-value">{value}</div>
    </div>
  );
}
