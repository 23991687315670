import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import './Club.scss';
import '../home/GameListMay23.scss';
import TopNav from '../modal/TopNav';
import {GetGame, GetTKClub} from '../../resources/image/Image';
import api from '../../common/api';
import {useNav as useNavigate} from '../../common/utils/helper';
import {isEqual} from 'lodash';

export default function Club({title}) {
  const navigate = useNavigate();
  const {t: trans} = useTranslation();

  const [list, setList] = useState([]);
  const Game = GetGame();
  const TKClub = GetTKClub();

  useEffect(() => {
    Listing();
    getGameList();
  }, []);

  function getGameList() {
    const frmLocal = JSON.parse(localStorage.getItem('gList'));

    api.GameList({
      success: (res) => {
        if (res.data) {
          const newGameList = [...res.data];
          if (!isEqual(frmLocal, newGameList)) {
            localStorage.setItem('gList', JSON.stringify(newGameList));
            Listing();
          }
        }
      },
    });
  }

  function Listing() {
    const frmLocal = JSON.parse(localStorage.getItem('gList'));
    const filter = frmLocal
      ? frmLocal.filter((item) => item.gametype === 'club')
      : [];

    // if (filter.length === 0) {
    //   navigate('/');
    //   return;
    // }

    setList(filter);
  }

  return (
    <div className="streaming-container container-padding">
      {/* <TopNav title={title} /> */}
      <div className="club-style">
        <div className="club-banner-container">
          <img src={TKClub.banner} className="club-banner" alt="club-banner" />
          <div className="club-title">{trans('club.title')}</div>
        </div>
        <div className="club-list-container">
          {list.map((item) => {
            const gamePlatform = item.platform ? item.platform : item.provider;
            const name = item.gamename;

            function GameOptions({options}) {
              return options.map((val, i) => {
                const source =
                  val === 'hot'
                    ? Game.label.hotLabel
                    : val === 'new'
                    ? Game.label.newLabel
                    : null;
                return source ? (
                  <img
                    alt="club-hotLabel-icon"
                    className="club-hotLabel-icon"
                    key={i}
                    src={source}
                    style={{
                      top: i * 28,
                    }}
                  />
                ) : null;
              });
            }

            return (
              <div key={name} className="club-btn-style">
                <div className="club-btn-container">
                  <GameOptions options={item.option} />

                  <img
                    alt={`${item.gameName}`}
                    className={'club-icon'}
                    src={
                      Game.gameImg[`${item.provider}${item.gameid}`]
                        ? Game.gameImg[`${item.provider}${item.gameid}`]
                        : Game.defaultImg[gamePlatform]
                    }
                    onClick={() => {
                      switch (item.gameid) {
                        case 'streaming':
                          api.SportList({
                            navigate,
                          });
                          break;

                        // case 'Live Score':
                        // break;

                        case 'movie':
                          navigate('/movie');
                          break;

                        case 'adultmovie':
                          navigate('/porn');
                          break;

                        default:
                          break;
                      }
                    }}
                  />
                </div>
                <div className="club-name">
                  {name.length > 20 ? name.substring(0, 20) + '...' : name}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
