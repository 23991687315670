import React, {Component} from 'react';
import {GetCommonByUrl} from '../../../../resources/image/Image';
import './Loading.scss';
import {connect} from 'react-redux';

class Loading extends Component {
  static loadingInstance;
  state = {show: this.props.show ? this.props.show : false};

  static show() {
    this.loadingInstance.showLoading();
  }

  static hide() {
    this.loadingInstance.hideLoading();
  }

  showLoading() {
    this.setState({show: true});
    document.body.style.overflow = 'hidden';
  }

  hideLoading() {
    this.setState({show: false});
    document.body.style.overflow = 'scroll';
  }

  render() {
    const {show} = this.state;
    const {contentUrl} = this.props;
    const Common = GetCommonByUrl(contentUrl);

    return show ? (
      <div className="spinner-container">
        <img
          src={Common.loadingSpinner}
          className="loading-spinner-gif"
          alt="loading-spinner-gif"
        />
      </div>
    ) : null;
  }
}

const mapStateToProps = (state) => ({
  contentUrl: state.company.contentUrl,
});

export default connect(mapStateToProps, null, null, {forwardRef: true})(
  Loading,
);
