import React from 'react';
import {GetHome} from '../../resources/image/Image';
import './WalletDetail.scss';
import {useSelector} from 'react-redux';
import {format} from '../../common/utils';
import {
  NavigateToLogin,
  useNav as useNavigate,
} from '../../common/utils/helper';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';
import {ShowLoginDialog} from '../../common/utils/helper';
import {useModal} from '../../common/context/ModalContext';

function WalletDetail() {
  const useModalContext = useModal();
  const Home = GetHome();
  const location = useLocation();
  const navigate = useNavigate();
  const {t: trans} = useTranslation();
  const {currency} = useSelector(({company}) => company);
  const {isLogin} = useSelector((state) => state.user);
  const {wallet, isMobile} = useSelector(({user}) => user);

  const formatBal = (amt) => {
    const n = format.chopDecimal(amt, 2);

    return format.formatCurrency(
      n.length <= (isMobile ? 9 : 10)
        ? format.formatCurrency(format.chopDecimal(amt, 2))
        : format.chopDecimal(amt, 0),
    );
  };

  return (
    <div>
      {isLogin ? (
        <div
          className="wallet-balance-container"
          onClick={() => {
            navigate('/fund');
          }}>
          <img
            className="wallet-balance-img"
            alt="balance"
            src={Home.wallet}></img>
          <div className="wallet-balance-text">{`${formatBal(
            wallet.currbal,
          )} (${currency})`}</div>
        </div>
      ) : (
        <div
          className="wallet-balance-container"
          style={{justifyContent: 'center'}}
          onClick={() => {
            // ShowLoginDialog(useModalContext);
            NavigateToLogin(navigate);
          }}>
          <div className="wallet-signin-text">
            {trans('general.title.welcome2')}
          </div>
        </div>
      )}
    </div>
  );
}

export default WalletDetail;
