import React, {createContext, useState, useContext} from 'react';

const FocusComponentContext = createContext();

export const FocusComponentProvider = ({children}) => {
  // {key: uniqueKey, content: element}
  const [focusComponent, setFocusComponent] = useState(null);

  const createFocusComponent = (focusComponent) => {
    setFocusComponent(focusComponent);
  };

  const clearFocusComponent = (key) => {
    console.log(`halo here many clear ${key} and `, focusComponent);
    //only clear the correct focus component
    setFocusComponent((prevValue) => {
      if (!prevValue) {
        return null;
      }
      if (key) {
        if (prevValue.key == key) {
          return null;
        } else {
          return prevValue;
        }
      }
      return null;
    });
  };

  return (
    <FocusComponentContext.Provider
      value={{
        focusComponent,
        createFocusComponent,
        clearFocusComponent,
      }}>
      {children}
    </FocusComponentContext.Provider>
  );
};

export const useFocusComponent = () => {
  return useContext(FocusComponentContext);
};
