import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import './GameListMay23.scss';
import GameCardComponent from './pageComponent/GameCardComponent';

export default function InnerGameList({
  filteredGameList,
  dummyParams,
  gameOnClick,
}) {
  const {t: trans} = useTranslation();
  const {anim} = useSelector((state) => state.option);

  const RenderGame = useMemo(() => {
    var size = anim ? 20 : filteredGameList.length; //aniamtion used for prevent laggy

    return filteredGameList.slice(0, size).map((game, index) => {
      return (
        <GameCardComponent
          key={index}
          item={game}
          updateHeightListener={index == 0 ? true : false}
          onClick={() => gameOnClick(game, dummyParams)}
        />
      );
    });
  }, [filteredGameList, dummyParams]);

  return (
    <div>
      {filteredGameList.length == 0 ? (
        <div className="game-nodata">{trans('general.list.noRecord')}</div>
      ) : (
        <div className="game-provider-list-container">{RenderGame}</div>
      )}
    </div>
  );
}
