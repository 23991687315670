import React, {useEffect, useState} from 'react';

import './Home.scss';
import api from '../../common/api';
import News from '../../common/components/root/news/News';
import GameList from './GameListMay23';
import {Pipeline} from '../../common/utils/helper';
import Anim from '../../common/components/root/animation/Anim';
import {isEqual} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {CheckLocalFortuneWheel} from '../../common/utils/FWHelper';
import {gameInit} from '../../common/redux/game/gameSlice';

export default function Home({
  isPage = true, //indicate whether this component is a page, false mean it treat as a normal component in to use
}) {
  const dispatch = useDispatch();
  const {isLogin} = useSelector((state) => state.user);
  const [gameListing, setGameListing] = useState([]);
  const [loadingState, setLoadingState] = useState(true);

  useEffect(() => {
    start();
    if (isLogin) {
      getBlockedGames();
    }
  }, [isLogin]);

  function start() {
    const chain = Pipeline(getGameList, getNews, getFortuneWheel);
    // const chain = Pipeline(getGameList, getFortuneWheel, getNews);
    // const chain = Pipeline(getNews, getGameList, getFortuneWheel);
    chain.go();
  }

  function getGameList(context, next) {
    const frmLocal = JSON.parse(localStorage.getItem('gList'));
    if (frmLocal) {
      setGameListing(frmLocal);
      setLoadingState(false);
    }

    api.GameList({
      success: (res) => {
        setLoadingState(false);
        if (res.data) {
          const newGameList = [...res.data];
          if (!isEqual(frmLocal, newGameList)) {
            localStorage.setItem('gList', JSON.stringify(newGameList));
            setGameListing(newGameList);
          }
        } else {
          setGameListing([]);
        }
      },
      error: () => {
        setLoadingState(false);
        setGameListing([]);
      },
    });

    context.PromoCheckFortuneWheel = true;
    next();
  }

  function getFortuneWheel(context, next) {
    if (!isLogin) {
      return;
    }
    setTimeout(() => {
      if (context.PromoCheckFortuneWheel) {
        const flag = CheckLocalFortuneWheel();

        if (flag) {
          if (typeof next === 'function') next();
        } else {
          api.PromoCheckFortuneWheel({next});
        }
      }
    }, 500);
  }

  function getNews(context, next) {
    if (!isLogin) {
      return;
    }
    const showNews = JSON.parse(sessionStorage.getItem('news'));
    if (showNews === null) {
      api.NewsList({
        success: (res) => {
          const data = res.data;

          if (res.noData) {
            context.PromoCheckFortuneWheel = true;
            next();
          } else {
            context.PromoCheckFortuneWheel = true;
            News.show(data, next);
          }
        },
      });
    } else {
      // context.PromoCheckFortuneWheel = true;
      next();
    }
  }

  function getBlockedGames() {
    api.GetBlockedGames({
      success: (res) => {
        const disallowGames = res?.data?.disallow ?? [];
        dispatch(gameInit(disallowGames));
      },
    });
  }

  return (
    <GameList
      gameListing={gameListing}
      isLoading={loadingState}
      isPage={isPage}
    />
  );
}
