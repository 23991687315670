import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import '../../../pages/sign_in_out/SignIn.scss';
import './CPBottomModal.scss';
import {GetCommon} from '../../../resources/image/Image';

CPBottomModal.propTypes = {
  isVisible: PropTypes.string,
  content: PropTypes.element,
  callback: PropTypes.func,
  icon: PropTypes.string,
  id: PropTypes.string,
  background: PropTypes.oneOf(['black', 'default']),
  bottomModalStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  insideCloseBtn: PropTypes.bool,
};

CPBottomModal.defaultProps = {
  isVisible: 'none',
  callback: {},
  background: 'default',
  insideCloseBtn: false,
};

export default function CPBottomModal({
  isVisible,
  content,
  callback,
  icon,
  id,
  background,
  bottomModalStyle,
  insideCloseBtn,
}) {
  useEffect(() => {
    document.getElementById(id).style.display = isVisible;
  }, [isVisible, id]);

  const prefab =
    background === 'black' ? 'bottomModal-black' : 'bottomModal-white';
  const Common = GetCommon();

  return (
    <div id={id} className="bottomModal-container">
      <div className={`bottomModal-content ${prefab} ${bottomModalStyle}`}>
        <div className="bottomModal-style ">
          {content}
          {insideCloseBtn ? (
            <div
              className="bottomModal-close-modal"
              onClick={() => {
                callback();
              }}>
              <img
                alt="bottomModal-close-icon"
                src={icon ? icon : Common.close2}
                className="bottomModal-close-icon"
                style={{height: icon ? 15 : null, width: icon ? 15 : null}}
              />
            </div>
          ) : null}
        </div>
        {insideCloseBtn ? null : (
          <div
            className="bottomModal-close-modal"
            onClick={() => {
              callback();
            }}>
            <img
              alt="bottomModal-close-icon"
              src={icon ? icon : Common.close2}
              className="bottomModal-close-icon"
              style={{height: icon ? 15 : null, width: icon ? 15 : null}}
            />
          </div>
        )}
      </div>
    </div>
  );
}
