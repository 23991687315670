import React, {useEffect, useRef} from 'react';
import './DimmedComponent.scss';
import {useFocusComponent} from '../../context/FocusComponentContext';

export default function DimmedComponent() {
  const {focusComponent, clearFocusComponent} = useFocusComponent();
  const bodyScrollRef = useRef(null);

  useEffect(() => {
    if (focusComponent == null) {
      if (bodyScrollRef.current) {
        document.body.style.overflow = bodyScrollRef.current;
      }
    } else {
      //to get the init value of body overflow, when closed focus component will use back the value
      const bodyStyle = getComputedStyle(document.body);
      bodyScrollRef.current = bodyStyle.overflow;
      document.body.style.overflow = 'hidden';
    }
  }, [focusComponent]);

  return (
    <div>
      <div
        onClick={() => {
          clearFocusComponent(focusComponent.key);
        }}
        className={`dimmed-container ${
          focusComponent == null ? 'dimmed-container-hidden' : ''
        }`}></div>
      {focusComponent == null ? null : focusComponent.content}
    </div>
  );
}
