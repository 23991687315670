/**
 * https://github.com/rafaelaugustos/react-native-popup-ui
 * Loading spinner, dialog
 * Prevent duplicate popup
 */
import React, {Component, Suspense} from 'react';
import {withTranslation} from 'react-i18next';
import Dialog from './dialog/Dialog.js';
import CustomAlert from './alert/CustomAlert.js';
import Loading from './loading_spinner/Loading.js';
import Bank from './bank/Bank.js';
import './Root.css';
import Toast from './toast/Toast.js';
import Info from './info/Info.js';
import Notification from './notification/Notification.js';
import Socket from '../../api/Socket.js';
import Plane from './Plane/Plane.js';
import News from './news/News.js';
import Feedback from './feedback/Feedback.js';
import Anim from './animation/Anim.js';
import Notif from './notification/notif.js';
import ModalStack from '../modal/ModalStack.js';
import SuspenseLoading from './loading_spinner/SuspenseLoading.js';
import GlobalBottomModal from '../bottomModal/GlobalBottomModal.js';

class Root extends Component {
  render() {
    const {t, navigate} = this.props;

    return (
      <div id="root-style" className="root-style" ref={(c) => (this._root = c)}>
        {this.props.children}
        <GlobalBottomModal></GlobalBottomModal>
        {/* <ModalStack /> */}
        <Bank
          trans={t}
          ref={(c) => {
            if (c) Bank.addBankInstance = c;
          }}
        />

        <Info
          trans={t}
          ref={(c) => {
            if (c) Info.infoInstance = c;
          }}
        />

        <News
          navigate={navigate}
          trans={t}
          ref={(c) => {
            if (c) News.newsInstance = c;
          }}
        />

        <Plane
          trans={t}
          ref={(c) => {
            if (c) Plane.planeInstance = c;
          }}
        />

        <Notification
          trans={t}
          ref={(c) => {
            if (c) Notification.notificationInstance = c;
          }}
        />

        <Notif
          navigate={navigate}
          trans={t}
          ref={(c) => {
            if (c) Notif.notifInstance = c;
          }}
        />

        <Feedback
          trans={t}
          ref={(c) => {
            if (c) Feedback.feedbackInstance = c;
          }}
        />

        <Socket />

        <Anim
          ref={(c) => {
            if (c) Anim.animInstance = c;
          }}
        />

        <CustomAlert
          ref={(c) => {
            if (c) CustomAlert.customAlertInstance = c;
          }}
        />

        <Dialog
          trans={t}
          ref={(c) => {
            if (c) Dialog.dialogInstance = c;
          }}
        />

        <Toast
          trans={t}
          ref={(c) => {
            if (c) Toast.toastInstance = c;
          }}
        />

        <SuspenseLoading
          ref={(c) => {
            if (c) SuspenseLoading.loadingInstance = c;
          }}
        />

        <Loading
          ref={(c) => {
            if (c) Loading.loadingInstance = c;
          }}
        />
      </div>
    );
  }
}

export default withTranslation()(Root);
