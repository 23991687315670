import React, { forwardRef, useRef, useState } from 'react';
import './ChangePasswordTextField.scss';
import BaseTextField from './BaseTextField';
import { GetCommon, GetTextField } from '../../../resources/image/Image';
import { useTranslation } from 'react-i18next';

const ChangePasswordTextField = forwardRef(
  (
    {
      title,
      titleClassName,
      containerClassName,
      enableHideShow = false,
      formatValidation = true,
      value,
      ...props
    },
    ref,
  ) => {
    const { t: trans } = useTranslation();
    const tfImg = GetTextField();
    const containerRef = useRef(null);
    const [errMsg, setErrMsg] = useState('');
    const [showValue, setShowValue] = useState(false);
    const [setIsFocused] = useState('');

    const handleErrMsg = (errMsg) => {
      setErrMsg(errMsg);
    };

    return (
      <div ref={containerRef} className="changePasswordTextField-container">
        <div className={`${titleClassName}`}>{title}</div>
        <div className={`changePasswordTextField-input-container`}>
          <div style={{ flex: '1' }}>
            <BaseTextField
              {...props}
              inputClassName={'changePasswordTextField-input'}
              placeholderClassName={'changePasswordTextField-placeholder'}
              type={showValue ? 'text' : 'password'}
              containerRef={containerRef}
              ref={ref}
              errMsgCallBack={handleErrMsg}
              isFocusedCallBack={setIsFocused}
              value={value}
              validation={(value) => {
                let errMsg = '';
                if (formatValidation) {
                  if (value.length < 6) {
                    errMsg = 'general.errMsg.pwFormat';
                  }
                  if (!errMsg && /(.)\1\1/.test(value)) {
                    errMsg = 'general.errMsg.pwThreeSameCharac';
                  }
                  if (!errMsg && !/[A-Z]/.test(value)) {
                    errMsg = 'general.errMsg.pwUpperCase';
                  }
                  if (!errMsg && !/[a-z]/.test(value)) {
                    errMsg = 'general.errMsg.pwLowerCase';
                  }
                  if (!errMsg && !/\d/.test(value)) {
                    errMsg = 'general.errMsg.pwNumFormat';
                  }
                }
                if (!errMsg && props.validation && value.length >= 6) {
                  errMsg = props.validation(value);
                }
                return errMsg;
              }}
            />
          </div>
          {enableHideShow ? (
            <div
              onClick={() => {
                setShowValue((value) => !value);
              }}
              className="changePasswordTextField-hide-show-img-container">
              <img
                className="changePasswordTextField-hide-show-img"
                alt={showValue ? 'hide' : 'show'}
                src={showValue ? tfImg.pwHide : tfImg.pwShow}
              />
            </div>
          ) : null}
        </div>
        {errMsg ? (
          <div className="changePasswordTextField-error-msg">
            {trans(errMsg)}
          </div>
        ) : null}
      </div>
    );
  },
);

export default ChangePasswordTextField;
