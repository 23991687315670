import React, {forwardRef, useEffect, useRef, useState} from 'react';
import './PasswordTextField.scss';
import BaseTextField from './BaseTextField';
import {GetCommon, GetTextField} from '../../../resources/image/Image';
import {useTranslation} from 'react-i18next';

const PasswordTextField = forwardRef(
  ({enableHideShow = false, formatValidation = true, ...props}, ref) => {
    const {t: trans} = useTranslation();
    const commonImg = GetCommon();
    const tfImg = GetTextField();
    const containerRef = useRef(null);
    const [errMsg, setErrMsg] = useState('');
    const [showValue, setShowValue] = useState(false);
    const [isFocused, setIsFocused] = useState('');

    const handleErrMsg = (errMsg) => {
      setErrMsg(errMsg);
    };

    return (
      <div ref={containerRef} className="passwordTextField-container">
        <div
          className={`passwordTextField-input-container ${
            errMsg ? 'passwordTextField-error-input-container' : ''
          } ${isFocused ? 'passwordTextField-focus-input-container' : ''}`}>
          <img
            className="passwordTextField-icon"
            alt={'password'}
            src={tfImg.password}
          />
          <div style={{flex: '1'}}>
            <BaseTextField
              {...props}
              inputClassName={'passwordTextField-input'}
              placeholderClassName={'passwordTextField-placeholder'}
              type={showValue ? 'text' : 'password'}
              containerRef={containerRef}
              ref={ref}
              errMsgCallBack={handleErrMsg}
              isFocusedCallBack={setIsFocused}
              validation={(value) => {
                let errMsg = '';
                if (formatValidation && value.length < 6) {
                  errMsg = 'general.errMsg.pwFormat';
                }
                if (props.validation && value.length >= 6) {
                  errMsg = props.validation(value);
                }
                return errMsg;
              }}></BaseTextField>
          </div>
          {enableHideShow ? (
            <div
              onClick={() => {
                setShowValue((value) => !value);
              }}
              className="passwordTextField-hide-show-img-container">
              <img
                className="passwordTextField-hide-show-img"
                alt={showValue ? 'hide' : 'show'}
                src={showValue ? tfImg.pwHide : tfImg.pwShow}
              />
            </div>
          ) : null}
        </div>
        {errMsg ? (
          <div className="passwordTextField-error-msg">{trans(errMsg)}</div>
        ) : null}
      </div>
    );
  },
);

export default PasswordTextField;
