import React from 'react';
import './MobileCodeBottomModal.scss';
import {useTranslation} from 'react-i18next';
import {GetSign_In} from '../../../resources/image/Image';
import {GetPureCountryCode} from '../../utils/helper';

export default function MobileCodeBottomModal({
  mobileCode,
  selectedId,
  onClick,
}) {
  const {t: trans} = useTranslation();
  const Sign_In = GetSign_In();

  function CountryOpt({title, id, img, onClick}) {
    const selected = GetPureCountryCode(id) === GetPureCountryCode(selectedId);

    return (
      <div
        id={id}
        className={`mobileCodeBottomModal-selected-container ${
          selected
            ? 'mobileCodeBottomModal-selected-bg'
            : 'mobileCodeBottomModal-unselect-bg'
        }`}
        onClick={onClick}>
        <img
          alt="mobileCodeBottomModal-country-icon"
          src={Sign_In.country[img]}
          className="mobileCodeBottomModal-country-icon"
        />
        <div>{title}</div>
      </div>
    );
  }

  return (
    <div className="mobileCodeBottomModal-select-container">
      <div className="mobileCodeBottomModal-title">
        {trans('general.label.chooseCountry')}
      </div>
      <div className="mobileCodeBottomModal-select-style">
        {mobileCode.map((item, index) => {
          return (
            <CountryOpt
              key={index}
              id={item.id}
              title={item.title}
              img={item.code}
              onClick={() => {
                onClick(item.id, selectedId);
              }}
            />
          );
        })}
      </div>
    </div>
  );
}
