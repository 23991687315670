import LineLogin from '../sign_in_out/LineLogin';
import LoginMain, {loginTabId, registerTabId} from '../sign_in_out/LoginMain';
import RegisterBank from '../sign_in_out/RegisterBank';
import SetPassword from '../sign_in_out/SetPassword';
import EnterPassword from '../sign_in_out/EnterPassword';
import ForgotPassword from '../sign_in_out/ForgotPassword';

const AuthRoutes = [
  {
    path: '/linelogin',
    element: <LineLogin />,
  },
  {
    path: '/login',
    element: <LoginMain selectedTabId={loginTabId} key={loginTabId} />,
  },
  {
    path: '/register',
    element: <LoginMain selectedTabId={registerTabId} key={registerTabId} />,
  },
  {
    path: '/registerBank',
    element: <RegisterBank />,
  },
  {
    path: '/setPassword',
    element: <SetPassword />,
  },
  {
    path: '/enterPassword',
    element: <EnterPassword />,
  },
  {
    path: '/forgotPassword',
    element: <ForgotPassword />,
  },
];

export default AuthRoutes;
