import React, {Component} from 'react';
import CPButton from '../../button/CPButton';
import '../loading_spinner/Loading.scss';
import './CustomAlert.scss';

class CustomAlert extends Component {
  static customAlertInstance;

  state = {
    show: false,
  };

  //config can refer to setState in showCustomAlert
  static show(config) {
    this.customAlertInstance.showCustomAlert(config);
  }

  static hide() {
    this.customAlertInstance.hideCustomAlert();
  }

  showCustomAlert(config) {
    this.setState({
      backgroundColor: config.backgroundColor,
      border: config.border,
      show: config.show,
      view: config.view,
    });
    document.body.style.overflow = 'hidden';
  }

  hideCustomAlert() {
    this.setState({show: false});
    document.body.style.overflow = 'scroll';
  }

  render() {
    const {backgroundColor, border, show, view} = this.state;
    const containerBackground = backgroundColor
      ? {backgroundColor: backgroundColor}
      : {};
    const containerBorder = border ? {border: border} : {};
    const mergedContainerStyle = {...containerBackground, ...containerBorder};
    return show ? (
      <div className="spinner-container">
        <div className="custom-alert-container" style={mergedContainerStyle}>
          {view}
        </div>
      </div>
    ) : null;
  }
}

export default CustomAlert;
