import React, {useEffect, useState} from 'react';
import {useSpring, animated, useSpringRef, useChain} from 'react-spring';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import './Wheel.scss';
import '../../common/components/root/info/Info.scss';
import {GetPromo} from '../../resources/image/Image';
import Anim from '../../common/components/root/animation/Anim';
import {clientInfo} from '../../../src/resources/';
import api from '../../common/api';
import {Pipeline, RemoveFWFlag} from '../../common/utils/helper';

export default function Wheel({
  position,
  bonus,
  bonusPecent,
  depositAmt,
  depositTime,
  type,
  next,
}) {
  const Promo = GetPromo();
  const {wheel} = Promo;
  const {t: trans} = useTranslation();
  const {currency} = useSelector(({company}) => company);
  const [display, setDisplay] = useState(false);
  const [check, setCheck] = useState(false);
  const [localCheck, setLocalCheck] = useState(false);
  const [infoDialog, setInfoDialog] = useState(false);
  const version = clientInfo.wheel[type];
  const wheelImg = wheel[type];

  useEffect(() => {
    setDisplay(true);
  }, []);

  useEffect(() => {
    const info = JSON.parse(localStorage.getItem('wheel'));

    setLocalCheck(info);
    if (info) return;

    setInfoDialog(true);
    infoRef.start({
      to: [{opacity: 1, scale: 1}],
    });
  }, []);

  //#region Animation
  const fortuneWheelRef = useSpringRef();
  const pointerRef = useSpringRef();
  const coinRef = useSpringRef();
  const backlightRef = useSpringRef();
  const spotlightRef = useSpringRef();
  const containerRef = useSpringRef();
  const flashlightRef = useSpringRef();
  const spinBtnRef = useSpringRef();
  const dialogRef = useSpringRef();
  const infoRef = useSpringRef();

  const flashlight = useSpring({
    ref: flashlightRef,
    from: {opacity: 0, scale: 1},
  });

  const container = useSpring({
    ref: containerRef,
    from: {scale: 1},
  });

  const dialog = useSpring({
    ref: dialogRef,
    from: {opacity: 0, scale: 0},
  });

  const info = useSpring({
    ref: infoRef,
    from: {opacity: 0, scale: 0},
  });

  const fortuneWheel = useSpring({
    ref: fortuneWheelRef,
    from: {translateY: -window.innerHeight, rotate: 0},
    to: {translateY: display ? 0 : -window.innerHeight},
  });

  const pointer = useSpring({
    ref: pointerRef,
    from: {scale: 0},
    to: [{scale: 1}],
  });

  const coin = useSpring({
    ref: coinRef,
    from: {scale: 0},
    to: [
      {scale: 1},
      {
        from: {scale: 1},
        to: [{scale: 0.9}, {scale: 1}],
        config: {tension: 200},
        loop: true,
        delay: 1000,
      },
    ],
  });

  const backlight = useSpring({
    ref: backlightRef,
    from: {scale: 0, rotate: 0},
    to: [
      {scale: 1},
      {
        to: {rotate: 360},
        config: {duration: 10000},
        loop: true,
      },
    ],
  });

  const spotlight = useSpring({
    ref: spotlightRef,
    from: {opacity: 0},
    to: [{opacity: 1}],
  });

  const spinBtn = useSpring({
    ref: spinBtnRef,
    from: {scale: 0},
    to: [{scale: 1}],
  });

  useChain(
    display
      ? [
          fortuneWheelRef,
          spotlightRef,
          pointerRef,
          coinRef,
          backlightRef,
          spinBtnRef,
        ]
      : [],
    [0, 0.6, 0.6, 0.6, 0.6, 0.8],
  );
  //#endregion

  const spin = () => {
    const index = position;
    const offset =
      Math.floor(Math.random() * 6) * (Math.random() < 0.5 ? -1 : 1);
    document.getElementById('wheel-spin').style.display = 'none';
    document.getElementById('wheel-spin-cancel').style.display = 'none';

    if (infoDialog) return;
    document.getElementById('wheel-info-icon').style.display = 'none';
    spinBtnRef.start({to: {scale: 0}});
    spotlightRef.start({to: {opacity: 0}});
    containerRef.start({
      to: {scale: 1.2},
      onRest: () => {
        api.PromoClaimFortuneWheel({
          depositTime: depositTime,
          bonus: bonus,
          depositAmt: depositAmt,
          bonusPercent: bonusPecent,
        });
        RemoveFWFlag();
        fortuneWheelRef.start({
          to: [
            {
              rotate: 360 * 3 + index * (360 / version.options.length) + offset,
              config: {
                friction: 100,
                tension: 100,
              },
            },
          ],
          onRest: () => {
            flashlightRef.start({
              to: [{opacity: 1, scale: 20}, {opacity: 0}],
              onChange: (result) => {
                const value = Math.floor(result.value.opacity * 100);

                if (value === 100) {
                  spinBtnRef.start({to: {scale: 0}});
                  dialogRef.start({
                    to: [{opacity: 1, scale: 1}],
                  });
                }
              },
            });
          },
        });
      },
    });
  };

  function CheckPromo() {
    const flag = JSON.parse(localStorage.getItem('FW'));

    if (flag) {
      const firstElement = flag[0];
      const {position, bonus, bonusPecent, depositAmt, depositTime, type} =
        firstElement;
      Anim.show({
        type: 'wheel',
        props: {
          position: position,
          bonus: bonus,
          bonusPecent: bonusPecent,
          depositAmt: depositAmt,
          depositTime: depositTime,
          type: type,
        },
      });
    } else {
      api.PromoCheckFortuneWheel({});
    }
  }

  function Unclaim(context, next) {
    if (infoDialog) return;

    api.PromoUnClaimFortuneWheel({depositTime: depositTime});
    Anim.hide();

    setTimeout(() => {
      RemoveFWFlag();
      next();
    }, 500);
  }

  function CancelBtn() {
    const chain = Pipeline(Unclaim, CheckPromo);
    chain.go();
  }

  return (
    <div className="wheel-container">
      <animated.img
        style={spotlight}
        src={wheel.wheelSpotLight}
        className={'wheel-spotlight'}
      />
      <animated.img
        style={backlight}
        src={wheel.wheelBackLight}
        className={'wheel-backlight'}
      />
      <animated.img
        style={coin}
        src={wheel.wheelCoin}
        className={'wheel-coin'}
      />
      <animated.div style={container} className={'wheel-fortune-wheel'}>
        <animated.img
          style={fortuneWheel}
          src={wheelImg}
          className={'wheel-wheel'}
        />
        <animated.img
          style={pointer}
          src={wheel.wheelPointer}
          className={'wheel-pointer'}
        />
        <animated.img
          style={flashlight}
          src={wheel.wheelFlashLight}
          className={'wheel-flashlight'}
        />
      </animated.div>
      <img
        alt="info"
        id="wheel-info-icon"
        src={wheel.wheelInfoBtn}
        className="wheel-info-icon"
        onClick={() => {
          setInfoDialog(true);
          infoRef.start({
            to: [{opacity: 1, scale: 1}],
          });
        }}
      />
      <animated.div style={dialog} className={'wheel-dialog'}>
        <div className="wheel-title">{trans('homepage.promo.header')}</div>
        <div className="wheel-desc">
          {`${trans('homepage.wheel.claimMsg_1')} ${bonusPecent}% ${trans(
            'homepage.wheel.claimMsg_2',
          )} ${depositAmt}`}
        </div>
        <div className="wheel-total">{`${bonus} ${currency}`}</div>
        <img
          src={wheel.wheelOK}
          className="wheel-ok-icon"
          alt="wheel-ok-icon"
          onClick={() => {
            Anim.hide();

            setTimeout(() => {
              CheckPromo();
              if (next) next();
            }, 500);
          }}
        />
      </animated.div>

      <div className="wheel-spin-container">
        <animated.img
          id="wheel-spin"
          onClick={() => spin()}
          style={spinBtn}
          src={wheel.wheelSpinBtn}
          className={'wheel-spin-btn'}
        />
        <animated.div
          id="wheel-spin-cancel"
          style={spinBtn}
          className="wheel-cancel-btn"
          onClick={() => CancelBtn()}>
          {trans('general.btn.cancel')}
          {` `}
          {trans('homepage.wheel.noTurnover')}
        </animated.div>
      </div>
      <animated.div style={info} className={'wheel-dialog wheel-info '}>
        <div className="wheel-info-title">{trans('homepage.wheel.title')}</div>
        <div className="wheel-info">{trans('homepage.wheel.desc')}</div>
        {!localCheck ? (
          <div
            className="wheel-info-check"
            onClick={() => {
              localStorage.setItem(`wheel`, !check);
            }}>
            <img
              alt="wheelCheck"
              className="wheel-check-icon"
              src={check ? wheel.wheelTick : wheel.wheelUntick}
              onClick={() => {
                setCheck(!check);
              }}
            />
            <div>{trans('topUp.tips.hide')}</div>
          </div>
        ) : null}
        <img
          src={wheel.wheelCloseBtn}
          className="wheel-close"
          alt="wheel-close-icon"
          onClick={() => {
            setInfoDialog(false);
            infoRef.start({
              to: [{opacity: 0, scale: 0}],
            });
          }}
        />
      </animated.div>
    </div>
  );
}
