import React, {useEffect, useRef, useState} from 'react';
import './ForgotPassword.scss';
import {useTranslation} from 'react-i18next';
import FormComponent from '../../common/components/Form/FormComponent';
import PasswordTextField from '../../common/components/TextField/PasswordTextField';
import ButtonComponent_v2 from '../../common/components/button/ButtonComponent_v2';
import {clientInfo} from '../../resources';
import api from '../../common/api';
import {useLocation} from 'react-router-dom';
import {useNav} from '../../common/utils/helper';
import {
  MsgModalObject,
  useMsgModal,
} from '../../common/context/MsgModalContext';
import ErrMsgModal from '../../common/components/modal/ErrMsgModal';

export default function ForgotPassword() {
  const {pushMsgModal} = useMsgModal();
  const location = useLocation();
  const navigate = useNav();
  const {mobile} = location.state || {};
  const pwTfName = 'password';
  const confirmPwTfName = 'confirmPassword';
  const {t: trans} = useTranslation();
  const containerRef = useRef(null);
  const formRef = useRef(null);
  const [formData, setFormData] = useState({});
  const [isInProgress, setIsInProgress] = useState(false);
  const registerConfig = clientInfo.register;

  const handleFieldChange = (e) => {
    const {name, value} = e;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleButtonClick = async () => {
    const isValid = await formRef.current.ValidateValue({});
    console.log(formData);
    if (isValid) {
      const password = formData[pwTfName];
      changePassword(mobile, password);
    }
  };

  const changePassword = (mobile, password) => {
    setIsInProgress(true);
    api
      .ForgotPin({
        mobileNumber: mobile,
        password: password,
        success: () => {
          pushMsgModal(
            new MsgModalObject({
              content: (
                <ErrMsgModal
                  title={'dialog.msg.changePass'}
                  onClick={() => {
                    navigate('/login');
                  }}
                />
              ),
            }),
          );
        },
      })
      .finally(() => {
        setIsInProgress(false);
      });
  };

  return (
    <div className="forgotPassword-container container-padding">
      <div ref={containerRef} className="forgotPassword-style">
        <div className="forgotPassword-title-section">
          <div className="forgotPassword-title">
            {trans('forgotPass.label.newPass')}
          </div>
        </div>
        <FormComponent
          ref={formRef}
          frameRef={containerRef}
          className="forgotPassword-form-container">
          {registerConfig.password && (
            <PasswordTextField
              isFormChild={true}
              name={pwTfName}
              placeholder={trans('general.placeholder.password')}
              value={formData[pwTfName] || ''}
              isRequired={true}
              enableHideShow={true}
              onChange={(e) => {
                const confirmTf =
                  formRef.current.getTextFieldRefByName(
                    confirmPwTfName,
                  )?.current;
                if (confirmTf && confirmTf.isRealTimeValidation) {
                  const confirmPwValue = formData[confirmPwTfName] ?? '';
                  const pwValue = e.value;
                  if (confirmPwValue != '') {
                    if (confirmPwValue != pwValue) {
                      confirmTf.setErrMsg('general.errMsg.pwNotMatch');
                    } else {
                      confirmTf.setErrMsg('');
                    }
                  }
                }
                handleFieldChange(e);
              }}
            />
          )}

          {registerConfig.confirmPw && (
            <PasswordTextField
              isFormChild={true}
              name={confirmPwTfName}
              placeholder={trans('forgotPass.label.confirmPass')}
              value={formData[confirmPwTfName] || ''}
              onChange={handleFieldChange}
              isRequired={true}
              enableHideShow={true}
              validation={(value) => {
                if (value != formData[pwTfName] ?? '') {
                  return 'general.errMsg.pwNotMatch';
                } else {
                  return '';
                }
              }}
            />
          )}
        </FormComponent>
        <div className="forgotPassword-btn-section-container">
          <ButtonComponent_v2
            text={trans('signIn.login')}
            btnClassName={'btnV2-container-row-center-p17-r10-mainshadow'}
            btnTextClassName={'btnV2-btn-txt-s20-w700-cMain'}
            disableBtnClassName={
              'btnV2-container-row-center-p17-r10-mainshadow-disabled'
            }
            disableBtnTextClassName={'btnV2-btn-txt-s20-w700-cMain-disabled'}
            disable={isInProgress}
            onClick={() => {
              handleButtonClick();
            }}
          />
        </div>
      </div>
    </div>
  );
}
