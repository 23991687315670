import React, {useEffect, useState, useRef} from 'react';
import './LanguageModal.scss';

import {clientInfo} from '../../../resources';
import {useTranslation} from 'react-i18next';
import LanguageItem from '../language/LanguageItem';
import {GetLanguageObject} from '../../utils/helper';
import i18n from '../../../i18n';
import {useModal} from '../../context/ModalContext';

function LanguageModal() {
  const {popModal} = useModal();
  const {t: trans} = useTranslation();
  const getLanguageObject = GetLanguageObject();

  return (
    <div className="language-modal-main-container">
      <div className="language-modal-container">
        <div className="language-modal-title">
          {trans('homepage.act.language')}
        </div>
        <div className="language-modal-line" />

        {/* To render out all language options item */}
        <div className="language-modal-content">
          {clientInfo.optionLang.map((item) => {
            const languageObject = getLanguageObject(item.key);

            return (
              <LanguageItem
                key={item.key}
                imgSrc={languageObject.icon}
                languageTxt={languageObject.languageTxt}
                isSelected={i18n.language == item.key}
                onClick={() => {
                  i18n.changeLanguage(item.key);
                  popModal();
                }}></LanguageItem>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default LanguageModal;
