import React, {Component} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';

import 'swiper/scss';
import './News.scss';
import CPButton from '../../button/CPButton';
import api from '../../../api';

class News extends Component {
  static newsInstance;
  swiperRef = React.createRef();

  state = {
    data: [],
    show: false,
    index: 0,
  };

  static show(data, next) {
    this.newsInstance.showNews(data, next);
  }

  static hide() {
    this.newsInstance.hideNews();
  }

  showNews(data, next) {
    this.setState({
      data: data,
      show: true,
      next,
    });
    document.body.style.overflow = 'hidden';
  }

  hideNews() {
    this.setState({
      data: [],
      show: false,
    });
    document.body.style.overflow = 'scroll';
  }

  handle(cta) {
    switch (cta) {
      case 'Topup':
        this.props.navigate('/fund');
        break;

      case 'InviteFriend':
        this.props.navigate('/affiliate');
        break;

      case 'Mission':
        this.props.navigate('/mission');

        break;

      default:
        this.swiperRef.current.swiper.slideNext();
    }
  }

  render() {
    const {trans} = this.props;
    const {data, show, index, next} = this.state;

    return show ? (
      <div className="news-container">
        <div className="swiper-container">
          <Swiper
            ref={this.swiperRef}
            slidesPerView="auto"
            grabCursor={true}
            centeredSlides={true}
            loop={this.state.data.length > 2 ? true : false}
            onSlideChange={(swiper) => this.setState({index: swiper.realIndex})}
            // onSwiper={(swiper) => console.log(swiper)}
          >
            {data.map((item, index) => {
              const {cover, title, desc, button, cta} = item;
              return (
                <SwiperSlide key={index}>
                  <div className="swiper-content">
                    <div className="news-img-container">
                      <img className="news-img" src={cover} alt="news-img" />
                    </div>
                    <div className="news-content-container">
                      <div className="news-title">{title}</div>
                      <br />
                      <div className="news-desc">{desc}</div>
                    </div>
                    <CPButton
                      btnStyle={'news-btn'}
                      textStyle={'news-txt'}
                      title={button}
                      onClick={() => {
                        this.handle(cta);
                        this.hideNews();
                        sessionStorage.setItem('news', false);
                        if (next) {
                          next();
                        }
                      }}
                    />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        <div className="news-close-container">
          <CPButton
            btnBg={'news-close-bg'}
            btnStyle={'news-close'}
            textStyle={'news-close-txt'}
            title={trans('general.btn.closeAll')}
            onClick={() => {
              if (next) {
                next();
              }
              this.hideNews();
              sessionStorage.setItem('news', false);
            }}
          />
        </div>
        <div className="news-pagination-container">
          {data.map((item, i) => {
            return (
              <div
                key={i}
                className={`news-pagination ${index === i ? 'active' : ''}`}
              />
            );
          })}
        </div>
      </div>
    ) : null;
  }
}

export default News;
